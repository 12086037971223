import React, { createContext, useState, useEffect, useCallback } from 'react';
import userService from '../../services/api/user-service';
import firebaseAuth from '../../services/api/firebase-service';
import axiosInstance from '../../../api/axiosWrapper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
  GENERIC_SIGNUP_ERROR,
  FIREBASE_ERRORS,
  TRANSACTIONS_QUERY_KEY,
} from '../../../data/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import SyncAccountPrompt from '../../features/Register/SyncAccountPrompt';
import { updateStore } from '../../../data/store';
import { WebSocketProvider } from './WebSocketContext';

export const AuthContext = createContext();

const SKIP_SYNC_CHECK_ROUTES = ['/', '/signup', '/signin', '/login/reset','/link-bank-account'];

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [requireAuth, setRequireAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showSyncAccountPrompt, setShowSyncAccountPrompt] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const isPublicPage = (path) => SKIP_SYNC_CHECK_ROUTES.includes(path);

 const handleSyncCheck = useCallback(
   async (user) => {
     if (localStorage.getItem('skippedSync') === 'true') {
       return;
     }
     const currentPath = location.pathname;
     if (!user || SKIP_SYNC_CHECK_ROUTES.includes(currentPath)) return;
    if (currentPath === '/link-bank-account') return;

     const hasSkippedSync = localStorage.getItem('skippedSync') === 'true';

     if (user.signupState === 0 && !hasSkippedSync) {
       setShowSyncAccountPrompt(true);
     } else {
       await queryClient.invalidateQueries({
         queryKey: TRANSACTIONS_QUERY_KEY,
       });
       const lastVisitedPage =
         sessionStorage.getItem('lastVisitedPage') || '/dashboard';
       navigate(lastVisitedPage);
     }
   },
   [location.pathname, navigate, queryClient]
 );

  const handleSkip = () => {
    localStorage.setItem('skippedSync', 'true');
    setShowSyncAccountPrompt(false);
    navigate('/dashboard');
  };

  const handleSyncNow = () => {
    localStorage.removeItem('skippedSync');
    setShowSyncAccountPrompt(false);
    navigate('/link-bank-account');
  };

  const checkSessionLogin = useCallback(async () => {
    if (window.location.pathname === '/login/reset') {
      setRequireAuth(true);
      setLoading(false);
      return;
    }
    try {
      const response = await axiosInstance.post('/auth', {});
      if (response.status === 200) {
        const user = response.data.user;
        localStorage.setItem('userId', user?.id || '');
        setIsLoggedIn(true);
        setCurrentUser(user);
        updateStore({ user });
        handleSyncCheck(user);
      }
    } catch (error) {
      console.error('User is not logged in', error);
      setIsLoggedIn(false);
      setCurrentUser(null);
      updateStore({ user: null });
    } finally {
      setLoading(false);
    }
  }, [handleSyncCheck]);

  useEffect(() => {
    checkSessionLogin();
  }, [checkSessionLogin]);

  const register = async (registerUser) => {
    setButtonLoading(true);
    try {
      await firebaseAuth.createFirebaseUser(
        registerUser.emailId,
        registerUser.password
      );
      const firebaseToken = await firebaseAuth.getFirebaseIdToken();
      const firebaseUID = await firebaseAuth.getFirebaseUID();
      const newUserResponse = await userService.registerUser(
        registerUser,
        firebaseUID,
        firebaseToken
      );
      const newUser = newUserResponse.registeredUser;

      const loginRes = await firebaseAuth.firebaseEmailPassLogin({
        emailId: registerUser.emailId,
        password: registerUser.password,
      });

      if (loginRes.status === 200) {
        setCurrentUser(newUser);
        updateStore({ user: newUser });
        setIsLoggedIn(true);
        localStorage.setItem('userId', newUser?.id || '');
        sessionStorage.setItem('initialLinkRedirect', 'true');
        window.location.href = '/link-bank-account';
        return newUser;
      }
    } catch (error) {
      console.error('Registration Error:', error);
      const userFriendlyMessage =
        FIREBASE_ERRORS[error.code] || GENERIC_SIGNUP_ERROR;
      throw new Error(userFriendlyMessage);
    } finally {
      setButtonLoading(false);
    }
  };

  const login = async (loginReq) => {
    setButtonLoading(true);
    try {
      const response = await axiosInstance.post('/loginUser', {
        emailId: loginReq.emailId,
      });
      if (response.data.success !== 1) {
        throw new Error(response.data.message);
      }

      const loginRes = await firebaseAuth.firebaseEmailPassLogin(loginReq);
      if (loginRes.status === 200) {
        const firebaseToken = await firebaseAuth.getFirebaseIdToken();
        const user = await userService.getUserByToken(firebaseToken);
        setCurrentUser(user);
        updateStore({ user });
        setIsLoggedIn(true);
        localStorage.setItem('userId', user?.id || '');
        handleSyncCheck(user);
        return user;
      }
    } catch (error) {
      let userFriendlyMessage;
      if (error.response?.status === 422) {
        userFriendlyMessage =
          'No account found with this email. Please verify your email or sign up for a new account. If you’re sure the email exists, try entering it in all lowercase.';
      } else {
        userFriendlyMessage =
          FIREBASE_ERRORS[error.code] || error.message || GENERIC_SIGNUP_ERROR;
      }
      console.error('Login Error:', error);
      throw new Error(userFriendlyMessage);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await axiosInstance.post('/logoutUser');
      if (response.status === 200) {
        setCurrentUser(null);
        updateStore({ user: null });
        setIsLoggedIn(false);
        queryClient.clear();
        localStorage.removeItem('userId');
        localStorage.removeItem('skippedSync');
        sessionStorage.removeItem('lastVisitedPage');
        navigate('/');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        requireAuth,
        isLoggedIn,
        currentUser,
        setCurrentUser,
        login,
        register,
        handleLogout,
        loading,
        buttonLoading,
      }}
    >
      {!loading ? (
        isLoggedIn && currentUser ? (
          <>
            {showSyncAccountPrompt && !isPublicPage(location.pathname) && (
              <SyncAccountPrompt onSync={handleSyncNow} onSkip={handleSkip} />
            )}
            <WebSocketProvider
              wsUrl={process.env.REACT_APP_WS_URL}
              userId={currentUser.id}
            >
              {children}
            </WebSocketProvider>
          </>
        ) : (
          children
        )
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </AuthContext.Provider>
  );
};
