"use client";
import { useState } from "react";
import { Box, Typography, Button, Zoom } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FloatingButton from "./FloatingButton";
import AiSummaryModal from "./AiSummaryModal";
import { colors } from "../../../themes/theme";
import { useStore } from "@tanstack/react-store";
import { store } from "../../../data/store";
import BoltIcon from "@mui/icons-material/Bolt";

const AiSummary = ({ transactions = [], reports = [], type = "home" }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { user, isAiInsightsLoading } = useStore(store) || { user: { aiCredits: 0 } };
    const toggleMenu = (open) => {
        if (isAiInsightsLoading) {
            return;
        }
        setIsOpen(open);
        if (!open) {
            setShowModal(false);
        }
    };

    const handleGenerateSummary = () => {
        setShowModal(true);
    };

    return (
        <>
            <FloatingButton isOpen={isOpen} toggleMenu={toggleMenu} />

            {isOpen && !showModal && (
                <>
                    <Box
                        sx={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: colors.shadow3,
                            backdropFilter: "blur(12px)",
                            WebkitBackdropFilter: "blur(12px)",
                            zIndex: 1300,
                        }}
                        onClick={() => toggleMenu(false)}
                    />

                    <Zoom in>
                        <Box
                            sx={{
                                position: "fixed",
                                bottom: { xs: 10, sm: 100 },
                                right: { xs: 4, sm: 24 },
                                width: { xs: "calc(100% - 10px)", sm: "450px" },
                                maxWidth: "100%",
                                zIndex: 1400,
                                borderRadius: "16px",
                                overflow: "hidden",
                                boxShadow: `0 4px 20px rgba(0, 0, 0, 0.15)`,
                                backgroundColor: "white",
                            }}
                        >
                            <Box sx={{ p: 3 }}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontWeight: 700,
                                        mb: 2,
                                        fontFamily: "SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        fontSize: { xs: "1.35rem", sm: "1.5rem" }
                                    }}
                                >
                                    <span>✨ AI Financial Analysis</span>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: colors.chipBackground,
                                            borderRadius: "16px",
                                            padding: "4px 10px",
                                            fontSize: "0.875rem",
                                            fontWeight: 600,
                                            color: colors.chipText
                                        }}
                                    >
                                        <BoltIcon sx={{ fontSize: "1rem", color: colors.chipIcon, mr: 0.5, display: { xs: "none", sm: "block" } }} />
                                        {user.aiCredits || 0} Credits
                                    </Box>
                                </Typography>

                                <Typography sx={{ color: "text.secondary", mb: 2 }}>
                                    Our AI will analyze your financial data to provide insights on:
                                </Typography>

                                <Box component="ul" sx={{ pl: 2, mb: 2 }}>
                                    <Typography component="li" sx={{ color: "text.primary", display: "flex", alignItems: "center" }}>
                                        <Box component="span" sx={{ color: colors.aiAnalysisBullet, mr: 1, fontSize: "1.5rem" }}>•</Box>
                                        Income sources and performance
                                    </Typography>

                                    <Typography component="li" sx={{ color: "text.primary", display: "flex", alignItems: "center" }}>
                                        <Box component="span" sx={{ color: colors.aiAnalysisBullet, mr: 1, fontSize: "1.5rem" }}>•</Box>
                                        Cost breakdowns and opportunities
                                    </Typography>

                                    <Typography component="li" sx={{ color: "text.primary", display: "flex", alignItems: "center" }}>
                                        <Box component="span" sx={{ color: colors.aiAnalysisBullet, mr: 1, fontSize: "1.5rem" }}>•</Box>
                                        Profit metrics and trends
                                    </Typography>

                                    <Typography component="li" sx={{ color: "text.primary", display: "flex", alignItems: "center" }}>
                                        <Box component="span" sx={{ color: colors.aiAnalysisBullet, mr: 1, fontSize: "1.5rem" }}>•</Box>
                                        Personalized recommendations
                                    </Typography>
                                </Box>

                                <Typography component="li" sx={{  color: "text.secondary",  display: "flex", alignItems: "center", mb: 2 }}>
                                    The analysis will cover your transactions from the date range you select.
                                </Typography>

                                <Box
                                    sx={{
                                        p: 2,
                                        borderRadius: 2,
                                        bgcolor: colors.aiInfoBackground,
                                        display: "flex",
                                        alignItems: "flex-start",
                                        mb: 2
                                    }}
                                >
                                    <InfoOutlinedIcon sx={{ color: colors.aiInfoText, mr: 1, mt: "2px" }} />
                                    <Typography sx={{ color: colors.aiInfoText }}>
                                        Please note that the insights provided are for informational purposes only and should be used alongside professional financial advice.
                                    </Typography>
                                </Box>

                                <Typography sx={{ color: "text.secondary", fontSize: "0.875rem", mb: 3}}>
                                    This will consume one AI-Credit. You have {user.aiCredits || 0} credits left.
                                </Typography>

                                <Box sx={{ display: "flex", gap: 2 }}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => toggleMenu(false)}
                                        sx={{
                                            py: 1.5,
                                            borderColor: "divider",
                                            color: "text.primary",
                                            borderRadius: 2,
                                            "&:hover": {
                                                borderColor: "text.secondary",
                                                bgcolor: "transparent"
                                            }
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleGenerateSummary}
                                        sx={{
                                            py: 1.5,
                                            bgcolor: colors.primary,
                                            borderRadius: 2,
                                            "&:hover": {
                                                bgcolor: colors.primaryHover
                                            }
                                        }}
                                    >
                                        Generate
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Zoom>
                </>
            )}

            {showModal && (<>
                <Box
                    sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: colors.shadow3,
                        backdropFilter: "blur(12px)",
                        WebkitBackdropFilter: "blur(12px)",
                        zIndex: 1300,
                    }}
                    onClick={() => toggleMenu(false)}
                />
                <AiSummaryModal
                    transactions={transactions}
                    reports={reports}
                    type={type}
                    toggleMenu={toggleMenu}
                />
            </>
            )}
        </>
    );
};

export default AiSummary;