import React from 'react';
import { Box, Paper, Typography, styled } from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
} from '@mui/icons-material';
import { colors } from '../../../themes/theme';
import dayjs from 'dayjs';
import {
  ValueTypography,
  ItemFieldset,
  ItemLegend,
} from '../../styles/style.js';

// Shared styled components
export const DateText = styled(Typography)(({ theme }) => ({
  '@media (max-width: 374px)': {
    fontSize: '0.75rem',
  },
}));

export const DateDisplay = styled(Paper)(({ theme, isCustomRange }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  backgroundColor: 'white',
  border: isCustomRange
    ? `2px solid ${colors.secondary}`
    : `1px solid ${colors.lightGray4}`,
  borderRadius: '24px',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: colors.lightGray2,
  },
  '& .MuiTypography-root': {
    fontSize: '0.875rem',
    '@media (max-width: 374px)': {
      fontSize: '0.75rem',
    },
  },
  '@media (max-width: 374px)': {
    padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
  },
}));

export const DateControls = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

// Shared MetricCard component
export const MetricCard = React.memo(
  ({ label, amount, type, isMobile, isTablet }) => {
    const isPositive = type === 'positive';
    const Icon = isPositive ? TrendingUpIcon : TrendingDownIcon;
    const color = isPositive ? colors.income : colors.expenses;

    return (
      <ItemFieldset
        isIncome={isPositive}
        sx={{
          flex: 1,
          minWidth: isTablet ? '100%' : { xs: '100%', sm: '200px' },
          maxWidth: isTablet ? '100%' : 'none',
          transition: 'all 0.2s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          },
          mx: isTablet ? 'auto' : 0,
        }}
      >
        <ItemLegend
          isIncome={isPositive}
          sx={{
            fontSize: {
              xs: '1.1rem',
              sm: isTablet ? '1.1rem' : '0.85rem',
            },
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          {label}
          <Icon
            sx={{ color }}
            fontSize={isMobile || isTablet ? 'medium' : 'small'}
          />
        </ItemLegend>
        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
          <ValueTypography
            color={color}
            sx={{
              fontSize: {
                xs: '2rem !important',
                sm: isTablet ? '1.85rem !important' : '2rem !important',
              },
            }}
          >
            $
            {Math.abs(amount).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </ValueTypography>
        </Box>
      </ItemFieldset>
    );
  }
);

// Shared date picker dialog props
export const getDatePickerDialogProps = (options) => {
  const { isOpen, onClose, value, onChange, minDate, maxDate } = options;

  return {
    open: isOpen,
    onClose,
    fullWidth: true,
    maxWidth: 'xs',
    slotProps: {
      textField: {
        size: 'small',
        sx: {
          width: '100%',
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
            borderRadius: '15px',
            '& .MuiInputBase-input': {
              fontSize: '0.75rem',
              padding: '6px 8px',
              '@media (max-width: 320px)': {
                fontSize: '0.7rem',
                padding: '6px',
              },
            },
          },
          '@media (max-width: 320px)': {
            '& .MuiFormLabel-root': {
              fontSize: '0.75rem',
            },
          },
        },
      },
      layout: {
        sx: {
          width: '100%',
          '& .MuiDateRangeCalendar-root': {
            '@media (max-width: 320px)': {
              transform: 'scale(0.9)',
              transformOrigin: 'top center',
            },
          },
        },
      },
    },
    value,
    onChange,
    minDate,
    maxDate,
  };
};

// Shared utilities
export const isDefaultDateRange = (startDate, endDate, minDate, maxDate) => {
  return (
    dayjs(startDate).isSame(dayjs(minDate), 'day') &&
    dayjs(endDate).isSame(dayjs(maxDate), 'day')
  );
};

// To maintain single source of truth
export {
  ValueTypography,
  ItemFieldset,
  ItemLegend,
} from '../../styles/style.js';
