import axiosInstance from '../../../api/axiosWrapper';

// Base function that makes the actual API call
const getPlaidItems = async () => {
  const response = await axiosInstance.get('/getPlaidItemsForUser');
  return response;
};

// Processed version that formats the data
const getPlaidItemsForUser = async () => {
  try {
    const response = await getPlaidItems(); // Use the base function instead of making another request
    const { items, reauthItems } = response.data;
    return {
      items,
      reauthItems,
      hasReauthItems: reauthItems?.length > 0,
      linkToken: reauthItems?.length > 0 ? reauthItems[0].linkToken : null,
    };
  } catch (error) {
    console.error('Error fetching bank accounts:', error);
    throw error;
  }
};

export { getPlaidItems, getPlaidItemsForUser };
