import React from 'react';
import {
  SFormControl,
  SInputLabel,
  SSelect,
  SMenuItem,
} from '../../styles/style';
import { useMediaQuery, useTheme } from '@mui/material';
import { useStore } from '@tanstack/react-store';
import { store, updateStore } from '../../../data/store';

const TransactionFilter = ({ onFilterChange, isEditMode }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const transactionType = useStore(store, (state) => state.transactionType);

  const handleTransactionTypeChange = (e) => {
    if (isEditMode) return;

    updateStore({ transactionType: e.target.value });
    if (onFilterChange) {
      onFilterChange(e.target.value);
    }
  };

  return (
    <SFormControl
      sx={{
        m: isDesktop ? 1 : 0,
        width: isDesktop ? '100px' : '125px',
        '& .MuiInputBase-root': {
          height: '40px',
          borderRadius: '20px', // Ensure base border radius
        },
        '& .MuiSelect-select': {
          borderRadius: '20px', // Match select element border radius
        },
        '& .Mui-disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '20px', // Match disabled outline border radius
          },
          // Remove the rectangular background
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        // Style for the outline when disabled
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
          {
            borderRadius: '20px',
            borderColor: 'rgba(0, 0, 0, 0.1)',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
      }}
      size="small"
    >
      <SInputLabel
        id="toggle-select-small-label"
        sx={{
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.38)',
          },
        }}
      >
        Filters
      </SInputLabel>
      <SSelect
        labelId="toggle-select-small-label"
        id="toggle-select-small"
        value={transactionType}
        label="Filters"
        onChange={handleTransactionTypeChange}
        color="primary"
        disabled={isEditMode}
      >
        <SMenuItem value="All">All</SMenuItem>
        <SMenuItem value="income">Income</SMenuItem>
        <SMenuItem value="expenses">Expenses</SMenuItem>
      </SSelect>
    </SFormControl>
  );
};

export default TransactionFilter;
