import React, { useState, useEffect, useCallback, useMemo } from 'react';
import SalesDataGrid from '../../../components/features/Sales Data Grid/index';
import { dummySalesData, calculateSalesMetrics } from './salesPageDummyData';
import { useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { useDashboardContext } from '../../../components/common/contexts/DashboardContext';
import MetricsDisplay from '../../../components/features/Metrics Display';

// Extend dayjs with required plugins
dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

/**
 * Transforms raw sales data into the format expected by the UI components
 */
const localizeData = (salesData) => {
  return salesData.map((item) => ({
    id: item.id,
    date: item.date,
    description: item.details,
    account: item.source,
    items: item.items,
    amount: item.amount,
    status: item.status,
    paymentMethod: item.paymentMethod,
    category: item.category,
    isPending: item.status === 'PENDING',
    totalAmount: item.totalAmount,
    costOfGoods: item.costOfGoods,
    profit: item.profit,
  }));
};

const SalesPage = () => {
  const [filterState, setFilterState] = useState('All');
  const [isCalculating, setIsCalculating] = useState(false);

  // Access dashboard context for date range and metrics state management
  const {
    activeTimeTab,
    startDate,
    endDate,
    setSalesMetricsData,
    isCustomRange,
    setStartDate,
    setEndDate,
  } = useDashboardContext();

  // Access alert context from router outlet
  const { setOpenAlert, setAlertSeverity, setAlertMessage } =
    useOutletContext();

  // Memoize processed sales data to prevent unnecessary recalculations
  const localizedSalesData = useMemo(() => localizeData(dummySalesData), []);

  // Extract unique categories from data for filtering options
  const uniqueCategories = useMemo(
    () => [...new Set(localizedSalesData.map((item) => item.category))],
    [localizedSalesData]
  );

  // Define color scheme for transaction categories
  const transactionCategoryColorMap = useMemo(
    () => ({
      'Square Terminal': '#e6f7ee',
      'Online Store': '#e6f0ff',
      'Mobile Payment': '#f8f8e8',
    }),
    []
  );

  // Local metrics state for display
  const [metricsData, setMetricsData] = useState({
    totalSales: '$0.00',
    totalTransactions: '0',
    averageSale: '$0.00',
    itemsSold: '0',
    percentChanges: {
      sales: '+0.0%',
      transactions: '+0.0%',
      average: '+0.0%',
      items: '+0.0%',
    },
    previousValues: {
      sales: '$0.00',
      transactions: '0',
      average: '$0.00',
      items: '0',
    },
    comparisonPeriod: null,
  });

  // Mock data fetching function - would be replaced with real API call in production
  const fetchSales = useCallback(() => {
    return Promise.resolve(localizedSalesData);
  }, [localizedSalesData]);

  /**
   * Calculates sales metrics based on selected time period or custom date range
   * Updates both local state and dashboard context with formatted metric values
   */
  const calculateMetrics = useCallback(() => {
    if (isCalculating) return;

    setIsCalculating(true);

    try {
      // Calculate metrics based on selected time period or custom range
      const metrics = isCustomRange
        ? calculateSalesMetrics(localizedSalesData, 'all', startDate, endDate)
        : calculateSalesMetrics(localizedSalesData, activeTimeTab);

      // Format metrics for display
      const formattedData = {
        totalSales: `$${metrics.totalSales.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        totalTransactions: metrics.transactionCount.toLocaleString('en-US'),
        averageSale: `$${metrics.averageTicketSize.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        itemsSold: metrics.totalItems.toLocaleString('en-US'),
        percentChanges: {
          sales: metrics.percentChanges.sales || '+0.0%',
          transactions: metrics.percentChanges.transactions || '+0.0%',
          average: metrics.percentChanges.average || '+0.0%',
          items: metrics.percentChanges.items || '+0.0%',
        },
        previousValues: {
          sales: metrics.previousValues.sales || '$0.00',
          transactions: metrics.previousValues.transactions || '0',
          average: metrics.previousValues.average || '$0.00',
          items: metrics.previousValues.items || '0',
        },
        comparisonPeriod: metrics.previousPeriod,
      };

      // Update both local state and context
      setMetricsData(formattedData);
      setSalesMetricsData(formattedData);
    } catch (error) {
      console.error('Error calculating metrics:', error);
      setAlertSeverity('error');
      setAlertMessage('There was an error calculating sales metrics.');
      setOpenAlert(true);
    } finally {
      setIsCalculating(false);
    }
  }, [
    activeTimeTab,
    localizedSalesData,
    startDate,
    endDate,
    isCustomRange,
    setAlertMessage,
    setAlertSeverity,
    setOpenAlert,
    setSalesMetricsData,
    isCalculating,
  ]);

  // Handler for filter state changes
  const handleFilterChange = useCallback((newFilterState) => {
    setFilterState(newFilterState);
  }, []);

  // Recalculate metrics when relevant dependencies change
  useEffect(() => {
    // Only calculate when date range is complete
    if (startDate && endDate) {
      const timeoutId = setTimeout(() => {
        calculateMetrics();
      }, 50); // Small delay to prevent multiple calculations during rapid changes

      return () => clearTimeout(timeoutId);
    }
  }, [activeTimeTab, startDate, endDate, isCustomRange, calculateMetrics]);

  // Handler for date range changes
  const handleDateChange = useCallback(
    (newStartDate, newEndDate) => {
      // Only update when both dates are selected
      if (newStartDate && newEndDate) {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      }
    },
    [setStartDate, setEndDate]
  );

  // Empty handler for time period changes (handled by context)
  const handleTimePeriodChange = useCallback(() => {
    // This function passes through to the context handler
  }, []);

  // Generate unique key for data grid to force proper re-rendering when data changes
  const dataGridKey = useMemo(
    () =>
      `sales-grid-${activeTimeTab}-${startDate?.toString() || ''}-${endDate?.toString() || ''}`,
    [activeTimeTab, startDate, endDate]
  );

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        marginTop: '16px',
      }}
    >
      {/* Sales Metrics Display */}
      <MetricsDisplay
        metricsData={metricsData}
        onTimePeriodChange={handleTimePeriodChange}
        filterState={filterState}
        metricsType="sales"
        onDateChange={handleDateChange}
      />

      {/* Sales Data Grid */}
      <SalesDataGrid
        key={dataGridKey}
        rows={localizedSalesData}
        categories={uniqueCategories}
        timePeriod={activeTimeTab}
        startDate={startDate}
        endDate={endDate}
        transactionCategoryColorMap={transactionCategoryColorMap}
        fetchSales={fetchSales}
        onFilterChange={handleFilterChange}
      />
    </div>
  );
};

export default SalesPage;
