import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { colors } from "../../../themes/theme";

const FinancialLoadingAnimation = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", perspective: 1000 }}>
      <motion.div
        style={{
          width: 60,
          height: 60,
          borderRadius: "50%",
          backgroundColor: colors.coin,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: `0px 0px 8px ${colors.shadow3}`,
          transformStyle: "preserve-3d",
          border: `3px dashed ${colors.coinBorder}`
        }}
        animate={{ rotateY: [0, 360] }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
      >
        <motion.div
          style={{ backfaceVisibility: "hidden" }}
          animate={{ opacity: [1, 0, 1] }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        >
          <Typography variant="h4" sx={{ color: colors.white, fontWeight: 700 }}>
            $
          </Typography>
        </motion.div>
      </motion.div>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2, height: 30 }}>
        <motion.div
          style={{
            width: 8,
            backgroundColor: colors.income,
            borderRadius: 2,
            margin: "0 4px"
          }}
          animate={{ height: ["20px", "30px", "20px"] }}
          transition={{ duration: 0.6, repeat: Infinity, delay: 0 }}
        />
        <motion.div
          style={{
            width: 8,
            backgroundColor: colors.expenses,
            borderRadius: 2,
            margin: "0 4px"
          }}
          animate={{ height: ["25px", "35px", "25px"] }}
          transition={{ duration: 0.6, repeat: Infinity, delay: 0.2 }}
        />
        <motion.div
          style={{
            width: 8,
            backgroundColor: colors.income,
            borderRadius: 2,
            margin: "0 4px"
          }}
          animate={{ height: ["20px", "30px", "20px"] }}
          transition={{ duration: 0.6, repeat: Infinity, delay: 0.4 }}
        />
      </Box>
    </Box>
  );
};

export default FinancialLoadingAnimation;