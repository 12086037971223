import React, { useState, useEffect } from 'react';
import {
  SearchContainer,
  InputWrapper,
  Input,
  GoButton,
  ClearButton,
  LoadingSpinner,
} from './styled.js';
import { Box, Button, IconButton, TextField, Divider } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EastIcon from '@mui/icons-material/East';
import ClearIcon from '@mui/icons-material/Clear';
import { store, updateStore } from '../../../data/store.js';
import { useStore } from '@tanstack/react-store';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleAiSearch from './ToggleAiSearch.js';

const placeholders = [
  'How much profit did I make in Q3 last year?',
  'What were my COGS transactions for FY2023?',
  'Which products generated the most revenue?',
  'What were my largest expenses in the last 3 months?',
];

const AiSearchBar = ({ handleSearch, fetchTransactions }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const query = useStore(store, (state) => state.query);
  const isAiSearchLoading = useStore(store, (state) => state.isAiSearchLoading);

  const [typedPlaceholder, setTypedPlaceholder] = useState('');
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const currentText = placeholders[placeholderIndex];
    let timeoutId;
    if (charIndex < currentText.length) {
      timeoutId = setTimeout(() => {
        setTypedPlaceholder(currentText.slice(0, charIndex + 1));
        setCharIndex(charIndex + 1);
      }, 50);
    } else {
      timeoutId = setTimeout(() => {
        setCharIndex(0);
        setPlaceholderIndex((placeholderIndex + 1) % placeholders.length);
        setTypedPlaceholder('');
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [charIndex, placeholderIndex]);

  const enableAiSearch = useStore(store, (state) => state.enableAiSearch);

  const handleKeyPress = (event) => {
    if (query.length > 0 && event.key === 'Enter') {
      handleSearch(query);
    }
  };

  const handleClear = () => {
    updateStore({
      query: '',
      aiInterpretation: null,
      hasCritiqueAnimated: false,
      critique: null,
      aiCritiqueError: '',
    });
    if (fetchTransactions) {
      fetchTransactions({ background: true });
    }
  };

  const handleToggleAiSearch = () => {
    updateStore({ enableAiSearch: !enableAiSearch });
  };

  // Mobile version
  if (isMobile) {
    return (
      <Box
        sx={{
          width: '100%',
          padding: '0',
          position: 'relative',
          zIndex: 1, // Ensure search bar stays above content
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            '&::before': {
              content: '""',
              position: 'absolute',
              inset: '-2px',
              background: 'linear-gradient(135deg, #5aa8ff 0%, #ff6482 100%)',
              borderRadius: '14px', // Slightly larger to account for the border
              zIndex: 0,
            },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#f8f9fa',
              borderRadius: '12px',
              height: '48px',
              transition: 'all 0.2s ease',
              zIndex: 1,
              '&:focus-within': {
                boxShadow: '0 0 0 4px rgba(90, 168, 255, 0.1)',
              },
            }}
          >
            <AutoAwesomeIcon
              sx={{
                ml: 2,
                fontSize: '20px',
                background: 'linear-gradient(135deg, #5aa8ff 0%, #ff6482 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            />

            <TextField
              fullWidth
              variant="standard"
              placeholder={
                typedPlaceholder || 'Ask about your latest transactions!'
              }
              value={query}
              onChange={(e) => updateStore({ query: e.target.value })}
              onKeyPress={handleKeyPress}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                ml: 1,
                mr: 1,
                flex: 1,
                '& .MuiInputBase-root': {
                  height: '100%',
                  fontSize: '14px',
                  color: '#333',
                  backgroundColor: 'transparent',
                },
                '& .MuiInputBase-input': {
                  padding: '8px 0',
                  '&::placeholder': {
                    color: '#757575',
                    opacity: 1,
                  },
                },
              }}
            />

            {query && (
              <IconButton
                size="small"
                onClick={handleClear}
                sx={{
                  padding: '4px',
                  mr: 1,
                  '& svg': {
                    fontSize: '18px',
                    background:
                      'linear-gradient(135deg, #5aa8ff 0%, #ff6482 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  },
                }}
              >
                <ClearIcon />
              </IconButton>
            )}

            {isAiSearchLoading ? (
              <LoadingSpinner>
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: 'white',
                    borderRadius: '100px',
                  }}
                />
              </LoadingSpinner>
            ) : (
              <Button
                size="small"
                onClick={handleToggleAiSearch}
                startIcon={<AutoAwesomeIcon sx={{ fontSize: '16px' }} />}
                sx={{
                  minWidth: 'auto',
                  height: '32px',
                  mr: 1,
                  borderRadius: '8px',
                  px: 1.5,
                  background:
                    'linear-gradient(135deg, #5aa8ff 0%, #ff6482 100%)',
                  color: 'white',
                  border: 'none',
                  fontSize: '13px',
                  textTransform: 'none',
                  fontWeight: 500,
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, #4997ee 0%, #ee5371 100%)',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'white',
                  },
                }}
              >
                AI
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  // Desktop version (unchanged)
  return (
    <SearchContainer>
      <InputWrapper>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              id="iconGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop offset="0%" stopColor="#5aa8ff" />
              <stop offset="100%" stopColor="#ff6482" />
            </linearGradient>
          </defs>
          <AutoAwesomeIcon style={{ fill: 'url(#iconGradient)' }} />
        </svg>
        <Input
          placeholder={typedPlaceholder || ''}
          value={query}
          onChange={(e) => updateStore({ query: e.target.value })}
          onKeyPress={handleKeyPress}
          sx={{
            fontSize: {
              xs: '14px',
              sm: '16px',
              md: '18px',
            },
          }}
        />
        {query.length > 0 && (
          <ClearButton onClick={handleClear}>
            <ClearIcon style={{ fill: 'url(#iconGradient)' }} />
          </ClearButton>
        )}
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mx: 1, height: '24px', alignSelf: 'center' }}
        />
        <Box sx={{ mx: 1 }}>
          <ToggleAiSearch isMobile={false} />
        </Box>
        {isAiSearchLoading ? (
          <LoadingSpinner>
            <Box
              sx={{
                width: '20px',
                height: '20px',
                backgroundColor: 'white',
                borderRadius: '100px',
              }}
            />
          </LoadingSpinner>
        ) : (
          <GoButton
            onClick={() => handleSearch(query)}
            disabled={query.length === 0}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="iconGradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop offset="0%" stopColor="#5aa8ff" />
                  <stop offset="100%" stopColor="#ff6482" />
                </linearGradient>
              </defs>
              <EastIcon style={{ fill: 'url(#iconGradient)' }} />
            </svg>
          </GoButton>
        )}
      </InputWrapper>
    </SearchContainer>
  );
};

export default AiSearchBar;
