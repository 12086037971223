import React, { useCallback } from 'react';
import {
  Button,
  Box,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  Divider,
  IconButton,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useStore } from '@tanstack/react-store';
import { store, updateStore } from '../../../data/store';
import { colors } from '../../../themes/theme';

/**
 * Filter component for sales transactions
 * @param {Object} props Component props
 * @param {Function} props.onFilterChange Callback when filter changes
 * @param {boolean} props.isEditMode Whether the component is in edit mode
 */
const SalesTransactionFilter = ({ onFilterChange, isEditMode }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Get current filter value from store with fallback to 'All'
  const salesTransactionType = useStore(
    store,
    (state) => state.salesTransactionType || 'All'
  );

  // Handler for changing transaction type filter
  const handleTransactionTypeChange = useCallback(
    (newValue) => {
      if (isEditMode) return;

      updateStore({ salesTransactionType: newValue });

      if (onFilterChange) {
        onFilterChange(newValue);
      }

      handleClose();
    },
    [isEditMode, onFilterChange]
  );

  // Reset filters to default state
  const resetFilters = useCallback(() => {
    if (isEditMode) return;

    updateStore({ salesTransactionType: 'All' });

    if (onFilterChange) {
      onFilterChange('All');
    }
  }, [isEditMode, onFilterChange]);

  const handleClick = (event) => {
    if (isEditMode) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  return (
    <>
      {/* Filter Button with active indicator dot */}
      <Button
        onClick={handleClick}
        startIcon={
          salesTransactionType !== 'All' ? (
            <FilterAltIcon sx={{ color: colors.filterIconColor }} />
          ) : (
            <FilterAltOutlinedIcon sx={{ color: colors.filterIconColor }} />
          )
        }
        disabled={isEditMode}
        variant="outlined"
        sx={{
          height: '40px',
          borderRadius: '20px',
          backgroundColor:
            salesTransactionType !== 'All'
              ? colors.filterButtonActiveBg
              : colors.white,
          borderColor: colors.filterButtonColor,
          color: colors.filterButtonColor,
          position: 'relative',
          textTransform: 'none',
          fontWeight: 500,
          px: 2,
          transition: 'all 0.2s ease',
          '&:hover': {
            backgroundColor: colors.filterButtonHoverBg,
            borderColor: colors.filterButtonHoverColor,
            color: colors.filterButtonHoverColor,
            transform: 'translateY(-1px)',
          },
          '&:active': {
            transform: 'translateY(1px)',
          },
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            opacity: 0.6,
            backgroundColor: colors.white,
            borderColor: colors.filterDisabledBorderColor,
            color: colors.filterDisabledColor,
          },
        }}
      >
        Filter
        {/* Blue dot indicator when filter is active */}
        {salesTransactionType !== 'All' && (
          <Box
            sx={{
              position: 'absolute',
              top: '-4px',
              right: '-4px',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: colors.filterButtonColor,
              border: `2px solid ${colors.white}`,
            }}
          />
        )}
      </Button>

      {/* Filter Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            p: 2,
            width: 250,
            mt: 0.5,
            boxShadow: `0 4px 20px ${colors.filterPopoverBoxShadow}`,
            borderRadius: '8px',
            transition: 'opacity 0.2s ease, transform 0.2s ease',
            animation: 'fadeInScale 0.2s ease',
            '@keyframes fadeInScale': {
              '0%': {
                opacity: 0,
                transform: 'scale(0.95)',
              },
              '100%': {
                opacity: 1,
                transform: 'scale(1)',
              },
            },
          },
        }}
      >
        {/* Popover Header with Title and Action Buttons */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Typography variant="subtitle1" fontWeight={600}>
            Filter
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton
              size="small"
              onClick={resetFilters}
              sx={{
                transition: 'all 0.2s ease',
                '&:hover': {
                  backgroundColor: colors.filterResetButtonHoverBg,
                },
              }}
            >
              <RestartAltIcon fontSize="small" color="primary" />
            </IconButton>
            <IconButton
              size="small"
              onClick={handleClose}
              sx={{
                transition: 'all 0.2s ease',
                '&:hover': {
                  backgroundColor: colors.filterCloseButtonHoverBg,
                },
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <Typography variant="subtitle2" fontWeight={600} sx={{ mb: 1 }}>
          Transaction Type
        </Typography>

        {/* Filter Options List */}
        <List sx={{ py: 0 }}>
          {/* All Transactions Option */}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleTransactionTypeChange('All')}
              dense
              selected={salesTransactionType === 'All'}
              sx={{
                borderRadius: 1,
                transition: 'all 0.15s ease',
                '&.Mui-selected': {
                  backgroundColor: colors.filterSelectedBg,
                },
                '&.Mui-selected:hover': {
                  backgroundColor: colors.filterSelectedHoverBg,
                },
                '&:hover': {
                  backgroundColor: colors.filterOptionHoverBg,
                },
              }}
            >
              <Radio
                checked={salesTransactionType === 'All'}
                size="small"
                sx={{
                  p: 0.5,
                  mr: 1,
                  transition: 'all 0.2s ease',
                }}
              />
              <ListItemText primary="All" />
            </ListItemButton>
          </ListItem>

          {/* Income Transaction Option */}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleTransactionTypeChange('Income')}
              dense
              selected={salesTransactionType === 'Income'}
              sx={{
                borderRadius: 1,
                transition: 'all 0.15s ease',
                '&.Mui-selected': {
                  backgroundColor: colors.filterSelectedBg,
                },
                '&.Mui-selected:hover': {
                  backgroundColor: colors.filterSelectedHoverBg,
                },
                '&:hover': {
                  backgroundColor: colors.filterOptionHoverBg,
                },
              }}
            >
              <Radio
                checked={salesTransactionType === 'Income'}
                size="small"
                sx={{
                  p: 0.5,
                  mr: 1,
                  transition: 'all 0.2s ease',
                }}
              />
              <ListItemText primary="Income" />
            </ListItemButton>
          </ListItem>

          {/* Expenses Transaction Option */}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleTransactionTypeChange('Expenses')}
              dense
              selected={salesTransactionType === 'Expenses'}
              sx={{
                borderRadius: 1,
                transition: 'all 0.15s ease',
                '&.Mui-selected': {
                  backgroundColor: colors.filterSelectedBg,
                },
                '&.Mui-selected:hover': {
                  backgroundColor: colors.filterSelectedHoverBg,
                },
                '&:hover': {
                  backgroundColor: colors.filterOptionHoverBg,
                },
              }}
            >
              <Radio
                checked={salesTransactionType === 'Expenses'}
                size="small"
                sx={{
                  p: 0.5,
                  mr: 1,
                  transition: 'all 0.2s ease',
                }}
              />
              <ListItemText primary="Expenses" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default React.memo(SalesTransactionFilter);
