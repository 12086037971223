import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { Link, Clock, TrendingUp, ArrowRight } from 'lucide-react';
import { colors } from '../../../themes/theme';

const SyncAccountPrompt = ({ onSync, onSkip }) => {
  return (
    <Modal open={true} onClose={() => {}} disableEscapeKeyDown>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100vw',
          p: { xs: 1.5, sm: 3 },
        }}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ type: 'spring', stiffness: 120, damping: 10 }}
          style={{
            width: '100%',
            maxWidth: 520,
            minWidth: 320,
            background: colors.white,
            boxShadow: colors.shadow,
            padding: '18px',
            borderRadius: '12px',
            textAlign: 'center',
            border: `1px solid ${colors.borderColor}`,
          }}
        >
          <motion.div
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 150, damping: 8 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: { xs: '12px', sm: '20px' },
            }}
          >
            <Box
              sx={{
                width: { xs: 50, sm: 66 },
                height: { xs: 50, sm: 66 },
                borderRadius: '50%',
                backgroundColor: colors.aiCritiqueBg,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Link size={24} color={colors.primary} />{' '}
            </Box>
          </motion.div>

          {/* Title */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: colors.textHover,
              mb: { xs: 1, sm: 1.5 },
              fontSize: { xs: '1rem', sm: '1.4rem' },
            }}
          >
            Sync Your Bank Account
          </Typography>

          <Typography
            sx={{
              color: colors.textSecondary,
              mb: { xs: 2, sm: 3 },
              fontSize: { xs: '0.8rem', sm: '1rem' },
              maxWidth: '90%',
              margin: '0 auto',
            }}
          >
            We noticed you haven't linked a bank account yet.
            <br />
            Connect your accounts to unlock financial insights.
          </Typography>

          <Box
            sx={{
              backgroundColor: colors.aiCritiqueBg,
              borderRadius: '8px',
              padding: { xs: '12px', sm: '16px' },
              textAlign: 'left',
              mb: { xs: 2, sm: 3 },
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                color: colors.primary,
                mb: 1,
                fontSize: { xs: '0.9rem', sm: '1.1rem' },
              }}
            >
              Why link your bank account?
            </Typography>

            <Box sx={{ pl: 1 }}>
              <Typography
                sx={{
                  color: colors.darkGray3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                  mb: 1.2,
                }}
              >
                <Clock size={16} color={colors.primary} />
                Get insights on your income sources and spending patterns.
              </Typography>
              <Typography
                sx={{
                  color: colors.darkGray3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                  mb: 1.2,
                }}
              >
                <TrendingUp size={16} color={colors.primary} />
                Receive AI-powered recommendations to maximize revenue.
              </Typography>
              <Typography
                sx={{
                  color: colors.darkGray3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                }}
              >
                <ArrowRight size={16} color={colors.primary} />
                Track profits and identify growth opportunities.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1.5,
              mt: { xs: 1.5, sm: 2 },
              width: '100%',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: colors.error,
                borderColor: colors.error,
                backgroundColor: 'white',
                px: { xs: 2, sm: 4 },
                py: { xs: 1.2, sm: 1.4 },
                fontSize: { xs: '0.85rem', sm: '1rem' },
                fontWeight: '500',
                borderRadius: '8px',
                width: { xs: '100%', sm: '220px' },
                minHeight: '44px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: colors.lightError,
                },
              }}
              onClick={onSkip}
            >
              Skip for now
            </Button>

            <Button
              variant="contained"
              sx={{
                bgcolor: colors.primary,
                color: colors.white,
                px: { xs: 2, sm: 4 },
                py: { xs: 1.2, sm: 1.4 },
                fontSize: { xs: '0.85rem', sm: '1rem' },
                fontWeight: '500',
                borderRadius: '8px',
                width: { xs: '100%', sm: '220px' },
                minHeight: '44px',
                textTransform: 'none',
                '&:hover': {
                  bgcolor: colors.primaryHover,
                },
              }}
              onClick={onSync}
            >
              Sync now
            </Button>
          </Box>

          <Typography
            sx={{
              color: colors.textSecondary,
              fontSize: { xs: '0.7rem', sm: '0.85rem' },
              mt: { xs: 1.5, sm: 2 },
              maxWidth: '80%',
              margin: '0 auto',
            }}
          >
            Your data is encrypted and used only for financial insights.
          </Typography>
        </motion.div>
      </Box>
    </Modal>
  );
};

export default SyncAccountPrompt;
