import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { SDataGrid } from '../../styles/style.js';
import { GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid';
import {
  addTransaction,
  editTransaction,
  markTransactionAsRemoved,
} from '../../services/api/transactions-service.js';
import { categoriesColorArray } from '../../../themes/theme.js';
import EditToolbar from './EditToolbar.js';
import QuickSearchToolbar from './QuickSearchToolbar.js';
import DeleteConfirmationDialog from '../Delete Dialog/index.js';
import { getGridColumns } from './gridColumns.js';
import ReceiptModal from './ReceiptModal';
import MobileTransactionsPage from './MobileTransactionsPage';
import AiSearchBar from '../AiSearch';
import { useStore } from '@tanstack/react-store';
import { store, updateStore, createAlert } from '../../../data/store.js';
import NavigationWarning from './NavigationWarning.js';
import AiSummary from '../AiSummary/index.js';
import { randomId } from '@mui/x-data-grid-generator';
import { useDashboardContext } from '../../common/contexts/DashboardContext';
import TransactionsMetricsDisplay from './TransactionsMetricsDisplay.js';

dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

const FullFeaturedCrudGrid = ({
  accounts,
  setOpenAlert,
  setAlertSeverity,
  setAlertMessage,
  fetchTransactions,
  onFilterChange,
  categories,
}) => {
  const { minDateRangeLocal, maxDateRangeLocal } = useDashboardContext();

  const [localTimePeriod, setLocalTimePeriod] = useState('all');
  const [localSelectedWeeks, setLocalSelectedWeeks] = useState([]);
  const [localSelectedMonths, setLocalSelectedMonths] = useState([]);
  const [localSelectedQuarters, setLocalSelectedQuarters] = useState([]);
  const [localSelectedYears, setLocalSelectedYears] = useState([]);
  const [localStartDate, setLocalStartDate] = useState(
    dayjs(minDateRangeLocal).format('YYYY-MM-DD')
  );
  const [localEndDate, setLocalEndDate] = useState(
    dayjs(maxDateRangeLocal).format('YYYY-MM-DD')
  );

  const [rowModesModel, setRowModesModel] = useState({});
  const [editingCellId, setEditingCellId] = useState(null);
  const [isRowValid, setIsRowValid] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const transactionType = useStore(store, (state) => state.transactionType);
  const setTransactionType = (value) => updateStore({ transactionType: value });

  const apiRef = useGridApiRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPrintMedia = useMediaQuery('print');

  const {
    rows,
    isAiSearchLoading,
    enableAiSearch,
    isReceiptModalOpen,
    isDeleteDialogOpen,
    selectedRowId,
    isLoadingTransactions,
    editingTransactionId,
    sortField,
    sortDirection,
  } = useStore(store, (state) => ({
    rows: state.rows,
    isAiSearchLoading: state.isAiSearchLoading,
    enableAiSearch: state.enableAiSearch,
    isReceiptModalOpen: state.isReceiptModalOpen,
    isDeleteDialogOpen: state.isDeleteDialogOpen,
    currentRowId: state.currentRowId,
    currentReceiptUrl: state.currentReceiptUrl,
    selectedRowId: state.selectedRowId,
    isLoadingTransactions: state.isLoadingTransactions,
    editingTransactionId: state.editingTransactionId,
    currentEditData: state.currentEditData,
    sortField: state.sortField,
    sortDirection: state.sortDirection,
  }));

  const filterRowsByPeriod = useCallback(
    (rowsToFilter) => {
      if (!rowsToFilter || !Array.isArray(rowsToFilter)) return [];

      let filteredRows = [];

      switch (localTimePeriod) {
        case 'week':
          filteredRows = rowsToFilter.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date);
            return localSelectedWeeks.some((week) => {
              const [start, end] = week.split(' - ');
              const startOfSelectedWeek = dayjs(
                `${start}, ${end.split(', ')[1]}`
              ).startOf('day');
              const endOfSelectedWeek = dayjs(end).endOf('day');
              return rowDate.isBetween(
                startOfSelectedWeek,
                endOfSelectedWeek,
                null,
                '[]'
              );
            });
          });
          break;
        case 'month':
          filteredRows = rowsToFilter.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date).format('MMMM YYYY');
            return localSelectedMonths.includes(rowDate);
          });
          break;
        case 'quarter':
          filteredRows = rowsToFilter.filter((row) => {
            if (row.isNew) return true;
            const date = dayjs(row.date);
            const quarter = `Q${date.quarter()} ${date.format('YYYY')}`;
            return localSelectedQuarters.includes(quarter);
          });
          break;
        case 'year':
          filteredRows = rowsToFilter.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date).format('YYYY');
            return localSelectedYears.includes(rowDate);
          });
          break;
        case 'all':
          const start = dayjs(localStartDate).startOf('day');
          const end = dayjs(localEndDate).endOf('day');
          filteredRows = rowsToFilter.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date);
            return rowDate.isBetween(start, end, null, '[]');
          });
          break;
        default:
          filteredRows = rowsToFilter;
          break;
      }

      return filteredRows.filter((row) => {
        if (transactionType?.toLowerCase() === 'income') {
          return row.amount >= 0;
        } else if (transactionType?.toLowerCase() === 'expenses') {
          return row.amount <= 0;
        }
        return true;
      });
    },
    [
      localTimePeriod,
      transactionType,
      localSelectedWeeks,
      localSelectedMonths,
      localSelectedQuarters,
      localSelectedYears,
      localStartDate,
      localEndDate,
    ]
  );

  // Define filteredRows after filterRowsByPeriod is defined
  const filteredRows = useMemo(
    () => filterRowsByPeriod(rows),
    [rows, filterRowsByPeriod]
  );

  useEffect(() => {
    if (!isMobile && editingTransactionId) {
      // Only set row to edit mode if it exists in the filtered rows
      const rowExists = filteredRows.some(
        (row) => row.id === editingTransactionId
      );

      if (rowExists) {
        setRowModesModel({
          [editingTransactionId]: { mode: GridRowModes.Edit },
        });
        setEditingCellId(editingTransactionId);
        setIsEditing(true);
      } else {
        // Clear editing state if row doesn't exist in filtered view
        updateStore({
          editingTransactionId: null,
          currentEditData: null,
        });
        setEditingCellId(null);
        setIsEditing(false);
        setRowModesModel({});
      }
    }
  }, [isMobile, editingTransactionId, filteredRows]);

  // Prevent editing if another row is already being edited
  const handleEditClick = useCallback(
    (id) => {
      // Check if any row is being edited
      if (isEditing) {
        createAlert(
          'warning',
          'Please finish editing the current row before editing another one.'
        );
        return;
      }

      // Check if the row exists in the current filtered view
      const rowExists = filteredRows.some((row) => row.id === id);
      if (!rowExists) {
        createAlert(
          'error',
          'Cannot edit this row. It may have been filtered out or removed.'
        );
        return;
      }

      const row = rows.find((r) => r.id === id);
      setEditingCellId(id);
      setIsEditing(true);

      updateStore({
        editingTransactionId: id,
        currentEditData: row,
        rowEditCount: 1,
      });

      setRowModesModel({
        [id]: { mode: GridRowModes.Edit },
      });
    },
    [rows, isEditing, filteredRows]
  );

  const handleSaveClick = useCallback((id) => {
    setEditingCellId(null);
    setIsEditing(false);

    updateStore({
      editingTransactionId: null,
      currentEditData: null,
      rowEditCount: 0,
    });

    setRowModesModel({
      [id]: { mode: GridRowModes.View },
    });
  }, []);

  const handleSortModelChange = (model) => {
    if (model.length > 0) {
      updateStore({
        sortField: model[0].field,
        sortDirection: model[0].sort,
      });
    }
  };

  const handleCancelClick = useCallback(
    (id) => {
      setEditingCellId(null);
      setIsEditing(false);

      updateStore({
        editingTransactionId: null,
        currentEditData: null,
        rowEditCount: 0,
      });

      setRowModesModel({
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow?.isNew) {
        updateStore({ rows: rows.filter((row) => row.id !== id) });
      }
    },
    [rows]
  );

  useEffect(() => {
    window.isEditing = isEditing;
    return () => {
      window.isEditing = false;
    };
  }, [isEditing]);

  const transactionCategoryColorMap = useMemo(() => {
    return categories.allCategories.reduce((acc, category, index) => {
      acc[category.value] =
        categoriesColorArray[index % categoriesColorArray.length];
      return acc;
    }, {});
  }, [categories]);

  const validateRow = useCallback(
    (params) => {
      const hasError =
        !params.props.value ||
        params.props.value === '' ||
        params.props.value === 0;

      if (hasError && !isRowValid) {
        return { ...params.props, error: hasError };
      }

      return { ...params.props, error: false };
    },
    [isRowValid]
  );

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleProcessRowUpdate = async (updatedRow) => {
    const { id, isNew } = updatedRow;
    const rowKeys = Object.keys(updatedRow);

    let disableSave = 0;

    for (const key of rowKeys) {
      const value = updatedRow[key];
      if (value === '' || value === 0 || value === undefined) {
        // Skip validation for empty category since we'll auto-assign it
        if (key === 'category') continue;

        setIsRowValid(false);

        try {
          await apiRef.current.setEditCellValue({
            id: id,
            field: key,
            value: value,
          });
        } catch (error) {
          console.error('Error setting cell value:', error);
          // Continue despite error
        }
        disableSave++;
      }
    }
    if (disableSave) {
      createAlert(
        'warning',
        'Please make sure all necessary fields are filled out and try again.'
      );
      return updatedRow; // Return the row to prevent DataGrid from crashing
    }
    setIsRowValid(true);

    // Auto-categorize based on amount if category is empty
    if (!updatedRow.category || updatedRow.category === '') {
      // Use the correct category format (uppercase with underscores)
      if (updatedRow.amount > 0) {
        // Check if UNCATEGORIZED_INCOME exists in your categories, otherwise fallback to a known income category
        const incomeCategory =
          categories.allCategories.find(
            (c) => c.value === 'UNCATEGORIZED_INCOME'
          )?.value ||
          categories.allCategories.find((c) => c.type === 'income')?.value ||
          'UNCATEGORIZED_INCOME';
        updatedRow.category = incomeCategory;
      } else if (updatedRow.amount < 0) {
        // We know UNCATEGORIZED_EXPENSE exists from the error message
        updatedRow.category = 'UNCATEGORIZED_EXPENSE';
      }
    }

    // After auto-categorization, set the category_id
    updatedRow.category_id = categories.categoryIdMap[updatedRow.category]?.id;

    if (isNew) {
      saveNewTransaction(id, updatedRow);
    } else {
      editExistingTransaction(id, updatedRow);
    }

    const newRow = { ...updatedRow, isNew: false };
    updateStore({
      rows: rows.map((row) => (row.id === updatedRow.id ? newRow : row)),
    });
    return newRow;
  };

  const saveNewTransaction = async (id, addedRow) => {
    try {
      if (addedRow.isNew && (!addedRow.date || addedRow.date === '')) {
        addedRow = {
          ...addedRow,
          date: dayjs().toISOString(),
        };
      }

      const addRowResponse = await addTransaction(addedRow);
      if (addRowResponse.status === 200) {
        createAlert('success', 'Transaction successfully added.');
        const newId = addRowResponse.data.transaction_id;

        const updatedRows = rows.map((row) =>
          row.id === id ? { ...addedRow, id: newId } : row
        );
        updateStore({
          rows: updatedRows,
          rowModesModel: {
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
          },
        });
        setIsEditing(false);
        await fetchTransactions({ background: true, invalidate: true });
      }
    } catch (error) {
      createAlert('error', 'Unable to add transaction. Please try again.');
      console.error(error);
    }
  };

  const editExistingTransaction = async (id, addedRow) => {
    try {
      const editRowResponse = await editTransaction(id, addedRow);
      if (editRowResponse.status === 200) {
        setRowModesModel((prevRowModesModel) => {
          const newRowModesModel = {
            ...prevRowModesModel,
            [id]: { mode: GridRowModes.View },
          };
          setIsEditing(false);
          return newRowModesModel;
        });
        createAlert('success', 'Transaction successfully modified.');
        await fetchTransactions({ background: true, invalidate: true });
      }
    } catch (error) {
      createAlert('error', 'Unable to modify transaction. Please try again.');
      return;
    }
  };

  const handleDeleteClick = useCallback(
    (id) => {
      // Prevent deletion if currently editing
      if (isEditing) {
        createAlert(
          'warning',
          'Please finish editing before deleting a transaction.'
        );
        return;
      }

      updateStore({ selectedRowId: id, isDeleteDialogOpen: true });
    },
    [isEditing]
  );

  const handleConfirmDelete = async () => {
    try {
      const deleteRowResponse = await markTransactionAsRemoved(selectedRowId);
      if (deleteRowResponse.status === 200) {
        createAlert('success', 'Transaction successfully removed');
        updateStore({
          rows: rows.filter((row) => row.id !== selectedRowId),
          isDeleteDialogOpen: false,
          selectedRowId: null,
        });
        await fetchTransactions({ background: true, invalidate: true });
      }
    } catch (error) {
      createAlert('error', 'Unable to remove transaction. Please try again.');
    }
  };

  const handleClose = () =>
    updateStore({
      isDeleteDialogOpen: false,
      selectedRowId: null,
    });

  const handleRowModesModelChange = (newRowModesModel) => {
    // Detect when a row is exiting edit mode
    Object.entries(rowModesModel).forEach(([id, { mode }]) => {
      if (
        mode === GridRowModes.Edit &&
        (!newRowModesModel[id] ||
          newRowModesModel[id].mode === GridRowModes.View)
      ) {
        setIsEditing(false);
      }
    });

    // Check if any row is entering edit mode
    let editingCount = 0;
    Object.values(newRowModesModel).forEach(({ mode }) => {
      if (mode === GridRowModes.Edit) {
        editingCount++;
      }
    });

    // Prevent multiple rows from being edited
    if (editingCount > 1) {
      createAlert('warning', 'Only one row can be edited at a time.');
      return;
    }

    setRowModesModel(newRowModesModel);
  };

  const handleToggleChange = (event) => {
    const filter = event.target.value;
    setTransactionType(filter);
    onFilterChange(filter);
  };

  const handleUploadReceiptClick = useCallback(
    (id) => {
      const row = rows.find((row) => row.id === id);
      const receiptUrl = row?.receiptUrl || null;
      updateStore({
        currentRowId: id,
        currentReceiptUrl: receiptUrl,
        isReceiptModalOpen: true,
      });
    },
    [rows]
  );

  useEffect(() => {
    const handleNavigation = (event) => {
      if (isEditing) {
        event.preventDefault();
        event.returnValue = 'Changes that you made may not be saved.';
      }
    };

    const handlePopState = (event) => {
      handleNavigation(event);
      if (isEditing) {
        const confirmLeave = window.confirm(
          'Changes that you made may not be saved. Are you sure you want to leave?'
        );
        if (!confirmLeave) {
          event.preventDefault();
          window.history.pushState(null, '', window.location.href);
        }
      }
    };

    const handleBeforeUnload = (event) => {
      handleNavigation(event);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isEditing]);

  const columns = useMemo(
    () =>
      getGridColumns({
        accounts,
        handleEditClick,
        handleSaveClick,
        handleCancelClick,
        handleDeleteClick,
        handleUploadReceiptClick,
        rowModesModel,
        validateRow,
        isPrintMedia,
        transactionCategoryColorMap,
        categories,
        editingCellId,
      }),
    [
      accounts,
      handleEditClick,
      handleSaveClick,
      handleCancelClick,
      handleDeleteClick,
      handleUploadReceiptClick,
      rowModesModel,
      validateRow,
      isPrintMedia,
      transactionCategoryColorMap,
      categories,
      editingCellId,
    ]
  );

  const initializeNewTransaction = () => {
    // Check if already editing a row
    if (isEditing) {
      createAlert(
        'warning',
        'Please finish editing the current row before adding a new transaction.'
      );
      updateStore({ isLoadingTransactions: false });
      return;
    }

    // Generate a new unique ID string
    const id = randomId();
    // Use current time, not just date
    const now = new Date();
    const utcDate = dayjs(now).utc().format();

    const newRow = {
      id,
      date: utcDate,
      description: '',
      account: 'Other',
      category: '',
      amount: 0,
      isNew: true,
    };

    // Update the rows with the new transaction first
    const updatedRows = [newRow, ...rows];
    updateStore({
      isAddingNewTransaction: true,
      isLoadingTransactions: false,
      rows: updatedRows,
      editingTransactionId: id,
      currentEditData: newRow,
    });

    setIsEditing(true);

    // Set row mode model after the row has been added to the data source
    // Use setTimeout to ensure the row is in the DOM before setting edit mode
    setTimeout(() => {
      if (!isMobile) {
        setRowModesModel({
          [id]: { mode: GridRowModes.Edit, fieldToFocus: 'date' },
        });
      }
    }, 0);

    // Reset filters to ensure the new row is visible
    setLocalTimePeriod('all');
    setLocalSelectedWeeks([]);
    setLocalSelectedMonths([]);
    setLocalSelectedQuarters([]);
    setLocalSelectedYears([]);
    setLocalStartDate(dayjs(minDateRangeLocal).format('YYYY-MM-DD'));
    setLocalEndDate(dayjs(maxDateRangeLocal).format('YYYY-MM-DD'));
    onFilterChange('All');
  };

  const handleAddTransaction = async () => {
    if (isLoadingTransactions) return;
    updateStore({ isLoadingTransactions: true });

    const hasUnsaved = rows.some((row) => row.isNew);
    if (hasUnsaved) {
      return updateStore({ rows, isLoadingTransactions: false });
    }

    initializeNewTransaction();
  };

  if (isMobile) {
    return (
      <MobileTransactionsPage
        rows={rows}
        accounts={accounts}
        categories={categories}
        fetchTransactions={fetchTransactions}
        setAlertMessage={setAlertMessage}
        setAlertSeverity={setAlertSeverity}
        setOpenAlert={setOpenAlert}
        timePeriod={localTimePeriod}
        selectedWeeks={localSelectedWeeks}
        selectedMonths={localSelectedMonths}
        selectedQuarters={localSelectedQuarters}
        selectedYears={localSelectedYears}
        startDate={localStartDate}
        endDate={localEndDate}
        transactionCategoryColorMap={transactionCategoryColorMap}
        onFilterChange={onFilterChange}
      />
    );
  }

  return (
    <>
      <TransactionsMetricsDisplay />
      <Box
        sx={{
          minHeight: '100vh',
          height: 'auto',
          width: { xs: '95vw', sm: '95vw', md: '95vw', lg: '95vw' },
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: '25px',
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isDeleteDialogOpen && (
          <DeleteConfirmationDialog
            isOpen={isDeleteDialogOpen}
            onClose={handleClose}
            onConfirm={handleConfirmDelete}
          />
        )}
        <SDataGrid
          ignoreDiacritics
          apiRef={apiRef}
          rows={isLoadingTransactions || isAiSearchLoading ? [] : filteredRows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={(updatedRow) => handleProcessRowUpdate(updatedRow)}
          onProcessRowUpdateError={(error) =>
            console.error('Row update error:', error)
          }
          loading={isLoadingTransactions || isAiSearchLoading}
          sortModel={[{ field: sortField, sort: sortDirection }]}
          onSortModelChange={handleSortModelChange}
          getRowClassName={(params) =>
            rowModesModel[params.id]?.mode === GridRowModes.Edit
              ? 'editing'
              : ''
          }
          slots={{
            toolbar: React.memo(() => {
              return (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <EditToolbar
                    setRowModesModel={setRowModesModel}
                    onFilterChange={onFilterChange}
                    handleToggleChange={handleToggleChange}
                    isEditMode={isEditing}
                    onAddTransaction={handleAddTransaction}
                  />
                  <Box sx={{ width: '100%' }}>
                    {enableAiSearch && !isEditing ? (
                      <AiSearchBar
                        fetchTransactions={fetchTransactions}
                        onFilterChange={onFilterChange}
                        isEditMode={isEditing}
                      />
                    ) : (
                      <QuickSearchToolbar
                        fetchTransactions={fetchTransactions}
                        onFilterChange={onFilterChange}
                        isEditMode={isEditing}
                      />
                    )}
                  </Box>
                </Box>
              );
            }),
          }}
          slotProps={{
            toolbar: {
              setRows: (rows) => updateStore({ rows }),
              setRowModesModel,
            },
          }}
        />
      </Box>
      {isReceiptModalOpen && (
        <ReceiptModal
          open={isReceiptModalOpen}
          fetchTransactions={fetchTransactions}
        />
      )}
      <AiSummary transactions={rows} />
      <NavigationWarning isEditing={isEditing} editingCellId={editingCellId} />
    </>
  );
};

export default FullFeaturedCrudGrid;
