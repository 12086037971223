import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Paper,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { colors } from '../../../../themes/theme';

const TrxModal = ({
  open,
  handleClose,
  transaction,
  onSave,
  categories,
  categoryGroups,
  transactionCategoryColorMap,
}) => {
  const modalRef = useRef(null);
  const [formData, setFormData] = useState({
    date: dayjs().format('YYYY-MM-DD'),
    description: '',
    amount: '',
    account: 'Other',
    category: '',
  });
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [focusedInput, setFocusedInput] = useState(null);

  // Reset form when modal opens/closes
  useEffect(() => {
    if (transaction) {
      setFormData({
        // Store the full ISO date string to preserve the time
        date: transaction.date || dayjs().toISOString(),
        description: transaction.description || '',
        amount: transaction.amount?.toString() || '',
        account: 'Other',
        category: transaction.category || '',
      });
    } else {
      setFormData({
        date: dayjs().toISOString(), // Use full ISO format
        description: '',
        amount: '',
        account: 'Other',
        category: '',
      });
    }
  }, [transaction, open]);

  // Handle keyboard visibility
  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport) {
        const height = window.innerHeight - window.visualViewport.height;
        setKeyboardHeight(height);
      }
    };

    window.visualViewport?.addEventListener('resize', handleResize);
    return () =>
      window.visualViewport?.removeEventListener('resize', handleResize);
  }, []);

  // Scroll focused input into view
  useEffect(() => {
    if (focusedInput && modalRef.current) {
      const input = document.getElementById(focusedInput);
      if (input) {
        const modalRect = modalRef.current.getBoundingClientRect();
        const inputRect = input.getBoundingClientRect();
        const scrollNeeded = inputRect.top - modalRect.top - 80; // 80px for header
        modalRef.current.scrollTop += scrollNeeded;
      }
    }
  }, [focusedInput]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the user is changing the category, adjust the amount sign
    if (name === 'category' && formData.amount !== '') {
      const selectedCategory = value;
      const currentAmount = parseFloat(formData.amount);

      // Skip if amount is zero or not a valid number
      if (isNaN(currentAmount) || currentAmount === 0) {
        setFormData((prev) => ({ ...prev, [name]: value }));
        return;
      }

      // Check if it's an income or expense category
      const isIncomeCategory = categories.incomeCategories.some(
        (cat) => cat.value === selectedCategory
      );

      const isExpenseCategory = categories.expenseCategories.some(
        (cat) => cat.value === selectedCategory
      );

      let newAmount = currentAmount;

      // Adjust amount sign based on category type
      if (isIncomeCategory && currentAmount < 0) {
        newAmount = Math.abs(currentAmount);
      } else if (isExpenseCategory && currentAmount > 0) {
        newAmount = -Math.abs(currentAmount);
      }

      setFormData((prev) => ({
        ...prev,
        [name]: value,
        amount: newAmount.toString(),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDateChange = (date) => {
    if (!date) return;
    const newDate = dayjs(date);

    if (transaction && transaction.date) {
      const originalDate = dayjs(transaction.date);

      // Maintain time component from original date
      const combinedDate = newDate
        .hour(originalDate.hour())
        .minute(originalDate.minute())
        .second(originalDate.second())
        .millisecond(originalDate.millisecond());

      // Ensure we store a proper ISO string
      setFormData((prev) => ({
        ...prev,
        date: combinedDate.toISOString(),
      }));
    } else {
      const currentTime = dayjs();
      const combinedDate = newDate
        .hour(currentTime.hour())
        .minute(currentTime.minute())
        .second(currentTime.second())
        .millisecond(currentTime.millisecond());

      // Ensure we store a proper ISO string
      setFormData((prev) => ({
        ...prev,
        date: combinedDate.toISOString(),
      }));
    }
  };

  const handleInputFocus = (inputId) => {
    setFocusedInput(inputId);
  };

  const handleCancel = () => {
    handleClose(true);
  };

  const handleSubmit = () => {
    if (!formData.description || formData.amount === '' || !formData.category) {
      alert('Please fill out all fields.');
      return;
    }

    const category = categories.allCategories.find(
      (cat) => cat.value === formData.category
    );

    const submitData = {
      ...formData,
      category: formData.category,
      category_id: category?.id || null,
      amount: parseFloat(formData.amount),
      date: formData.date,
      authorized_date: formData.date,
    };

    onSave(submitData);
  };

  const categoryOptions = useMemo(() => {
    // Always show all categories regardless of transaction amount

    // First, show income categories
    const incomeOptions = [
      { value: '__header_INCOME', label: 'INCOME', disabled: true },
      ...(categories?.incomeCategories
        ?.filter((cat) => cat.label !== 'Income')
        .map((category) => ({
          value: category.value,
          label: category.label,
          color: transactionCategoryColorMap[category.value],
        })) || []),
    ];

    // Then show expense categories grouped by their categories
    const expenseOptions =
      categoryGroups['expense']
        ?.map((group) => [
          // Header for each expense group
          {
            value: `__header_${group.title}`,
            label: group.title,
            disabled: true,
          },
          // Map categories with their colors from the categoriesColorMap
          ...group.categories.map((categoryName) => {
            // Find the matching category from expenseCategories to get the correct value
            const matchingCategory = categories.expenseCategories.find(
              (cat) => cat.value === categoryName || cat.label === categoryName
            );
            return {
              value: matchingCategory?.value || categoryName,
              label: categoryName,
              color:
                transactionCategoryColorMap[
                  matchingCategory?.value || categoryName
                ],
            };
          }),
        ])
        .flat() || [];

    // Combine both income and expense categories
    return [...incomeOptions, ...expenseOptions];
  }, [categoryGroups, categories, transactionCategoryColorMap]);

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      backgroundColor: '#f8f9fa',
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: '#f0f1f2',
      },
      '&.Mui-focused': {
        backgroundColor: '#ffffff',
        boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.1)',
      },
    },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      <Box
        ref={modalRef}
        sx={{
          width: '100%',
          maxWidth: '600px',
          margin: '0 auto',
          maxHeight: `calc(100vh - ${keyboardHeight}px)`,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          boxShadow: '0px -4px 20px rgba(0, 0, 0, 0.15)',
          transition: 'all 0.3s ease',
        }}
      >
        {/* Header */}
        <Paper
          elevation={0}
          sx={{
            position: 'sticky',
            top: 0,
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            bgcolor: 'white',
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              px: 2,
              py: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, color: colors.primary }}
            >
              {transaction ? 'Edit Transaction' : 'New Transaction'}
            </Typography>
            <IconButton onClick={handleCancel} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Paper>

        {/* Form Content */}
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            p: 2,
            pb: 12, // Space for footer
          }}
        >
          <MobileDatePicker
            label="Date"
            value={dayjs(formData.date)}
            onChange={handleDateChange}
            slotProps={{
              textField: {
                id: 'date-input',
                onFocus: () => handleInputFocus('date-input'),
                sx: { ...inputStyles, mb: 2 },
                fullWidth: true,
                // Format display for user but keep full date internally
                inputFormat: 'YYYY-MM-DD',
              },
            }}
          />
          <TextField
            id="amount-input"
            name="amount"
            label="Amount"
            type="number"
            value={formData.amount}
            onChange={handleChange}
            onFocus={() => handleInputFocus('amount-input')}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            sx={{ ...inputStyles, mb: 2 }}
          />
          <TextField
            id="description-input"
            name="description"
            label="Description"
            value={formData.description}
            onChange={handleChange}
            onFocus={() => handleInputFocus('description-input')}
            fullWidth
            sx={{ ...inputStyles, mb: 2 }}
          />
          <TextField
            id="account-input"
            name="account"
            label="Account"
            value={formData.account}
            InputProps={{ readOnly: true }}
            onFocus={() => handleInputFocus('account-input')}
            fullWidth
            sx={{ ...inputStyles, mb: 2 }}
          />
          <FormControl fullWidth sx={{ ...inputStyles }}>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              id="category-input"
              labelId="category-label"
              name="category"
              value={formData.category || ''}
              onChange={handleChange}
              onFocus={() => handleInputFocus('category-input')}
              label="Category"
            >
              {categoryOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                  sx={{
                    py: 1.5,
                    ...(option.disabled
                      ? {
                          bgcolor: 'rgba(0, 0, 0, 0.04)',
                          fontWeight: 600,
                          color: colors.primary,
                        }
                      : {
                          pl: 3,
                        }),
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Footer */}
        <Paper
          elevation={3}
          sx={{
            position: 'fixed',
            bottom: keyboardHeight,
            left: 0,
            right: 0,
            bgcolor: 'white',
            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              p: 2,
              display: 'flex',
              gap: 2,
              maxWidth: '600px',
              margin: '0 auto',
            }}
          >
            <Button
              fullWidth
              variant="outlined"
              onClick={handleCancel}
              sx={{
                borderRadius: '12px',
                py: 1.5,
                borderColor: colors.primary,
                color: colors.primary,
                '&:hover': {
                  borderColor: colors.primary,
                  bgcolor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{
                borderRadius: '12px',
                py: 1.5,
                bgcolor: colors.primary,
                '&:hover': {
                  bgcolor: colors.primary,
                  opacity: 0.9,
                },
              }}
            >
              {transaction ? 'Update' : 'Save'}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default TrxModal;
