import React, { useRef, useEffect } from 'react';
import { Box, Button, IconButton, TextField, Divider } from '@mui/material';
import { SGridToolbarQuickFilter } from '../../styles/style.js';
import ToggleAiSearch from '../AiSearch/ToggleAiSearch.js';
import { useStore } from '@tanstack/react-store';
import { store, updateStore } from '../../../data/store.js';
import AiSearchBar from '../AiSearch';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  AutoAwesome as AutoAwesomeIcon,
} from '@mui/icons-material';

function QuickSearchToolbar({
  fetchTransactions,
  onFilterChange,
  value,
  isEditMode,
  isMobile,
}) {
  const enableAiSearch = useStore(store, (state) => state.enableAiSearch);
  const inputRef = useRef(null);

  useEffect(() => {
    const input = inputRef.current?.querySelector('input');
    if (input) {
      const scrollToEnd = () => {
        input.scrollLeft = input.scrollWidth;
      };
      input.addEventListener('input', scrollToEnd);
      return () => input.removeEventListener('input', scrollToEnd);
    }
  }, []);

  const handleClear = () => {
    if (isEditMode) return;
    if (onFilterChange) {
      onFilterChange('');
    }
    if (fetchTransactions) {
      fetchTransactions({ background: true });
    }
  };

  const handleInputChange = (event) => {
    if (isEditMode) return;
    if (onFilterChange) {
      onFilterChange(event.target.value);
    }
  };

  const handleToggleAiSearch = () => {
    updateStore({ enableAiSearch: !enableAiSearch });
  };

  // Desktop version
  if (!isMobile) {
    return (
      <Box sx={{ width: '100%', padding: '0' }}>
        {enableAiSearch && !isEditMode ? (
          <AiSearchBar
            fetchTransactions={fetchTransactions}
            onFilterChange={onFilterChange}
            isEditMode={isEditMode}
          />
        ) : (
          <Box
            ref={inputRef}
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              opacity: isEditMode ? 0.6 : 1,
              pointerEvents: isEditMode ? 'none' : 'auto',
              transition: 'opacity 0.2s ease',
              '& .MuiInputBase-root': {
                paddingRight: '180px',
                height: 'auto',
                minHeight: '40px',
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiInputBase-input': {
                whiteSpace: 'nowrap',
                overflow: 'auto',
                textOverflow: 'ellipsis',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                maxWidth: 'calc(100% - 200px)',
                '&:focus': {
                  outline: 'none',
                },
              },
            }}
          >
            <SGridToolbarQuickFilter
              disabled={isEditMode}
              quickFilterProps={{
                placeholder: 'Search transactions...',
                variant: 'standard',
                multiline: false,
                disabled: isEditMode,
                value: value,
                InputProps: {
                  disableUnderline: true,
                  onChange: handleInputChange,
                  readOnly: isEditMode,
                },
              }}
              sx={{
                width: '100%',
                '& .MuiInput-underline:before': {
                  display: 'none',
                },
                '& .MuiInput-underline:after': {
                  display: 'none',
                },
                '& .Mui-disabled': {
                  cursor: 'not-allowed',
                  WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
                  '&::placeholder': {
                    opacity: 0.5,
                  },
                },
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                right: '16px',
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: 'white',
                zIndex: 1,
                borderLeft: '8px solid white',
                pointerEvents: isEditMode ? 'none' : 'auto',
              }}
            >
              {value && (
                <Box
                  onClick={handleClear}
                  sx={{
                    cursor: isEditMode ? 'not-allowed' : 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      opacity: isEditMode ? 1 : 0.7,
                    },
                  }}
                >
                  <ClearIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                      fill: 'url(#iconGradient)',
                    }}
                  />
                </Box>
              )}

              <Divider
                orientation="vertical"
                flexItem
                sx={{ height: '24px' }}
              />
              <ToggleAiSearch disabled={isEditMode} />
            </Box>
          </Box>
        )}
        <svg width="0" height="0">
          <defs>
            <linearGradient
              id="iconGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop offset="0%" stopColor="#5aa8ff" />
              <stop offset="100%" stopColor="#ff6482" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
    );
  }

  // Mobile version
  return (
    <Box sx={{ width: '100%', padding: '0' }}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '12px',
          border: '0.3px solid',
          opacity: isEditMode ? 0.6 : 1,
          pointerEvents: isEditMode ? 'none' : 'auto',
          height: '48px',
          transition: 'all 0.2s ease',
          '&:hover': {
            border: '1px solid rgba(0, 0, 0, 0.2)',
          },
          '&:focus-within': {
            border: '1px solid rgba(90, 168, 255, 0.5)',
            boxShadow: '0 0 0 4px rgba(90, 168, 255, 0.1)',
          },
        }}
      >
        {enableAiSearch ? (
          <AiSearchBar
            fetchTransactions={fetchTransactions}
            onFilterChange={onFilterChange}
            isEditMode={isEditMode}
            isMobile={true}
          />
        ) : (
          <>
            <SearchIcon
              sx={{
                ml: 2,
                fontSize: '20px',
                background: 'linear-gradient(135deg, #5aa8ff 0%, #ff6482 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            />

            <TextField
              fullWidth
              variant="standard"
              placeholder="Search transactions..."
              value={value}
              onChange={handleInputChange}
              disabled={isEditMode}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                ml: 1,
                mr: 1,
                flex: 1,
                '& .MuiInputBase-root': {
                  height: '100%',
                  fontSize: '14px',
                  color: '#333',
                  backgroundColor: 'transparent',
                },
                '& .MuiInputBase-input': {
                  padding: '8px 0',
                  '&::placeholder': {
                    color: '#757575',
                    opacity: 1,
                  },
                },
              }}
            />

            {value && !enableAiSearch && (
              <IconButton
                size="small"
                onClick={handleClear}
                sx={{
                  padding: '4px',
                  mr: 1,
                  '& svg': {
                    fontSize: '18px',
                    background:
                      'linear-gradient(135deg, #5aa8ff 0%, #ff6482 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  },
                }}
              >
                <ClearIcon />
              </IconButton>
            )}

            <Button
              size="small"
              onClick={handleToggleAiSearch}
              startIcon={<AutoAwesomeIcon sx={{ fontSize: '16px' }} />}
              sx={{
                minWidth: 'auto',
                height: '32px',
                mr: 1,
                borderRadius: '8px',
                px: 1.5,
                background: enableAiSearch
                  ? 'linear-gradient(135deg, #5aa8ff 0%, #ff6482 100%)'
                  : 'transparent',
                color: enableAiSearch ? 'white' : '#666',
                border: enableAiSearch ? 'none' : '1px solid #ddd',
                fontSize: '13px',
                textTransform: 'none',
                fontWeight: 500,
                '&:hover': {
                  background: enableAiSearch
                    ? 'linear-gradient(135deg, #4997ee 0%, #ee5371 100%)'
                    : 'rgba(0, 0, 0, 0.04)',
                },
                '& .MuiSvgIcon-root': {
                  background: enableAiSearch
                    ? 'none'
                    : 'linear-gradient(135deg, #5aa8ff 0%, #ff6482 100%)',
                  WebkitBackgroundClip: enableAiSearch ? 'none' : 'text',
                  WebkitTextFillColor: enableAiSearch ? 'white' : 'transparent',
                },
              }}
            >
              AI
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}

export default QuickSearchToolbar;
