import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  Box,
  CardContent,
  Stack,
  IconButton,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { RestartAlt as RestartAltIcon } from '@mui/icons-material';
import DateRangeIndicator from './DateRangeIndicator';
import { DateControls, isDefaultDateRange } from './shared-components';
import { colors } from '../../../themes/theme';
import { useDashboardContext } from '../../common/contexts/DashboardContext';
import HomeMetricsDisplay from './HomeMetricsDisplay';
import SalesMetricsDisplay from './SalesMetricsDisplay';

// Styled component for the time period toggle button group
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    display: 'flex',
    gap: 8,
    width: '90%',
    height: '35px',
    position: 'relative',
    bottom: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 1,
    '& .MuiToggleButton-root': {
      flex: 1,
      padding: theme.spacing(0.75),
      color: colors.white,
      backgroundColor: colors.primary,
      borderRadius: '24px',
      fontSize: '0.875rem',
      border: `1px solid ${colors.primary}`,
      transition: 'all 0.2s ease',
      '&.Mui-selected': {
        backgroundColor: colors.secondary,
        color: colors.white,
        borderColor: colors.secondary,
        '&:hover': {
          backgroundColor: colors.secondary,
        },
      },
      '&:hover': {
        backgroundColor: colors.primary,
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1),
      },
    },
  })
);

// BaseMetricsDisplay handles shared logic for different metric display types
const BaseMetricsDisplay = React.memo(
  ({ onTimePeriodChange, onDateChange, children }) => {
    // Access dashboard context for shared state
    const {
      activeTimeTab,
      setActiveTimeTab,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      minDateRangeLocal,
      maxDateRangeLocal,
      setIsCustomRange,
    } = useDashboardContext();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdOrBelow = useMediaQuery(theme.breakpoints.down('md'));
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 700px)');

    const [canReset, setCanReset] = useState(false);

    // Refs to track UI interaction states without triggering re-renders
    const dateSelectionInProgress = useRef(false);
    const resetTriggered = useRef(false);

    // Check if reset button should be enabled based on date range
    useEffect(() => {
      const isDefault = isDefaultDateRange(
        startDate,
        endDate,
        minDateRangeLocal,
        maxDateRangeLocal
      );
      setCanReset(!isDefault);
    }, [startDate, endDate, minDateRangeLocal, maxDateRangeLocal]);

    // Reset dates to default range (all time)
    const handleReset = useCallback(() => {
      resetTriggered.current = true;

      const minDate = dayjs(minDateRangeLocal);
      const maxDate = dayjs(maxDateRangeLocal);

      // Check if current dates already match min/max range
      const isMinMaxMatch =
        minDate.isSame(dayjs(startDate), 'day') &&
        maxDate.isSame(dayjs(endDate), 'day');
      setIsCustomRange(!isMinMaxMatch);

      // Format the dates for setting
      const newStartDate = minDate.format('YYYY-MM-DD');
      const newEndDate = maxDate.format('YYYY-MM-DD');

      // Use the onDateChange prop if provided, otherwise update context
      if (onDateChange) {
        onDateChange(newStartDate, newEndDate);
      } else {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      }

      setCanReset(false);
      setActiveTimeTab('all');
      localStorage.removeItem('lastMobileShortcut');

      // Trigger time period change to recalculate metrics
      onTimePeriodChange('all');

      // Reset the flag after a short delay
      setTimeout(() => {
        resetTriggered.current = false;
      }, 100);
    }, [
      minDateRangeLocal,
      maxDateRangeLocal,
      setStartDate,
      setEndDate,
      setActiveTimeTab,
      onTimePeriodChange,
      setIsCustomRange,
      onDateChange,
      startDate,
      endDate,
    ]);

    // Handle time period tab selection
    const handleTimeTabChange = useCallback(
      (_, newValue) => {
        if (newValue === null) return;

        const maxDate = dayjs(maxDateRangeLocal);
        const minDate = dayjs(minDateRangeLocal);
        let newStartDate, newEndDate;

        // Calculate date range based on selected time period
        switch (newValue) {
          case '7days':
            newStartDate = dayjs.max(maxDate.subtract(6, 'day'), minDate);
            newEndDate = maxDate;
            setCanReset(true);
            setIsCustomRange(false);
            break;
          case '30days':
            newStartDate = dayjs.max(maxDate.subtract(29, 'day'), minDate);
            newEndDate = maxDate;
            setCanReset(true);
            setIsCustomRange(false);
            break;
          case '90days':
            newStartDate = dayjs.max(maxDate.subtract(89, 'day'), minDate);
            newEndDate = maxDate;
            setCanReset(true);
            setIsCustomRange(false);
            break;
          case '365days':
            newStartDate = dayjs.max(maxDate.subtract(364, 'day'), minDate);
            newEndDate = maxDate;
            setCanReset(true);
            setIsCustomRange(false);
            break;
          case 'all':
            newStartDate = minDate;
            newEndDate = maxDate;
            setCanReset(false);
            // Check if dates match min/max when 'all' is selected
            const isMinMaxMatch =
              dayjs(minDate).isSame(dayjs(startDate), 'day') &&
              dayjs(maxDate).isSame(dayjs(endDate), 'day');
            setIsCustomRange(!isMinMaxMatch);
            break;
          default:
            return;
        }

        // Use onDateChange if provided, otherwise update context directly
        if (onDateChange) {
          onDateChange(
            newStartDate.format('YYYY-MM-DD'),
            newEndDate.format('YYYY-MM-DD')
          );
        } else {
          setStartDate(newStartDate.format('YYYY-MM-DD'));
          setEndDate(newEndDate.format('YYYY-MM-DD'));
        }

        setActiveTimeTab(newValue);
        onTimePeriodChange(newValue);
      },
      [
        maxDateRangeLocal,
        minDateRangeLocal,
        setStartDate,
        setEndDate,
        setActiveTimeTab,
        onTimePeriodChange,
        endDate,
        startDate,
        setIsCustomRange,
        onDateChange,
      ]
    );

    // Handle custom date range selection from the date picker
    const handleCustomDateRangeSelect = useCallback(
      (newStartDate, newEndDate) => {
        // Only treat as a final selection if both dates are provided
        const isFinalSelection = newStartDate && newEndDate;

        if (isFinalSelection) {
          dateSelectionInProgress.current = false;

          // Use onDateChange prop if provided
          if (onDateChange) {
            onDateChange(newStartDate, newEndDate);
          } else {
            setStartDate(newStartDate);
            setEndDate(newEndDate);
          }

          const isDefault = isDefaultDateRange(
            newStartDate,
            newEndDate,
            minDateRangeLocal,
            maxDateRangeLocal
          );

          setCanReset(!isDefault);
          setIsCustomRange(!isDefault);

          // Always set to 'all' when using custom date range
          setActiveTimeTab('all');
          onTimePeriodChange('all');
        } else {
          // Just changing the start date, don't do a full state update
          dateSelectionInProgress.current = true;
          setStartDate(newStartDate);
        }
      },
      [
        setStartDate,
        setEndDate,
        setActiveTimeTab,
        onTimePeriodChange,
        minDateRangeLocal,
        maxDateRangeLocal,
        setIsCustomRange,
        onDateChange,
      ]
    );

    // Toggle button options for desktop and mobile
    const desktopToggleOptions = [
      { value: '7days', label: 'Past 7D' },
      { value: '30days', label: 'Past 30D' },
      { value: '90days', label: 'Past 90D' },
      { value: '365days', label: 'Past Year' },
      { value: 'all', label: 'All Time' },
    ];

    const mobileToggleOptions = [
      { value: '7days', label: '7D' },
      { value: '30days', label: '30D' },
      { value: '90days', label: '90D' },
      { value: '365days', label: 'YR' },
      { value: 'all', label: 'All' },
    ];

    return (
      <Box sx={{ marginTop: isMdOrBelow ? 1 : '-20px' }}>
        {!isMdOrBelow ? (
          // Desktop/Tablet layout
          <Box
            sx={{
              width: '95vw',
              height: 'fit-content',
              backgroundColor: 'white',
              mb: 0,
              pb: { xs: 2, sm: 2 },
              borderRadius: 5,
              boxShadow: colors.shadow,
              position: 'relative',
            }}
          >
            <StyledToggleButtonGroup
              value={activeTimeTab}
              exclusive
              onChange={handleTimeTabChange}
              size={isMobile ? 'small' : 'medium'}
              color="primary"
              sx={{
                width: isTablet ? '85%' : '90%',
              }}
            >
              {desktopToggleOptions.map(({ value, label }) => (
                <ToggleButton
                  key={value}
                  value={value}
                  sx={{ textTransform: 'none' }}
                >
                  {label}
                </ToggleButton>
              ))}
            </StyledToggleButtonGroup>

            <CardContent sx={{ pb: '8px !important', pt: '0 !important' }}>
              <Stack>
                <DateControls>
                  <Box sx={{ flex: 1, mr: 1 }}>
                    <DateRangeIndicator
                      startDate={startDate}
                      endDate={endDate}
                      minDateRange={minDateRangeLocal}
                      maxDateRange={maxDateRangeLocal}
                      onDateChange={handleCustomDateRangeSelect}
                      canReset={canReset}
                      isMobile={isMobile}
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <span>
                      <IconButton
                        onClick={handleReset}
                        disabled={isDefaultDateRange(
                          startDate,
                          endDate,
                          minDateRangeLocal,
                          maxDateRangeLocal
                        )}
                        size="small"
                        sx={{
                          top: '-16px',
                          backgroundColor: colors.secondary,
                          color: colors.white,
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            backgroundColor: colors.hoverBg,
                            transform: 'scale(1.05)',
                          },
                          '&.Mui-disabled': {
                            backgroundColor:
                              theme.palette.action.disabledBackground,
                            color: theme.palette.action.disabled,
                          },
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Box>
                </DateControls>

                <Box sx={{ mt: 2 }}>
                  {/* Clone children and pass additional props */}
                  {React.Children.map(children, (child) =>
                    React.cloneElement(child, {
                      isMobile,
                      isTablet,
                      canReset,
                      startDate,
                      endDate,
                    })
                  )}
                </Box>
              </Stack>
            </CardContent>
          </Box>
        ) : (
          // Mobile layout
          <Box
            sx={{
              width: {
                xs: '100vw',
                sm: '100vw',
                md: '85vw',
                lg: '80vw',
              },
              backgroundColor: 'white',
              mb: 0,
              pb: { xs: 2, sm: 2 },
              borderRadius: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
              },
              boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
              position: 'relative',
              zIndex: 0,
              pt: 2,
              top: -30,
            }}
          >
            <StyledToggleButtonGroup
              value={activeTimeTab}
              exclusive
              onChange={handleTimeTabChange}
              size={isMobile ? 'small' : 'medium'}
              color="primary"
              sx={{
                width: isTablet ? '85%' : '90%',
                mb: -3,
                top: -35,
                zIndex: 1,
              }}
            >
              {mobileToggleOptions.map(({ value, label }) => (
                <ToggleButton
                  key={value}
                  value={value}
                  sx={{ textTransform: 'none' }}
                >
                  {label}
                </ToggleButton>
              ))}
            </StyledToggleButtonGroup>

            <CardContent sx={{ pb: '8px !important' }}>
              <Stack spacing={2}>
                <Box
                  sx={{
                    marginTop: '0 !important',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 1,
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <DateRangeIndicator
                      startDate={startDate}
                      endDate={endDate}
                      minDateRange={minDateRangeLocal}
                      maxDateRange={maxDateRangeLocal}
                      onDateChange={handleCustomDateRangeSelect}
                      isCustomRange={canReset}
                      isMobile={true}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Tooltip title="Reset date range" placement="top">
                      <span>
                        <IconButton
                          onClick={handleReset}
                          disabled={isDefaultDateRange(
                            startDate,
                            endDate,
                            minDateRangeLocal,
                            maxDateRangeLocal
                          )}
                          size="small"
                          sx={{
                            top: '-14px',
                            backgroundColor: colors.secondary,
                            color: colors.white,
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              backgroundColor: colors.hoverBg,
                              transform: 'scale(1.05)',
                            },
                            '&.Mui-disabled': {
                              backgroundColor:
                                theme.palette.action.disabledBackground,
                              color: theme.palette.action.disabled,
                            },
                          }}
                        >
                          <RestartAltIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>

                <Box sx={{ mt: 1.5 }}>
                  {/* Clone children and pass additional props */}
                  {React.Children.map(children, (child) =>
                    React.cloneElement(child, {
                      isMobile,
                      isTablet,
                      canReset,
                      startDate,
                      endDate,
                    })
                  )}
                </Box>
              </Stack>
            </CardContent>
          </Box>
        )}
      </Box>
    );
  }
);

// Utility function to calculate average items per sale
export const calculateItemsPerSale = (itemsSold, totalTransactions) => {
  if (!itemsSold || !totalTransactions) return '0';

  const items = parseInt(itemsSold.replace(/,/g, ''), 10);
  const transactions = parseInt(totalTransactions.replace(/,/g, ''), 10);

  return transactions > 0 ? (items / transactions).toFixed(1) : '0';
};

// Main MetricsDisplay component that selects the appropriate display type
const MetricsDisplay = (props) => {
  const { metricsType = 'transactions', ...otherProps } = props;

  return (
    <BaseMetricsDisplay metricsType={metricsType} {...otherProps}>
      {metricsType === 'sales' ? (
        <SalesMetricsDisplay {...otherProps} />
      ) : (
        <HomeMetricsDisplay {...otherProps} />
      )}
    </BaseMetricsDisplay>
  );
};

// Export components and utility functions
export { HomeMetricsDisplay, SalesMetricsDisplay, BaseMetricsDisplay };
export default MetricsDisplay;
