import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

// Add dayjs plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// Simplified sales data with predefined transactions
export const dummySalesData = [
  {
    id: 1,
    date: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
    details: 'In-store purchase - General Merchandise',
    source: 'Square Terminal',
    items: 3,
    amount: 8750, // $87.50
    status: 'COMPLETED',
    paymentMethod: 'Credit Card',
    totalAmount: 87.5,
    costOfGoods: 52.5,
    profit: 35.0,
    category: 'Square Terminal',
    payment: {
      id: '0001',
      created_at: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
      note: 'In-store purchase - General Merchandise',
      status: 'COMPLETED',
      amount_money: { amount: 8750, currency: 'USD' },
      source_type: 'TERMINAL',
      card_details: { card: { card_brand: 'VISA' } },
    },
    order: { line_items: [{}, {}, {}] },
  },
  {
    id: 2,
    date: dayjs().subtract(5, 'day').format('YYYY-MM-DD'),
    details: 'Online order - Subscription',
    source: 'Online Store',
    items: 1,
    amount: 2999, // $29.99
    status: 'COMPLETED',
    paymentMethod: 'Credit Card',
    totalAmount: 29.99,
    costOfGoods: 15.0,
    profit: 14.99,
    category: 'Online Store',
    payment: {
      id: '0002',
      created_at: dayjs().subtract(5, 'day').format('YYYY-MM-DD'),
      note: 'Online order - Subscription',
      status: 'COMPLETED',
      amount_money: { amount: 2999, currency: 'USD' },
      source_type: 'ONLINE',
      card_details: { card: { card_brand: 'VISA' } },
    },
    order: { line_items: [{}] },
  },
  {
    id: 3,
    date: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    details: 'Mobile purchase - Food & Beverage',
    source: 'Mobile Payment',
    items: 2,
    amount: 1845, // $18.45
    status: 'COMPLETED',
    paymentMethod: 'Credit Card',
    totalAmount: 18.45,
    costOfGoods: 7.38,
    profit: 11.07,
    category: 'Mobile Payment',
    payment: {
      id: '0003',
      created_at: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
      note: 'Mobile purchase - Food & Beverage',
      status: 'COMPLETED',
      amount_money: { amount: 1845, currency: 'USD' },
      source_type: 'MOBILE',
      card_details: { card: { card_brand: 'VISA' } },
    },
    order: { line_items: [{}, {}] },
  },
  {
    id: 4,
    date: dayjs().subtract(10, 'day').format('YYYY-MM-DD'),
    details: 'In-store purchase - General Merchandise',
    source: 'Square Terminal',
    items: 5,
    amount: 12599, // $125.99
    status: 'COMPLETED',
    paymentMethod: 'Cash',
    totalAmount: 125.99,
    costOfGoods: 75.59,
    profit: 50.4,
    category: 'Square Terminal',
    payment: {
      id: '0004',
      created_at: dayjs().subtract(10, 'day').format('YYYY-MM-DD'),
      note: 'In-store purchase - General Merchandise',
      status: 'COMPLETED',
      amount_money: { amount: 12599, currency: 'USD' },
      source_type: 'TERMINAL',
      card_details: null,
    },
    order: { line_items: [{}, {}, {}, {}, {}] },
  },
  {
    id: 5,
    date: dayjs().subtract(12, 'day').format('YYYY-MM-DD'),
    details: 'Refund issued for returned items',
    source: 'Square Terminal',
    items: 1,
    amount: -4500, // -$45.00
    status: 'COMPLETED',
    paymentMethod: 'Credit Card',
    totalAmount: -45.0,
    costOfGoods: -27.0,
    profit: -18.0,
    category: 'Square Terminal',
    payment: {
      id: '0005',
      created_at: dayjs().subtract(12, 'day').format('YYYY-MM-DD'),
      note: 'Refund issued for returned items',
      status: 'COMPLETED',
      amount_money: { amount: -4500, currency: 'USD' },
      source_type: 'TERMINAL',
      card_details: { card: { card_brand: 'VISA' } },
    },
    order: { line_items: [{}] },
  },
  {
    id: 6,
    date: dayjs().subtract(15, 'day').format('YYYY-MM-DD'),
    details: 'Online order - Subscription',
    source: 'Online Store',
    items: 1,
    amount: 9999, // $99.99
    status: 'COMPLETED',
    paymentMethod: 'Credit Card',
    totalAmount: 99.99,
    costOfGoods: 59.99,
    profit: 40.0,
    category: 'Online Store',
    payment: {
      id: '0006',
      created_at: dayjs().subtract(15, 'day').format('YYYY-MM-DD'),
      note: 'Online order - Subscription',
      status: 'COMPLETED',
      amount_money: { amount: 9999, currency: 'USD' },
      source_type: 'ONLINE',
      card_details: { card: { card_brand: 'VISA' } },
    },
    order: { line_items: [{}] },
  },
  {
    id: 7,
    date: dayjs().subtract(20, 'day').format('YYYY-MM-DD'),
    details: 'In-store purchase - General Merchandise',
    source: 'Square Terminal',
    items: 2,
    amount: 6799, // $67.99
    status: 'COMPLETED',
    paymentMethod: 'Cash',
    totalAmount: 67.99,
    costOfGoods: 40.79,
    profit: 27.2,
    category: 'Square Terminal',
    payment: {
      id: '0007',
      created_at: dayjs().subtract(20, 'day').format('YYYY-MM-DD'),
      note: 'In-store purchase - General Merchandise',
      status: 'COMPLETED',
      amount_money: { amount: 6799, currency: 'USD' },
      source_type: 'TERMINAL',
      card_details: null,
    },
    order: { line_items: [{}, {}] },
  },
  {
    id: 8,
    date: dayjs().subtract(25, 'day').format('YYYY-MM-DD'),
    details: 'Mobile purchase - Food & Beverage',
    source: 'Mobile Payment',
    items: 3,
    amount: 3475, // $34.75
    status: 'COMPLETED',
    paymentMethod: 'Credit Card',
    totalAmount: 34.75,
    costOfGoods: 20.85,
    profit: 13.9,
    category: 'Mobile Payment',
    payment: {
      id: '0008',
      created_at: dayjs().subtract(25, 'day').format('YYYY-MM-DD'),
      note: 'Mobile purchase - Food & Beverage',
      status: 'COMPLETED',
      amount_money: { amount: 3475, currency: 'USD' },
      source_type: 'MOBILE',
      card_details: { card: { card_brand: 'VISA' } },
    },
    order: { line_items: [{}, {}, {}] },
  },
  {
    id: 9,
    date: dayjs().subtract(28, 'day').format('YYYY-MM-DD'),
    details: 'Transaction under dispute - Pending resolution',
    source: 'Online Store',
    items: 1,
    amount: 5999, // $59.99
    status: 'PENDING',
    paymentMethod: 'Credit Card',
    totalAmount: 59.99,
    costOfGoods: 35.99,
    profit: 24.0,
    category: 'Online Store',
    payment: {
      id: '0009',
      created_at: dayjs().subtract(28, 'day').format('YYYY-MM-DD'),
      note: 'Transaction under dispute - Pending resolution',
      status: 'PENDING',
      amount_money: { amount: 5999, currency: 'USD' },
      source_type: 'ONLINE',
      card_details: { card: { card_brand: 'VISA' } },
    },
    order: { line_items: [{}] },
  },
  {
    id: 10,
    date: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
    details: 'In-store purchase - General Merchandise',
    source: 'Square Terminal',
    items: 4,
    amount: 15299, // $152.99
    status: 'COMPLETED',
    paymentMethod: 'Gift Card',
    totalAmount: 152.99,
    costOfGoods: 91.79,
    profit: 61.2,
    category: 'Square Terminal',
    payment: {
      id: '0010',
      created_at: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
      note: 'In-store purchase - General Merchandise',
      status: 'COMPLETED',
      amount_money: { amount: 15299, currency: 'USD' },
      source_type: 'TERMINAL',
      card_details: null,
    },
    order: { line_items: [{}, {}, {}, {}] },
  },
  // Adding older dummy data for proper period-over-period comparison
  {
    id: 11,
    date: dayjs().subtract(35, 'day').format('YYYY-MM-DD'),
    details: 'In-store purchase - Electronics',
    source: 'Square Terminal',
    items: 2,
    amount: 19999, // $199.99
    status: 'COMPLETED',
    paymentMethod: 'Credit Card',
    totalAmount: 199.99,
    costOfGoods: 119.99,
    profit: 80.0,
    category: 'Square Terminal',
    payment: {
      id: '0011',
      created_at: dayjs().subtract(35, 'day').format('YYYY-MM-DD'),
      note: 'In-store purchase - Electronics',
      status: 'COMPLETED',
      amount_money: { amount: 19999, currency: 'USD' },
      source_type: 'TERMINAL',
      card_details: { card: { card_brand: 'MASTERCARD' } },
    },
    order: { line_items: [{}, {}] },
  },
  {
    id: 12,
    date: dayjs().subtract(40, 'day').format('YYYY-MM-DD'),
    details: 'Online order - Home Goods',
    source: 'Online Store',
    items: 3,
    amount: 7495, // $74.95
    status: 'COMPLETED',
    paymentMethod: 'Credit Card',
    totalAmount: 74.95,
    costOfGoods: 44.97,
    profit: 29.98,
    category: 'Online Store',
    payment: {
      id: '0012',
      created_at: dayjs().subtract(40, 'day').format('YYYY-MM-DD'),
      note: 'Online order - Home Goods',
      status: 'COMPLETED',
      amount_money: { amount: 7495, currency: 'USD' },
      source_type: 'ONLINE',
      card_details: { card: { card_brand: 'VISA' } },
    },
    order: { line_items: [{}, {}, {}] },
  },
  {
    id: 13,
    date: dayjs().subtract(45, 'day').format('YYYY-MM-DD'),
    details: 'Refund issued for damaged items',
    source: 'Square Terminal',
    items: 1,
    amount: -6295, // -$62.95
    status: 'COMPLETED',
    paymentMethod: 'Credit Card',
    totalAmount: -62.95,
    costOfGoods: -37.77,
    profit: -25.18,
    category: 'Square Terminal',
    payment: {
      id: '0013',
      created_at: dayjs().subtract(45, 'day').format('YYYY-MM-DD'),
      note: 'Refund issued for damaged items',
      status: 'COMPLETED',
      amount_money: { amount: -6295, currency: 'USD' },
      source_type: 'TERMINAL',
      card_details: { card: { card_brand: 'VISA' } },
    },
    order: { line_items: [{}] },
  },
  {
    id: 14,
    date: dayjs().subtract(50, 'day').format('YYYY-MM-DD'),
    details: 'Mobile purchase - Apparel',
    source: 'Mobile Payment',
    items: 2,
    amount: 4995, // $49.95
    status: 'COMPLETED',
    paymentMethod: 'Credit Card',
    totalAmount: 49.95,
    costOfGoods: 29.97,
    profit: 19.98,
    category: 'Mobile Payment',
    payment: {
      id: '0014',
      created_at: dayjs().subtract(50, 'day').format('YYYY-MM-DD'),
      note: 'Mobile purchase - Apparel',
      status: 'COMPLETED',
      amount_money: { amount: 4995, currency: 'USD' },
      source_type: 'MOBILE',
      card_details: { card: { card_brand: 'VISA' } },
    },
    order: { line_items: [{}, {}] },
  },
  {
    id: 15,
    date: dayjs().subtract(55, 'day').format('YYYY-MM-DD'),
    details: 'In-store purchase - General Merchandise',
    source: 'Square Terminal',
    items: 1,
    amount: 3999, // $39.99
    status: 'COMPLETED',
    paymentMethod: 'Cash',
    totalAmount: 39.99,
    costOfGoods: 23.99,
    profit: 16.0,
    category: 'Square Terminal',
    payment: {
      id: '0015',
      created_at: dayjs().subtract(55, 'day').format('YYYY-MM-DD'),
      note: 'In-store purchase - General Merchandise',
      status: 'COMPLETED',
      amount_money: { amount: 3999, currency: 'USD' },
      source_type: 'TERMINAL',
      card_details: null,
    },
    order: { line_items: [{}] },
  },
  {
    id: 16,
    date: dayjs().subtract(60, 'day').format('YYYY-MM-DD'),
    details: 'Online order - Subscription',
    source: 'Online Store',
    items: 1,
    amount: 2999, // $29.99
    status: 'COMPLETED',
    paymentMethod: 'Credit Card',
    totalAmount: 29.99,
    costOfGoods: 15.0,
    profit: 14.99,
    category: 'Online Store',
    payment: {
      id: '0016',
      created_at: dayjs().subtract(60, 'day').format('YYYY-MM-DD'),
      note: 'Online order - Subscription',
      status: 'COMPLETED',
      amount_money: { amount: 2999, currency: 'USD' },
      source_type: 'ONLINE',
      card_details: { card: { card_brand: 'VISA' } },
    },
    order: { line_items: [{}] },
  },
];

/**
 * Calculate sales metrics with real period-over-period comparison
 *
 * @param {Array} salesData - Array of sales transactions
 * @param {string} period - Time period ('7days', '30days', '90days', '365days', or 'all')
 * @param {string|null} customStartDate - Optional custom start date (YYYY-MM-DD)
 * @param {string|null} customEndDate - Optional custom end date (YYYY-MM-DD)
 * @returns {Object} Metrics for the current period with percentage changes vs previous period
 */
export const calculateSalesMetrics = (
  salesData,
  period = 'all',
  customStartDate = null,
  customEndDate = null
) => {
  const today = dayjs();
  let startDate;
  let endDate;
  let prevStartDate;
  let prevEndDate;

  // Handle date range determination based on input parameters
  if (customStartDate && customEndDate) {
    // Custom date range calculation
    startDate = dayjs(customStartDate);
    endDate = dayjs(customEndDate);

    // Calculate previous period with equal duration
    const rangeDuration = endDate.diff(startDate, 'day') + 1;
    prevEndDate = startDate.subtract(1, 'day');
    prevStartDate = prevEndDate.subtract(rangeDuration - 1, 'day');
  } else if (period !== 'all') {
    // Standard time periods lookup
    const periodMap = {
      '7days': 7,
      '30days': 30,
      '90days': 90,
      '365days': 365,
    };

    const days = periodMap[period] || 0;

    endDate = today;
    startDate = today.subtract(days - 1, 'day');

    // Previous period with equal duration
    prevEndDate = startDate.subtract(1, 'day');
    prevStartDate = prevEndDate.subtract(days - 1, 'day');
  } else {
    // "All time" - use earliest transaction date through today
    const earliestTransaction = [...salesData].sort((a, b) =>
      dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1
    )[0];

    startDate = earliestTransaction
      ? dayjs(earliestTransaction.date)
      : today.subtract(60, 'day');
    endDate = today;

    // Create a previous period of equal length
    const rangeDuration = endDate.diff(startDate, 'day') + 1;
    if (rangeDuration > 0) {
      prevEndDate = startDate.subtract(1, 'day');
      prevStartDate = prevEndDate.subtract(rangeDuration - 1, 'day');
    } else {
      // No meaningful previous period for comparison
      prevStartDate = null;
      prevEndDate = null;
    }
  }

  // Filter current period data
  const currentPeriodData = salesData.filter((item) => {
    const itemDate = dayjs(item.date);
    return (
      itemDate.isSameOrAfter(startDate, 'day') &&
      itemDate.isSameOrBefore(endDate, 'day')
    );
  });

  // Calculate current period metrics
  const currentMetrics = calculatePeriodMetrics(currentPeriodData);

  // Initialize previous period metrics
  let previousMetrics = {
    totalSales: 0,
    totalRefunds: 0,
    totalCosts: 0,
    totalProfit: 0,
    totalItems: 0,
    transactionCount: 0,
    averageTicketSize: 0,
  };

  // Calculate previous period metrics if applicable
  if (prevStartDate && prevEndDate) {
    const previousPeriodData = salesData.filter((item) => {
      const itemDate = dayjs(item.date);
      return (
        itemDate.isSameOrAfter(prevStartDate, 'day') &&
        itemDate.isSameOrBefore(prevEndDate, 'day')
      );
    });

    previousMetrics = calculatePeriodMetrics(previousPeriodData);
  }

  // Calculate percentage changes
  const percentChanges = {
    sales: calculatePercentChange(
      currentMetrics.totalSales,
      previousMetrics.totalSales
    ),
    transactions: calculatePercentChange(
      currentMetrics.transactionCount,
      previousMetrics.transactionCount
    ),
    average: calculatePercentChange(
      currentMetrics.averageTicketSize,
      previousMetrics.averageTicketSize
    ),
    items: calculatePercentChange(
      currentMetrics.totalItems,
      previousMetrics.totalItems
    ),
  };

  // Format previous values for display
  const previousValues = {
    sales: `$${previousMetrics.totalSales.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
    transactions: previousMetrics.transactionCount.toLocaleString('en-US'),
    average: `$${previousMetrics.averageTicketSize.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
    items: previousMetrics.totalItems.toLocaleString('en-US'),
  };

  return {
    // Current period metrics
    totalSales: currentMetrics.totalSales,
    totalCosts: currentMetrics.totalCosts,
    totalRefunds: currentMetrics.totalRefunds,
    totalProfit: currentMetrics.totalProfit,
    totalItems: currentMetrics.totalItems,
    averageTicketSize: currentMetrics.averageTicketSize,
    transactionCount: currentMetrics.transactionCount,
    // Comparison data
    percentChanges,
    previousValues,
    // Date range information for UI display
    currentPeriod: {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    },
    previousPeriod:
      prevStartDate && prevEndDate
        ? {
            startDate: prevStartDate.format('YYYY-MM-DD'),
            endDate: prevEndDate.format('YYYY-MM-DD'),
          }
        : null,
  };
};

/**
 * Calculates metrics for a specific time period
 *
 * @param {Array} data - Filtered sales data for the period
 * @returns {Object} Calculated metrics for the period
 */
function calculatePeriodMetrics(data) {
  // Calculate sales total (positive amounts only)
  const totalSales =
    data.reduce((sum, item) => {
      return sum + (item.amount > 0 ? item.amount : 0);
    }, 0) / 100; // Convert cents to dollars

  // Calculate costs (absolute values)
  const totalCosts =
    data.reduce((sum, item) => {
      return sum + Math.abs(item.costOfGoods * 100);
    }, 0) / 100;

  // Calculate refunds (absolute values of negative amounts)
  const totalRefunds =
    data.reduce((sum, item) => {
      return sum + (item.amount < 0 ? Math.abs(item.amount) : 0);
    }, 0) / 100;

  const totalProfit = totalSales - totalCosts - totalRefunds;

  // Count positive transactions only for average calculation
  const positiveTransactions = data.filter((item) => item.amount > 0);

  // Sum items across all transactions
  const totalItems = data.reduce((sum, item) => sum + item.items, 0);

  return {
    totalSales,
    totalCosts,
    totalRefunds,
    totalProfit,
    totalItems,
    averageTicketSize:
      positiveTransactions.length > 0
        ? totalSales / positiveTransactions.length
        : 0,
    transactionCount: data.length,
  };
}

/**
 * Calculates and formats percentage change between two values
 *
 * @param {number} current - Current period value
 * @param {number} previous - Previous period value
 * @returns {string} Formatted percentage change with sign (e.g., "+10.5%")
 */
function calculatePercentChange(current, previous) {
  if (previous === 0) {
    return '0.0%';
  }

  const change = ((current - previous) / previous) * 100;
  return (change >= 0 ? '+' : '') + change.toFixed(1) + '%';
}

export default dummySalesData;
