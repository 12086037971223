import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import {
  Box,
  Button,
  Typography,
  Modal,
  SwipeableDrawer,
  IconButton,
  LinearProgress,
  TextField,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Tooltip,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import dayjs from 'dayjs';
import { colors } from '../../../themes/theme';

const ReportFilterColors = {
  gray: '#e0e0e0',
  primary: '#3f51b5',
};

const CalculationRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  fontSize: '1.2em',
  border: `1px solid ${ReportFilterColors.gray}`,
  padding: '16px 24px',
  borderRadius: '20px',
  width: 'fit-content',
  maxWidth: '95%',
  margin: '0 auto',
  overflowX: 'auto',
  '& > span.operator': {
    fontSize: '1.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '40px',
    margin: '0 4px',
    position: 'relative',
    top: '-8px',
    flexShrink: 0,
  },
  '@media (max-width: 1024px)': {
    padding: '12px 16px',
    gap: '4px',
  },
});

const CalculationItem = styled('span')(({ color }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: color || 'black',
  fontWeight: 'bold',
  minWidth: '120px',
  maxWidth: '180px',
  padding: '0 8px',
  textAlign: 'center',
  position: 'relative',
  height: '60px',
  '& span': {
    width: '100%',
    fontSize: '1.3em',
    lineHeight: 1.2,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    display: 'block',
  },
  '& small': {
    width: '100%',
    fontSize: '0.4em',
    color: ReportFilterColors.primary,
    textAlign: 'center',
    fontWeight: 'normal',
    position: 'relative',
    bottom: 0,
    left: 0,
    right: 0,
    whiteSpace: 'nowrap',
  },
  '@media (min-width: 768px)': {
    fontSize: '0.9em !important',
    minWidth: '130px',
    maxWidth: '180px',
    marginBottom: '-3px',
  },
  '@media (min-width: 1024px)': {
    fontSize: '1.2em !important',
    minWidth: '150px',
    maxWidth: '200px',
  },
  '@media (min-width: 1440px)': {
    fontSize: '1.4em !important',
    minWidth: '170px',
    maxWidth: '220px',
  },
}));

const ReportFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  income,
  costOfGoodsSold,
  operatingExpenses,
  netProfit,
  minDateRangeLocal,
  maxDateRangeLocal,
  handleReset,
  isResetDisabled,
  isInitialDateRange,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const isVerySmallScreen = useMediaQuery('(max-width:374px)');

  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [tempDate, setTempDate] = useState(null);
  const [isCustomMode, setIsCustomMode] = useState(false);

  const handleFieldClick = () => {
    setIsOpen(true);
    setStep(1);
    setTempDate(null);
    setIsCustomMode(false);
  };

  const handleDateSelect = (newDate) => {
    setTempDate(newDate);
  };

  const handleCustomDateOk = () => {
    if (tempDate) {
      if (step === 1) {
        setStep(2);
        setTempDate(null);
      } else {
        const newEndDate = tempDate.endOf('day').toDate();
        const newStartDate = dayjs(startDate).startOf('day').toDate();

        // Update context directly
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setIsOpen(false);
        setStep(1);
        setTempDate(null);
      }
    }
  };
  const [localStartDate] = useState(startDate);
  const [localEndDate] = useState(endDate);

  const handleClose = () => {
    if (step === 2) {
      setStartDate(localStartDate);
      setEndDate(localEndDate);
    }
    setIsOpen(false);
    setStep(1);
    setTempDate(null);
    setIsCustomMode(false);
  };

  const getShortcutGroups = (maxDate) => [
    [
      {
        label: 'This Year',
        getValue: () => {
          const range = [dayjs().startOf('year'), dayjs()];
          return range;
        },
      },
      {
        label: 'Last Year',
        getValue: () => {
          const lastYear = dayjs().subtract(1, 'year');
          return [lastYear.startOf('year'), lastYear.endOf('year')];
        },
      },
    ],
    [
      {
        label: 'Last 30 Days',
        getValue: () => [dayjs().subtract(29, 'day'), dayjs()],
      },
      {
        label: 'Last 90 Days',
        getValue: () => [dayjs().subtract(89, 'day'), dayjs()],
      },
    ],
    [
      {
        label: 'Last 6 Months',
        getValue: () => [dayjs().subtract(6, 'month'), dayjs()],
      },
      {
        label: 'Last 12 Months',
        getValue: () => [dayjs().subtract(12, 'month'), dayjs()],
      },
    ],
  ];

  const handleShortcutClick = (shortcut) => {
    const [start, end] = shortcut.getValue();
    setStartDate(start.startOf('day').toDate());
    setEndDate(end.endOf('day').toDate());
    setIsOpen(false);
  };

  const isShortcutSelected = (shortcut) => {
    const [shortcutStart, shortcutEnd] = shortcut.getValue();
    return (
      dayjs(startDate).isSame(shortcutStart, 'day') &&
      dayjs(endDate).isSame(shortcutEnd, 'day')
    );
  };

  const ShortcutsDisplay = () => (
    <Box sx={{ width: '100%', mb: 3 }}>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'relative',
          top: -15,
          left: 825,
          zIndex: 1,
          padding: '4px',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        <CloseIcon sx={{ fontSize: '1.25rem' }} />
      </IconButton>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ fontWeight: 600 }}
      >
        Quick Date Ranges
      </Typography>
      <Grid container spacing={1} sx={{ px: 1 }}>
        {getShortcutGroups(dayjs(maxDateRangeLocal)).map(
          (group, groupIndex) => (
            <Grid item xs={12} key={groupIndex}>
              <Grid container spacing={1}>
                {group.map((shortcut, index) => (
                  <Grid item xs={6} key={index}>
                    <Button
                      fullWidth
                      variant={
                        isShortcutSelected(shortcut) ? 'contained' : 'outlined'
                      }
                      size="medium"
                      onClick={() => handleShortcutClick(shortcut)}
                      startIcon={
                        <CalendarTodayIcon
                          sx={{
                            fontSize: 18,
                          }}
                        />
                      }
                      sx={{
                        height: 56,
                        borderRadius: '12px',
                        textTransform: 'none',
                        justifyContent: 'flex-start',
                        padding: '8px 16px',
                        backgroundColor: isShortcutSelected(shortcut)
                          ? 'primary.main'
                          : 'background.paper',
                        '&:hover': {
                          backgroundColor: isShortcutSelected(shortcut)
                            ? 'primary.dark'
                            : 'action.hover',
                        },
                      }}
                    >
                      <Box sx={{ textAlign: 'left' }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: isTablet ? '0.6rem' : '0.8rem',
                            fontWeight: 600,
                            color: isShortcutSelected(shortcut)
                              ? 'white'
                              : 'text.primary',
                          }}
                        >
                          {shortcut.label}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: isTablet ? '0.6rem' : '0.8rem',
                            display: 'block',
                            color: isShortcutSelected(shortcut)
                              ? 'rgba(255,255,255,0.8)'
                              : 'text.secondary',
                          }}
                        >
                          {shortcut
                            .getValue()
                            .map((d) => d.format('MMM D'))
                            .join(' - ')}
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )
        )}
      </Grid>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => setIsCustomMode(true)}
        sx={{
          mt: 2,
          height: 48,
          borderRadius: '12px',
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 600,
          color: colors.primary,
          borderColor: colors.primary,
          '&:hover': {
            borderColor: colors.secondary,
            color: colors.secondary,
          },
        }}
      >
        Set Custom Range
      </Button>
    </Box>
  );

  const CustomDateContent = () => (
    <>
      <LinearProgress
        variant="determinate"
        value={step === 1 ? 50 : 100}
        sx={{
          mb: 3,
          height: 8,
          borderRadius: 4,
          bgcolor: 'action.hover',
          '& .MuiLinearProgress-bar': {
            bgcolor: colors.secondary,
          },
        }}
      />

      <Typography
        variant={isMobile ? 'subtitle1' : 'h6'}
        align="center"
        gutterBottom
        sx={{ fontWeight: 600 }}
      >
        Select {step === 1 ? 'Start' : 'End'} Date
      </Typography>
      <Typography
        variant="body2"
        color={colors.darkGray3}
        align="center"
        sx={{ mb: 3 }}
      >
        Step {step} of 2:{' '}
        {step === 1 ? 'Choose when to begin' : 'Choose when to end'}
      </Typography>

      <StaticDatePicker
        value={tempDate || dayjs(step === 1 ? startDate : endDate)}
        onChange={handleDateSelect}
        minDate={step === 1 ? dayjs(minDateRangeLocal) : dayjs(startDate)}
        maxDate={dayjs(maxDateRangeLocal)}
        slots={{ actionBar: () => null }}
        sx={{
          '& .MuiPickersDay-root': {
            borderRadius: '8px',
            fontSize: isMobile ? '0.875rem' : '1rem',
            '&.Mui-selected': { bgcolor: colors.secondary },
          },
          '& .MuiDayCalendar-weekDayLabel': {
            fontSize: isMobile ? '0.75rem' : '0.875rem',
          },
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
          px: isMobile ? 1 : 2,
        }}
      >
        <Button onClick={handleClose} sx={{ color: colors.darkGray3 }}>
          Cancel
        </Button>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {step === 2 && (
            <Button
              onClick={() => {
                setStep(1);
                setTempDate(null);
              }}
              sx={{ color: colors.secondary }}
            >
              Back to Start Date
            </Button>
          )}
          <Button
            onClick={handleCustomDateOk}
            disabled={!tempDate}
            sx={{
              color: colors.secondary,
              '&.Mui-disabled': {
                color: colors.darkGray,
              },
            }}
          >
            {step === 1 ? 'Next' : 'Finish'}
          </Button>
        </Box>
      </Box>
    </>
  );

  const DateRangeDisplay = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <TextField
          fullWidth
          size="small"
          value={`${dayjs(startDate).format('MMM DD, YYYY')} – ${dayjs(endDate).format('MMM DD, YYYY')}`}
          onClick={handleFieldClick}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ marginLeft: { xs: '-4px', sm: '-8px' } }}
              >
                <CalendarTodayIcon
                  sx={{ fontSize: { xs: '1.1rem', sm: '1.5rem' } }}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '24px',
              paddingRight: { xs: '6px', sm: '10px' },
              borderColor: colors.secondary,
              '&:hover': { borderColor: colors.secondary },
            },
            '& input': {
              textAlign: 'center',
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              fontWeight: 500,
              color: colors.darkGray3,
              padding: { xs: '10px 16px', sm: '12px 24px' },
            },
          }}
        />
        {!isInitialDateRange && (
          <Typography
            variant="caption"
            sx={{
              display: 'block',
              textAlign: 'center',
              color: colors.secondary,
              mt: 0.5,
              fontSize: '0.7rem',
              transition: 'opacity 0.2s ease',
            }}
          >
            {isCustomMode ? 'Custom range selected' : 'Preset range applied'}
          </Typography>
        )}
      </Box>
      <Tooltip title="Reset Date Range">
        <IconButton
          onClick={handleReset}
          disabled={isResetDisabled}
          size="small"
          sx={{
            top: isInitialDateRange ? 0 : '-10px',
            backgroundColor: colors.secondary,
            color: colors.white,
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: colors.hoverBg,
              transform: 'scale(1.05)',
            },
            '&.Mui-disabled': {
              backgroundColor: theme.palette.action.disabledBackground,
              color: theme.palette.action.disabled,
            },
          }}
        >
          <RestartAltIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: '100%', p: 1 }}>
        {/* Date Range Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            gap: 1,
            pb: 2,
          }}
        >
          <Typography
            fontWeight="bold"
            fontSize={isMobile ? '0.85rem' : '1rem'}
          >
            Date Range
          </Typography>
          <DateRangeDisplay />
        </Box>

        {/* Conditional Rendering for Mobile/Desktop */}
        {isMobile ? (
          // Mobile Vertical Layout
          <Box mt={2}>
            {[
              { label: 'Income', value: income },
              {
                label: 'Cost of Goods Sold',
                value: costOfGoodsSold,
                isSubtraction: true,
              },
              {
                label: 'Operating Expenses',
                value: operatingExpenses,
                isSubtraction: true,
              },
              {
                label: netProfit < 0 ? 'Net Loss' : 'Net Profit',
                value: netProfit,
                isTotal: true,
              },
            ].map((item, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                my={1}
              >
                <Typography
                  variant="body1"
                  fontWeight={item.isTotal ? 'bold' : 'normal'}
                  sx={isVerySmallScreen ? { fontSize: '0.9rem' } : {}}
                >
                  {item.label}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={item.isTotal ? 'bold' : 'normal'}
                  color={
                    item.isTotal
                      ? netProfit < 0
                        ? 'error.main'
                        : 'success.main'
                      : 'text.primary'
                  }
                  sx={isVerySmallScreen ? { fontSize: '0.9rem' } : {}}
                >
                  {item.isSubtraction && '-'}$
                  {Math.abs(item.value || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <CalculationRow>
            <CalculationItem>
              <span>
                $
                {Math.abs(income || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
              <small>Income</small>
            </CalculationItem>
            <span className="operator">-</span>
            <CalculationItem>
              <span>
                $
                {Math.abs(costOfGoodsSold || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
              <small>Cost of Goods Sold</small>
            </CalculationItem>
            <span className="operator">-</span>
            <CalculationItem>
              <span>
                $
                {Math.abs(operatingExpenses || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
              <small>Operating Expenses</small>
            </CalculationItem>
            <span className="operator">=</span>
            <CalculationItem color={netProfit < 0 ? 'red' : 'green'}>
              <span>
                {netProfit < 0 ? '-$' : '$'}
                {Math.abs(netProfit || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
              <small>Net {netProfit < 0 ? 'Loss' : 'Profit'}</small>
            </CalculationItem>
          </CalculationRow>
        )}
        {isMobile ? (
          <SwipeableDrawer
            anchor="bottom"
            open={isOpen}
            onClose={handleClose}
            onOpen={() => setIsOpen(true)}
            disableSwipeToOpen
            PaperProps={{
              sx: {
                height: '95vh',
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                p: 2,
              },
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
            {isCustomMode ? <CustomDateContent /> : <ShortcutsDisplay />}
          </SwipeableDrawer>
        ) : (
          <Modal
            open={isOpen}
            onClose={handleClose}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                bgcolor: 'background.paper',
                borderRadius: 3,
                boxShadow: 24,
                p: 4,
                maxWidth: 'md',
                width: '100%',
                maxHeight: '90vh',
                overflow: 'auto',
              }}
            >
              {isCustomMode ? <CustomDateContent /> : <ShortcutsDisplay />}
            </Box>
          </Modal>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default ReportFilter;
