import React from 'react';
import { Box } from '@mui/material';
import { MetricCard } from './shared-components';

// Display financial metrics on the home screen with responsive layout
const HomeMetricsDisplay = React.memo(({ metricsData, isMobile, isTablet }) => {
  if (!metricsData) return null;

  // Extract financial data from props
  const { income, expenses, netProfit } = metricsData;

  const metricLabels = {
    income: 'INCOME',
    expenses: 'EXPENSES',
    netProfit: 'NET POSITION',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        // Responsive layout - column on mobile/tablet, row on desktop
        flexDirection: {
          xs: 'column',
          sm: isTablet ? 'column' : 'row',
        },
        justifyContent: 'flex-start',
        alignItems: isTablet ? 'center' : 'stretch',
      }}
    >
      <MetricCard
        label={metricLabels.income}
        // Strip currency symbols and formatting for numerical operations
        amount={parseFloat(income.replace(/[^0-9.-]+/g, ''))}
        type="positive"
        isMobile={isMobile}
        isTablet={isTablet}
      />
      <MetricCard
        label={metricLabels.expenses}
        amount={parseFloat(expenses.replace(/[^0-9.-]+/g, ''))}
        type="negative"
        isMobile={isMobile}
        isTablet={isTablet}
      />
      <MetricCard
        label={metricLabels.netProfit}
        amount={parseFloat(netProfit.replace(/[^0-9.-]+/g, ''))}
        // Dynamically set type based on whether net profit is positive or negative
        type={
          parseFloat(netProfit.replace(/[^0-9.-]+/g, '')) >= 0
            ? 'positive'
            : 'negative'
        }
        isMobile={isMobile}
        isTablet={isTablet}
      />
    </Box>
  );
});

export default HomeMetricsDisplay;
