import dayjs from 'dayjs';

/**
 * searchUtils.js
 * Provides a unified search implementation for sales transactions across desktop and mobile views
 * This ensures consistent search behavior across the application with enhanced search capabilities
 */

/**
 * Unified search function for sales transactions
 * Maps the data structure correctly for MUI DataGrid and mobile view
 *
 * @param {Array} rows - Array of transaction rows to search through
 * @param {String} searchTerm - The search term entered by the user
 * @returns {Array} - Filtered array of rows that match the search criteria
 */
const searchSalesTransactions = (rows, searchTerm) => {
  if (!searchTerm || searchTerm.trim() === '') {
    return rows;
  }

  const term = searchTerm.toLowerCase().trim();

  return rows.filter((row) => {
    // Format fields for searching, mapping column names to data fields
    const searchableFields = [
      // Text fields - handle both naming conventions across different views
      row.description || row.details,
      row.category,
      row.account || row.source,
      row.paymentMethod,

      // Status - support both capitalization formats for consistent search
      row.status,
      row.status
        ? row.status.charAt(0).toUpperCase() + row.status.slice(1).toLowerCase()
        : null,

      // Date with multiple formats for flexible search
      // This allows users to search by various date formats they might be familiar with
      formatDates(row.date),

      // Amount - multiple formats for easier searching
      // Handles positive/negative values and different currency format preferences
      ...formatAmounts(row.amount),

      // Items count
      row.items?.toString(),
    ].filter(Boolean); // Remove null or undefined values

    // Join all fields and check if the search term is included
    const searchableString = searchableFields.join(' ').toLowerCase();
    return searchableString.includes(term);
  });
};

/**
 * Formats a date into multiple searchable strings
 * @param {String|Date} date - The date to format
 * @returns {Array} Array of formatted date strings
 */
const formatDates = (date) => {
  if (!date) return [];

  return [
    dayjs(date).format('MMM DD, YYYY'), // MMM DD, YYYY
    dayjs(date).format('MM/DD/YYYY'), // MM/DD/YYYY
    dayjs(date).format('MM DD YYYY'), // MM DD YYYY without comma
    dayjs(date).format('MMMM D'), // Month Day (for mobile display format)
    dayjs(date).format('MMM D'), // Short Month Day
  ];
};

/**
 * Formats an amount into multiple searchable strings
 * @param {Number} amount - The amount in cents
 * @returns {Array} Array of formatted amount strings
 */
const formatAmounts = (amount) => {
  if (amount == null) return [];

  const formattedAmounts = [
    `$${Math.abs(amount / 100).toFixed(2)}`, // $XX.XX
    Math.abs(amount / 100)
      .toFixed(2)
      .toString(), // XX.XX
    Math.abs(amount / 100).toString(), // XX
  ];

  if (amount < 0) {
    formattedAmounts.push(
      `-$${Math.abs(amount / 100).toFixed(2)}`, // -$XX.XX for expenses
      `-${Math.abs(amount / 100).toFixed(2)}` // -XX.XX for expenses
    );
  } else {
    formattedAmounts.push(
      `+$${(amount / 100).toFixed(2)}`, // +$XX.XX for income
      `+${(amount / 100).toFixed(2)}` // +XX.XX for income
    );
  }

  return formattedAmounts;
};

export default searchSalesTransactions;
