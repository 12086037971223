import { useState, useEffect } from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HomeIcon from '@mui/icons-material/Home';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import NavigationWarning from '../Data Grid/NavigationWarning';
import { DASHBOARD_ROUTES } from '../../../data/constants';
import { store } from '../../../data/store';
import { useStore } from '@tanstack/react-store';
import { colors } from '../../../themes/theme';
import useMediaQuery from '@mui/material/useMediaQuery';

export function NavigationTabs() {
  const location = useLocation();
  const isDatagridPath = location.pathname === '/dashboard/datagrid';
  const [showWarning, setShowWarning] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  // Extract rowEditCount from the store to track unsaved changes
  const { rowEditCount } = useStore(store, (state) => ({
    rowEditCount: state.rowEditCount,
  }));

  const isEditing = rowEditCount > 0;

  // Determine initial tab value based on current route or default to first route
  const initialTabValue = DASHBOARD_ROUTES.includes(location.pathname)
    ? location.pathname
    : DASHBOARD_ROUTES[0];
  const [value, setValue] = useState(initialTabValue);

  // Sync tab selection with current route
  useEffect(() => {
    setValue(initialTabValue);
  }, [initialTabValue]);

  // Handle tab change when not editing
  const handleChange = (event, newValue) => {
    if (!isEditing) {
      setValue(newValue);
    }
  };

  // Intercept tab clicks when there are unsaved changes
  const handleTabClick = (event, route) => {
    if (isDatagridPath && isEditing && route !== value) {
      event.preventDefault();
      event.stopPropagation();
      setShowWarning(true);
    }
  };

  // Create common props for tab components with navigation handling
  const getTabProps = (route) => ({
    component: Link,
    to: route,
    value: route,
    disabled: isDatagridPath && isEditing && route !== value,
    sx: {
      '&.Mui-disabled': {
        pointerEvents: 'auto',
        color: colors.textSecondary,
        opacity: 0.7,
      },
    },
    onClickCapture: (e) => handleTabClick(e, route),
  });

  return (
    <>
      <HTRTabs
        value={value}
        onChange={handleChange}
        aria-label="navigation tabs"
        scrollButtons={false}
        variant="fullWidth"
      >
        <HTRTab
          icon={isMobile ? <HomeIcon fontSize="small" /> : null}
          label={
            <TabLabel
              icon={!isMobile ? <HomeIcon fontSize="small" /> : null}
              text="Home"
            />
          }
          {...getTabProps(DASHBOARD_ROUTES[0])}
        />
        <HTRTab
          icon={isMobile ? <AttachMoneyIcon fontSize="small" /> : null}
          label={
            <TabLabel
              icon={!isMobile ? <AttachMoneyIcon fontSize="small" /> : null}
              text="Sales"
            />
          }
          {...getTabProps(DASHBOARD_ROUTES[3])}
        />
        <HTRTab
          icon={isMobile ? <ImportExportOutlinedIcon fontSize="small" /> : null}
          label={
            <TabLabel
              icon={
                !isMobile ? <ImportExportOutlinedIcon fontSize="small" /> : null
              }
              text="Transactions"
            />
          }
          {...getTabProps(DASHBOARD_ROUTES[1])}
        />
        <HTRTab
          icon={isMobile ? <AssignmentOutlinedIcon fontSize="small" /> : null}
          label={
            <TabLabel
              icon={
                !isMobile ? <AssignmentOutlinedIcon fontSize="small" /> : null
              }
              text="Reports"
            />
          }
          {...getTabProps(DASHBOARD_ROUTES[2])}
        />
      </HTRTabs>
      {/* Show warning dialog when trying to navigate away with unsaved changes */}
      <NavigationWarning
        isEditing={showWarning}
        onClose={() => setShowWarning(false)}
      />
    </>
  );
}

// Simple component to render tab label with consistent layout
const TabLabel = ({ icon, text }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
      {icon}
      <span>{text}</span>
    </div>
  );
};

// Styled Tabs component with responsive adjustments
export const HTRTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: colors.white,
  borderBottom: `1px solid ${colors.borderColor}`,
  minHeight: '48px',
  '& .MuiTabs-indicator': {
    backgroundColor: colors.primary,
    height: '2px',
  },
  marginTop: -10,
  marginBottom: 10,
  width: '100%',
  '& .MuiTabs-flexContainer': {
    justifyContent: 'flex-start',
  },

  [theme.breakpoints.down('sm')]: {
    minHeight: '55px',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-around',
    },
    '& .MuiTabs-indicator': {
      bottom: '4px',
    },
  },
}));

// Styled Tab component with responsive design for desktop and mobile
export const HTRTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  minHeight: '48px',
  padding: '6px 16px',
  fontSize: '14px',
  fontWeight: 'normal',
  color: colors.darkGray3,
  minWidth: 'auto',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  '& .MuiSvgIcon-root': {
    fontSize: '18px',
    margin: 0,
  },
  '&.Mui-selected': {
    color: colors.primary,
    fontWeight: '500',
  },
  '&:hover': {
    backgroundColor: colors.lightGray,
    transition: 'all 0.2s ease',
  },

  // Desktop styles
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    padding: '6px 20px',
  },

  // Mobile-specific styles - vertical layout with icon on top
  [theme.breakpoints.down('sm')]: {
    padding: '8px 0',
    fontSize: '11px',
    fontWeight: 'medium',
    flex: 1,
    minWidth: '25%',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
      marginBottom: '2px',
    },
    '&.Mui-selected': {
      backgroundColor: colors.primaryLight,
    },
  },
}));
