import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Link,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import PlaidLink from '../features/PlaidLink/PlaidLink';
import CloseIcon from "@mui/icons-material/Close";
import { colors } from '../../themes/theme';
import LinearProgress from '@mui/material/LinearProgress';
import { WebSocketContext } from './contexts/WebSocketContext.js';
import { motion } from 'framer-motion';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { GradientProgress} from '../styles/style.js';
import Lottie from 'react-lottie-player';
import { useQueryClient } from '@tanstack/react-query';
import { TRANSACTIONS_QUERY_KEY } from '../../data/constants';
import connectingAnimation from "../../assets/animations/connecting.json";


const LinkBankAccountModal = ({ open, handleClose }) => {
  const { wsRef, isConnected } = useContext(WebSocketContext);
  const [syncing, setSyncing] = useState(false);
  const [syncCompleted, setSyncCompleted] = useState(false);
  const [isWaitingForSync, setIsWaitingForSync] = useState(false);
  const [linkProcessStarted, setLinkProcessStarted] = useState(false);
  const [syncTimeout, setSyncTimeout] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [progressCapped, setProgressCapped] = useState(false);
  const theme = useTheme();
  const queryClient = useQueryClient();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isConnected && wsRef.current) {
      const ws = wsRef.current;

      const handleMessage = (event) => {
        const message = JSON.parse(event.data);

        // Filter messages by itemId
        if (message.itemId !== currentItemId) {
          return;
        }

        if (message.status === 'syncing') {
          setSyncing(true);
          setIsWaitingForSync(false);
        } else if (message.status === 'complete') {
          setSyncing(false);
          setSyncCompleted(true);
          setProgress(100);
        }
      };

      ws.addEventListener('message', handleMessage);

      return () => {
        ws.removeEventListener('message', handleMessage);
      };
    }
  }, [isConnected, wsRef, currentItemId]);

  useEffect(() => {
    if (syncCompleted) {
      // Invalidate and refetch the transactions cache
      queryClient.invalidateQueries({ queryKey: TRANSACTIONS_QUERY_KEY });

      setTimeout(() => {
        handleClose();
        window.location.href = '/dashboard';
      }, 3000);
    }
  }, [syncCompleted, handleClose, queryClient]);

  // Simulated sync progress
  useEffect(() => {
    let interval;
    if (syncing && !syncCompleted) {
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 95) {
            setProgressCapped(true);
            clearInterval(interval);
            return 95;
          }
          let increment;
          if (oldProgress < 30) {
            increment = 4;
          } else if (oldProgress >= 30 && oldProgress < 90) {
            increment = 3;
          } else {
            increment = 1;
          }
          return Math.min(oldProgress + increment, 95);
        });
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [syncing, syncCompleted]);


  const onSyncStart = (itemId) => {
    setCurrentItemId(itemId);
    setLinkProcessStarted(true);
    setIsWaitingForSync(true);

    // Set timeout to check for sync completion, scoped to current itemId
    const timeoutId = setTimeout(() => {
      if (!syncing) {
        setSyncTimeout(true);
        setIsWaitingForSync(false);
      }
    }, 180000);

    return () => clearTimeout(timeoutId);
  };

  // Cleanup on modal close
  useEffect(() => {
    if (!open) {
      setIsWaitingForSync(false);
      setLinkProcessStarted(false);
      setSyncTimeout(false);
      setProgress(0);
      setProgressCapped(false);
      setSyncCompleted(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={null}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: '20px',
          overflow: 'hidden',
          width: isSmallScreen ? '90%' : '600px',
          background: colors.PaperBackgroundGradient,
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <DialogTitle
        sx={{
          background: colors.DarkHeaderGradient,
          color: colors.white,
          fontSize: isSmallScreen ? '1.2rem' : '1.5rem',
          fontWeight: 'bold',
          textAlign: 'center',
          position: 'relative',
          padding: isSmallScreen ? '6px' : '20px',
        }}
      >
        Connect Your Bank Account
        <IconButton
          onClick={!syncing ? handleClose : null}
          disabled={syncing}
          sx={{
            position: 'absolute',
            top: isSmallScreen ? -3 : 10,
            right: isSmallScreen ? -3 : 10,
            color: syncing ? colors.gray : colors.black2,
            '&:hover': {
              color: colors.darkGray2,
              bgcolor: colors.lightGray2,
            },
            '&:disabled': {
              opacity: 0.5,
            },
          }}
        >
          <CloseIcon sx={{ fontSize: isSmallScreen ? 20 : 32 }} />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: isSmallScreen ? '16px' : '32px',
          gap: isSmallScreen ? '12px' : '20px',
          marginTop: isSmallScreen ? '8px' : '16px',
        }}
      >
        {syncTimeout ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                color: 'red',
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: isSmallScreen ? '1rem' : '1.2rem',
              }}
            >
              Syncing is taking too long. Please try again.
            </Typography>
          </Box>
        ) : !linkProcessStarted &&
          !syncing &&
          !syncCompleted &&
          !isWaitingForSync ? (
          <Box textAlign="center">
            <Typography
              variant="body1"
              sx={{
                marginBottom: '16px',
                fontSize: isSmallScreen ? '0.8rem' : '1.2rem',
                fontWeight: 'medium',
              }}
            >
              Securely connect with your bank accounts using{' '}
              <Link
                href="https://plaid.com"
                target="_blank"
                rel="noopener"
                sx={{
                  color: colors.primary,
                  textDecoration: 'underline',
                }}
              >
                Plaid
              </Link>
              . <br />
              <Link
                href="https://onebitapp.com"
                target="_blank"
                rel="noopener"
                sx={{
                  color: colors.primary,
                  textDecoration: 'underline',
                }}
              >
                ONEBIT
              </Link>{' '}
              leverages Plaid to provide insights into your business's expenses
              and transactions.
            </Typography>
            <Box sx={{ marginTop: '16px' }}>
              <PlaidLink onSyncStart={onSyncStart} />
            </Box>
          </Box>
        ) : isWaitingForSync ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Lottie
              loop
              animationData={connectingAnimation}
              play
              style={{ width: '300px', height: '200px' }}
            />
            <Typography
              variant="h6"
              sx={{
                marginTop: '16px',
                fontSize: isSmallScreen ? '1rem' : '1.2rem',
              }}
            >
              Establishing connection...
            </Typography>
            <LinearProgress
              sx={{
                width: '100%',
                marginTop: '8px',
                height: '8px',
                borderRadius: '4px',
                backgroundColor: colors.lightGray2,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#6c63ff',
                },
              }}
            />
          </Box>
        ) : syncing && !syncCompleted ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              width: '100%',
              textAlign: 'center',
              padding: isSmallScreen ? '0 8px' : '0 16px',
            }}
          >
            <Lottie
              loop
              animationData={connectingAnimation}
              play
              style={{
                width: isSmallScreen ? '200px' : '300px',
                height: isSmallScreen ? '150px' : '200px',
              }}
            />
            <Typography
              variant="h6"
              sx={{ mt: 1, fontSize: isSmallScreen ? '0.9rem' : '1.2rem' }}
            >
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 2 }}
                style={{ display: 'inline-block' }}
              >
                {progress < 20 && '🚀 Starting sync! Organizing your data'}
                {progress >= 20 &&
                  progress < 50 &&
                  '📊 Sorting your transactions'}
                {progress >= 50 && progress < 80 && '📈 Calculating insights'}
                {progress >= 80 && progress < 95 && '🔍 Verifying details'}
                {progress === 95 &&
                  !progressCapped &&
                  '📥 Loading insights for your dashboard'}
                {progressCapped && '🎉 Almost done! Finalizing the sync'}
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: [0, 1, 0] }}
                transition={{ repeat: Infinity, duration: 1.5 }}
                style={{ display: 'inline-block', marginLeft: '5px' }}
              >
                ...
              </motion.div>
            </Typography>

            <GradientProgress
              variant="determinate"
              value={progress}
              sx={{ width: isSmallScreen ? '100%' : '90%', mt: 2 }}
            />
          </Box>
        ) : syncCompleted ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              minHeight: '300px',
            }}
          >
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: 'spring', stiffness: 120, duration: 0.8 }}
              style={{
                textAlign: 'center',
              }}
            >
              <AiOutlineCheckCircle
                size={isSmallScreen ? 60 : 80}
                color={colors.primary}
              />
              <Typography
                variant="h6"
                sx={{
                  color: colors.black,
                  mt: 2,
                  fontWeight: 'bold',
                }}
              >
                Transactions Synced!
              </Typography>
            </motion.div>
          </Box>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default LinkBankAccountModal;
