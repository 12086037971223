import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  Menu,
  Button,
  Box,
  Switch,
  Tooltip,
} from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SavingsIcon from '@mui/icons-material/Savings';
import InlineEditableField from './InlineEditableField';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { colors } from '../../../../../themes/theme';

const BankAccountCard = ({
  bank,
  setSnackbarMessage,
  setSnackbarSeverity,
  setOpenSnackbar,
  handleOpenDialog,
  handleToggleAccount,
  onSaveCustomName,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (accountId, itemId, accountName, isActive) => {
    const action = isActive ? 'deactivate' : 'activate';
    handleOpenDialog('account', accountId, itemId, accountName, action);
  };

  const calculateTimeDifference = (lastSynced) => {
    const now = new Date();
    const lastSyncedDate = new Date(lastSynced);
    const diffInSeconds = Math.floor((now - lastSyncedDate) / 1000);
    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };

  const getAccountIcon = (subtype) => {
    switch (subtype) {
      case 'checking':
        return <HomeIcon sx={{ color: colors.white }} />;
      case 'savings':
        return <SavingsIcon sx={{ color: colors.white }} />;
      case 'credit card':
        return <CreditCardIcon sx={{ color: colors.white }} />;
      default:
        return <AccountBalanceIcon sx={{ color: colors.white }} />;
    }
  };
  return (
    <Card
      sx={{
        mb: { xs: 2, sm: 4 },
        maxWidth: { xs: '100%', sm: '700px' },
        mx: 'auto',
        borderRadius: { xs: '10px', sm: '15px' },
        boxShadow: {
          xs: '0 2px 10px rgba(0, 0, 0, 0.05)',
          sm: '0 4px 20px rgba(0, 0, 0, 0.1)',
        },
        background: `linear-gradient(185deg, ${colors.white}, ${colors.receiptButtonGrey}, ${colors.white})`,
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: { xs: 'scale(1)', sm: 'scale(1.02)' },
          boxShadow: {
            xs: '0 4px 12px rgba(0, 0, 0, 0.1)',
            sm: '0 6px 24px rgba(0, 0, 0, 0.15)',
          },
        },
      }}
    >
      <CardContent sx={{ padding: { xs: '0px', md: '24px 32px' } }}>
        {/* Bank Header with Menu Icon */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
            position: 'relative',
            background: `linear-gradient(120deg, ${colors.primary}, ${colors.primaryHover})`,
            padding: { xs: '12px', sm: '10px' },
            borderRadius: { xs: '8px', sm: '12px' },
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {bank.logo_url ? (
              <Box
                component="img"
                src={`data:image/png;base64,${bank.logo_url}`}
                alt={`${bank.institutionName} logo`}
                sx={{
                  height: { xs: 40, md: 45 },
                  width: { xs: 40, md: 45 },
                  borderRadius: '50%',
                  backgroundColor: colors.lightGray2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 1,
                  border: `2px solid ${colors.primaryDark}`,
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                  },
                }}
              />
            ) : (
              <AccountBalanceIcon
                sx={{
                  fontSize: { xs: 24, md: 30 },
                  color: colors.white,
                  mr: 1,
                }}
              />
            )}
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                color: colors.white,
                fontSize: { xs: '1.2rem', sm: '1.6rem' },
              }}
            >
              {bank.institutionName && bank.institutionName !== 'Unknown'
                ? bank.institutionName
                : bank.bank_name || 'Unnamed Bank'}
            </Typography>
          </Box>

          {/*Last Synced and Menu Icon */}
          {bank.last_synced && (
            <Typography
              sx={{
                position: 'absolute',
                bottom: '2px',
                right: { xs: '30px', md: '60px' },
                fontFamily: 'Lora, serif',
                fontStyle: 'italic',
                fontSize: { xs: '0.7rem', md: '0.85rem' },
                color: colors.light,
                textShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
              }}
            >
              Last Synced: {calculateTimeDifference(bank.last_synced)}
            </Typography>
          )}
          <IconButton
            onClick={handleMenuOpen}
            aria-label="more options"
            sx={{
              color: colors.white,
              position: 'absolute',
              top: '12px',
              right: '12px',
            }}
          >
            <MoreVertIcon sx={{ color: colors.white }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            disablePadding
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            slotProps={{
              paper: {
                sx: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  padding: 0,
                  minWidth: 'auto',
                  overflow: 'visible',
                  mt: '-8px',
                },
              },
            }}
          >
            {/* Unlink whole Bank Item */}{' '}
            <Button
              onClick={() => {
                handleMenuClose();
                handleOpenDialog(
                  'item',
                  null,
                  bank.id,
                  bank.institutionName,
                  'unlink'
                );
              }}
              startIcon={<LinkOffIcon />}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: colors.error,
                fontWeight: 'bold',
                borderRadius: '10px',
                padding: '6px 12px',
                gap: 0.5,
                minWidth: 'auto',
                backgroundColor: colors.lightGray2,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 1.2)',
                transition: 'all 0.3s',
                '&:hover': {
                  backgroundColor: colors.errorBg,
                  color: colors.error,
                  boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
                },
                '&:active': {
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  transform: 'scale(0.98)',
                },
                fontSize: { xs: '0.85rem', md: '0.9rem' },
              }}
              aria-label="Unlink Bank Item"
            >
              Unlink
            </Button>
          </Menu>
        </Box>
        <Divider
          sx={{
            my: 2,
            background: `linear-gradient(90deg, ${colors.primaryHover}, ${colors.primaryDark})`,
            height: { xs: '1px', sm: '2px' },
            borderRadius: '1px',
          }}
        />

        <List disablePadding>
          {bank.accounts.map((account) => (
            <ListItem
              key={account.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 1,
                opacity: account.is_active ? 1 : 0.4,
                backgroundColor: account.is_active ? 'none' : colors.lightGray2,

                borderRadius: 2,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                p: { xs: 1, sm: 2 },
              }}
            >
              {/* Account Details */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    backgroundColor: colors.primary,
                    borderRadius: '50%',
                    p: 1,
                  }}
                >
                  {getAccountIcon(account.subtype)}
                </ListItemIcon>
                <Box>
                  <InlineEditableField
                    value={account.custom_name || account.name}
                    onSave={(newCustomName) =>
                      onSaveCustomName(bank.id, account.id, newCustomName)
                    }
                    disabled={!account.is_active}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: colors.textSecondary,
                      fontSize: { xs: '0.8rem', sm: '0.9em' },
                    }}
                  >
                    {account.number
                      ? `Account: ${account.number}`
                      : 'Account: Not Available'}
                    {' | '}
                    {account.subtype.charAt(0).toUpperCase() +
                      account.subtype.slice(1)}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    color: account.is_active
                      ? colors.black
                      : colors.textSecondary,
                  }}
                >
                  $
                  {account.balances?.available !== null
                    ? account.balances.available.toFixed(2)
                    : account.balances.current.toFixed(2)}
                </Typography>
                <Tooltip
                  title={
                    account.is_active
                      ? 'Deactivate this account'
                      : 'Activate this account'
                  }
                  placement="top"
                  arrow
                  enterDelay={200}
                  leaveDelay={200}
                  sx={{
                    '& .MuiTooltip-arrow': {
                      color: colors.primaryDark,
                    },
                  }}
                >
                  <Switch
                    checked={account.is_active}
                    onChange={() =>
                      handleToggle(
                        account.id,
                        bank.id,
                        account.name,
                        account.is_active
                      )
                    }
                    color="primary"
                  />
                </Tooltip>
              </Box>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default BankAccountCard;
