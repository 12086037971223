import ReactGA from 'react-ga4';
import './App.css';
import React, { useContext, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './components/features/Navbar/Navbar';
import Home from './pages/Dashboard/Home';
import PosSelectionPage from './components/features/PosSelectionPage/PosSelectionPage';
import LandingPage from './components/features/LandingPage/LandingPage';
import Footer from './components/features/Footer/Footer';
import DataGridPage from './pages/Dashboard/Home/DataGridPage';
import DataVisualizationsPage from './pages/Dashboard/Home/DataVisualizationsPage';
import ResetPassword from './components/features/Login/ResetPassword';
import ReportsPage from './components/features/Reports';
import LinkBankAccountPage from './components/features/PlaidLink/LinkAccount';
import SettingsPage from './pages/Dashboard/Home/settings/settingsPage';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from './components/common/contexts/authContext';

import SalesPage from './pages/Dashboard/Home/SalesPage';

import logToSentry from './lib/sentry';

function App() {
  const { isLoggedIn, currentUser, handleLogout, loading } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-K390GN602X');
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  useEffect(() => {
    if (isLoggedIn) {
      ReactGA.set({
        userId: currentUser?.id,
        userRole: currentUser?.role,
      });
    }
  }, [isLoggedIn, currentUser]);
  useEffect(() => {
    if (!loading && isLoggedIn) {
      const publicPages = [
        '/',
        '/signup',
        '/signin',
        '/login/reset',
        '/link-bank-account',
      ];
      if (!publicPages.includes(location.pathname)) {
        sessionStorage.setItem('lastVisitedPage', location.pathname);
      }
    }
  }, [location.pathname, loading, isLoggedIn]);

 useEffect(() => {
   if (!loading && isLoggedIn) {
     const authPages = [
       '/signup',
       '/signin',
       '/login/reset',
     ];
     const initialRedirect =
       sessionStorage.getItem('initialLinkRedirect') === 'true';
     if (initialRedirect) return;

     if (authPages.includes(location.pathname)) {
       logToSentry('App', {
         step: 'navigate to dashboard',
         status: 'success',
         tags: { category: 'user-flow' },
       });

       const lastVisitedPage = sessionStorage.getItem('lastVisitedPage');
       if (lastVisitedPage && !authPages.includes(lastVisitedPage)) {
         navigate(lastVisitedPage);
       } else {
         navigate('/dashboard/chart');
       }
     }
   }
 }, [isLoggedIn, loading, location.pathname, navigate]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="App">
        <Helmet>
          <title>ONEBIT</title>
        </Helmet>
        <Navbar
          currentUser={currentUser}
          isLoggedIn={isLoggedIn}
          handleLogout={handleLogout}
          isEditing={isEditing}
        />
        <Routes>
          <Route path="/*" element={<LandingPage />} />
          <Route path="/login/reset" element={<ResetPassword />} />
          <Route path="/setpos" element={<PosSelectionPage />} />
          <Route
            path="/link-bank-account"
            element={
              isLoggedIn ? <LinkBankAccountPage /> : <Navigate to="/" replace />
            }
          />
          <Route
            path="/dashboard"
            element={isLoggedIn ? <Home /> : <Navigate to="/" replace />}
          >
            <Route index element={<Navigate to="chart" replace />} />
            <Route path="chart" element={<DataVisualizationsPage />} />
            <Route
              path="datagrid"
              element={
                <DataGridPage
                  isEditing={isEditing}
                  onEditingChange={(editing) => setIsEditing(editing)}
                />
              }
            />
            <Route path="reports" element={<ReportsPage />} />
            <Route path="sales" element={<SalesPage />} />
          </Route>
          <Route
            path="/settings/*"
            element={
              isLoggedIn ? <SettingsPage /> : <Navigate to="/" replace />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
