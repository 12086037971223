import React, { useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery, IconButton } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getPlaidItemsForUser } from '../../services/api/plaid-service.js';
import { colors } from '../../../themes/theme.js';
import { useStore } from '@tanstack/react-store';
import { store } from '../../../data/store.js';
import { addCommasToNumber } from '../Reports/addCommasToNumber.js';
import { useNavigate } from 'react-router-dom';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { memoize } from 'lodash';

const formatNumber = memoize((num) => {
  if (num === 0) return '$0';
  if (num >= 1000000000) {
    return `$${(Math.floor((num / 1000000000) * 10) / 10).toFixed(1)}B`;
  }
  if (num >= 1000000) {
    return `$${(Math.floor((num / 1000000) * 10) / 10).toFixed(1)}M`;
  }
  if (num >= 1000) {
    return `$${(Math.floor((num / 1000) * 10) / 10).toFixed(1)}K`;
  }
  return `$${Math.floor(num)}`;
});

// Function to format currency with correct negative sign placement
const formatCurrency = (amount) => {
  const isNegative = amount < 0;
  const absoluteAmount = Math.abs(amount);
  const formattedAmount = addCommasToNumber(absoluteAmount);
  return isNegative ? `-$${formattedAmount}` : `$${formattedAmount}`;
};

const TransactionsMetricsDisplay = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    assets: {
      total: 0,
      linkedBankCash: 0,
      manualCash: 0,
      linkedBankPercentage: '0.00',
      manualCashPercentage: '0.00',
    },
    debts: {
      totalUsed: 0,
      totalLimit: 0,
      totalLeft: 0,
      utilizationPercentage: '0.00',
    },
  });
  const [error, setError] = useState(null);

  // Accordion state for phone layout
  const [assetsExpanded, setAssetsExpanded] = useState(false);
  const [debtsExpanded, setDebtsExpanded] = useState(false);

  // Use exact breakpoints as specified
  const isPhone = useMediaQuery('(max-width:600px)');
  const isMobile = useMediaQuery('(max-width:807px)');
  const isTablet = useMediaQuery('(max-width:1090px)');
  const shouldReorganizeLayout = useMediaQuery('(max-width:1650px)');

  // Get rows directly from the store
  const rows = useStore(store, (state) => state.rows);

  useEffect(() => {
    const fetchMetricsData = async () => {
      try {
        const { items } = await getPlaidItemsForUser();

        // Calculate assets (depository accounts)
        let linkedBankCash = 0;

        // Calculate debts (credit accounts)
        let totalCreditUsed = 0;
        let totalCreditLimit = 0;

        items.forEach((item) => {
          item.accounts.forEach((account) => {
            if (account.is_active) {
              if (account.type === 'depository') {
                linkedBankCash += account.balances.available || 0;
              } else if (account.type === 'credit') {
                const used = account.balances.current || 0;
                const limit = account.balances.limit || 0;
                totalCreditUsed += used;
                totalCreditLimit += limit;
              }
            }
          });
        });

        // Calculate manually added cash from transactions with account "Other"
        let manualCash = 0;
        if (rows && rows.length > 0) {
          manualCash = rows
            .filter((row) => row.account === 'Other')
            .reduce((sum, transaction) => sum + (transaction.amount || 0), 0);
        }

        const totalAvailableCash = linkedBankCash + manualCash;

        // Calculate percentages
        const linkedBankPercentage =
          totalAvailableCash > 0
            ? ((linkedBankCash / totalAvailableCash) * 100).toFixed(2)
            : '100.00';

        const manualCashPercentage =
          totalAvailableCash > 0
            ? ((manualCash / totalAvailableCash) * 100).toFixed(2)
            : '0.00';

        const creditUtilizationPercentage =
          totalCreditLimit > 0
            ? ((totalCreditUsed / totalCreditLimit) * 100).toFixed(2)
            : '0.00';

        const totalCreditLeft = totalCreditLimit - totalCreditUsed;

        setData({
          assets: {
            total: totalAvailableCash,
            linkedBankCash,
            manualCash,
            linkedBankPercentage,
            manualCashPercentage,
          },
          debts: {
            totalUsed: totalCreditUsed,
            totalLimit: totalCreditLimit,
            totalLeft: totalCreditLeft,
            utilizationPercentage: creditUtilizationPercentage,
          },
        });
      } catch (err) {
        setError('Failed to load metrics data');
        console.error('Error fetching metrics data:', err);
      }
    };

    fetchMetricsData();
  }, [rows]);

  if (error) {
    return (
      <Box
        sx={{
          p: 3,
          color: colors.error,
          bgcolor: colors.white,
          borderRadius: isPhone ? '10px' : '25px',
          width: isPhone
            ? '100vw'
            : { xs: '95vw', sm: '95vw', md: '95vw', lg: '95vw' },
          mx: isPhone ? 0 : 'auto',
          my: 3,
        }}
      >
        <Typography>{error}</Typography>
      </Box>
    );
  }

  // Calculate the percentage for progress bar
  const percentageUsed = (data.debts.totalUsed / data.debts.totalLimit) * 100;

  // Left side metrics component (asset percentages)
  const AssetPercentagesCards = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: shouldReorganizeLayout ? 'row' : 'column',
        justifyContent: 'center',
        gap: 2,
        width: shouldReorganizeLayout ? '100%' : '200px',
        flex: shouldReorganizeLayout ? 1 : 'none',
      }}
    >
      {/* Linked Bank % */}
      <Box
        sx={{
          p: 2,
          border: colors.TransactionsMetricsBorder,
          borderRadius: isPhone ? '10px' : '15px',
          bgcolor: colors.TransactionsMetricsBackground,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flex: shouldReorganizeLayout ? 1 : 'auto',
          height: shouldReorganizeLayout ? 'auto' : '50%',
        }}
      >
        <Typography
          variant="body2"
          sx={{ mb: 0.4, color: colors.textSecondary }}
        >
          Linked Bank (%)
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {data.assets.linkedBankPercentage}%
        </Typography>
      </Box>

      {/* Manual Cash % */}
      <Box
        sx={{
          p: 2,
          border: colors.TransactionsMetricsBorder,
          borderRadius: isPhone ? '10px' : '15px',
          bgcolor: colors.TransactionsMetricsBackground,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flex: shouldReorganizeLayout ? 1 : 'auto',
          height: shouldReorganizeLayout ? 'auto' : '50%',
        }}
      >
        <Typography
          variant="body2"
          sx={{ mb: 0.5, color: colors.textSecondary }}
        >
          Manual Cash (%)
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {data.assets.manualCashPercentage}%
        </Typography>
      </Box>
    </Box>
  );

  // Right side metrics component (debt percentages)
  const DebtPercentagesCards = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: shouldReorganizeLayout ? 'row' : 'column',
        justifyContent: 'center',
        gap: 2,
        width: shouldReorganizeLayout ? '100%' : '200px',
        flex: shouldReorganizeLayout ? 1 : 'none',
      }}
    >
      {/* Credit Utilization % */}
      <Box
        sx={{
          p: 2,
          border: colors.TransactionsMetricsBorder,
          borderRadius: isPhone ? '10px' : '15px',
          bgcolor: colors.TransactionsMetricsBackground,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flex: shouldReorganizeLayout ? 1 : 'auto',
          height: shouldReorganizeLayout ? 'auto' : '50%',
        }}
      >
        <Typography
          variant="body2"
          sx={{ mb: 0.5, color: colors.textSecondary }}
        >
          Credit Utilization (%)
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {data.debts.utilizationPercentage}%
          </Typography>
          <Typography
            variant="caption"
            sx={{ ml: 0.5, color: colors.textSecondary, fontSize: '0.65rem' }}
          >
            of total limit
          </Typography>
        </Box>
      </Box>

      {/* Total Credit Left */}
      <Box
        sx={{
          p: 2,
          border: colors.TransactionsMetricsBorder,
          borderRadius: isPhone ? '10px' : '15px',
          bgcolor: colors.TransactionsMetricsBackground,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flex: shouldReorganizeLayout ? 1 : 'auto',
          height: shouldReorganizeLayout ? 'auto' : '50%',
        }}
      >
        <Typography
          variant="body2"
          sx={{ mb: 0.5, color: colors.textSecondary }}
        >
          Total Credit Left ($)
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {formatCurrency(data.debts.totalLeft)}
        </Typography>
      </Box>
    </Box>
  );

  // Modified Assets card component without title since it's already in the accordion header
  const AssetsCardContent = () => (
    <Box
      sx={{
        flex: 1,
        bgcolor: colors.white,
        borderRadius: isPhone ? '10px' : '15px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        border: colors.TransactionsMetricsBorder,
        minWidth: shouldReorganizeLayout ? 0 : '300px',
        width: shouldReorganizeLayout ? '100%' : 'auto',
        height: isTablet && !isPhone ? '220px' : 'auto',
      }}
    >
      <Box
        sx={{
          px: 2,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <LinkIcon
              sx={{ fontSize: '1.5rem', mr: 1, color: colors.primary }}
            />
            <Typography sx={{ fontSize: '1rem' }}>
              Linked Bank Account Cash
            </Typography>
            <Typography
              sx={{ ml: 'auto', fontWeight: 'medium', fontSize: '1rem' }}
            >
              {isPhone
                ? formatNumber(data.assets.linkedBankCash)
                : formatCurrency(data.assets.linkedBankCash)}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AddIcon
              sx={{ fontSize: '1.5rem', mr: 1, color: colors.primary }}
            />
            <Typography sx={{ fontSize: '1rem' }}>
              Manually Added Cash
            </Typography>
            <Typography
              sx={{
                ml: 'auto',
                fontWeight: 'medium',
                color: colors.black,
                fontSize: '1rem',
              }}
            >
              {isPhone
                ? formatNumber(data.assets.manualCash)
                : formatCurrency(data.assets.manualCash)}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              width: '100%',
              bgcolor: colors.lightGray2,
              borderRadius: 5,
              height: 3,
              mb: 1,
            }}
          ></Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Total Available Cash
            </Typography>
            <Typography
              variant="h5"
              sx={{ color: colors.primary, fontWeight: 'bold' }}
            >
              {isPhone
                ? formatNumber(data.assets.total)
                : formatCurrency(data.assets.total)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  // Modified Debts card component without title since it's already in the accordion header
  const DebtsCardContent = () => (
    <Box
      sx={{
        flex: 1,
        bgcolor: colors.white,
        borderRadius: isPhone ? '10px' : '15px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        border: colors.TransactionsMetricsBorder,
        minWidth: shouldReorganizeLayout ? 0 : '300px',
        width: shouldReorganizeLayout ? '100%' : 'auto',
        height: isTablet && !isPhone ? '220px' : 'auto',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            sx={{ color: colors.textSecondary, mb: 0.5, fontSize: '1rem' }}
          >
            Total Credit Used
          </Typography>
          <Typography
            sx={{
              color: colors.primary,
              fontWeight: 'bold',
              mb: 2,
              fontSize: 37,
            }}
          >
            {isPhone
              ? formatNumber(data.debts.totalUsed)
              : formatCurrency(data.debts.totalUsed)}
          </Typography>
        </Box>

        {/* Custom progress bar */}
        <Box
          sx={{
            width: '100%',
            px: 3,
          }}
        >
          <Box
            sx={{
              width: '100%',
              bgcolor: colors.lightGray2,
              borderRadius: 5,
              height: 10,
              mb: 1,
            }}
          >
            <Box
              sx={{
                width: `${percentageUsed}%`,
                bgcolor: colors.primary,
                borderRadius: 5,
                height: 10,
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant="body2" sx={{ color: colors.textSecondary }}>
              {isPhone
                ? formatNumber(data.debts.totalLimit)
                : formatCurrency(data.debts.totalLimit)}{' '}
              total limit
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  // For regular desktop view
  const AssetsCard = () => (
    <Box
      sx={{
        flex: 1,
        bgcolor: colors.white,
        borderRadius: isPhone ? '10px' : '15px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        border: colors.TransactionsMetricsBorder,
        minWidth: shouldReorganizeLayout ? 0 : '300px',
        width: shouldReorganizeLayout ? '100%' : 'auto',
        height: isTablet ? '220px' : 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <AttachMoneyIcon sx={{ mr: 1, fontSize: 30 }} />
          <Typography sx={{ fontWeight: 'bold', fontSize: 28 }}>
            Assets
          </Typography>
        </Box>
        {/* Add bank account button */}
        <IconButton
          size="small"
          onClick={() => navigate('/settings/bank-account')}
          sx={{
            bgcolor: colors.lightGray4,
            color: colors.white,
            '&:hover': { bgcolor: colors.darkGray },
            width: 25,
            height: 25,
          }}
        >
          <AccountBalanceIcon sx={{ fontSize: 15 }} />
        </IconButton>
      </Box>

      <Box
        sx={{
          px: 2,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <LinkIcon
              sx={{ fontSize: '1.5rem', mr: 1, color: colors.primary }}
            />
            <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>
              Linked Bank Account Cash
            </Typography>
            <Typography
              sx={{ ml: 'auto', fontWeight: 'medium', fontSize: '1rem' }}
            >
              {formatCurrency(data.assets.linkedBankCash)}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AddIcon
              sx={{ fontSize: '1.5rem', mr: 1, color: colors.primary }}
            />
            <Typography sx={{ fontSize: '1rem' }}>
              Manually Added Cash
            </Typography>
            <Typography
              sx={{
                ml: 'auto',
                fontWeight: 'medium',
                color: colors.black,
                fontSize: '1rem',
              }}
            >
              {formatCurrency(data.assets.manualCash)}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              width: '100%',
              bgcolor: colors.lightGray2,
              borderRadius: 5,
              height: 3,
              mb: 1,
            }}
          ></Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Total Available Cash
            </Typography>
            <Typography
              variant="h5"
              sx={{ color: colors.primary, fontWeight: 'bold' }}
            >
              {formatCurrency(data.assets.total)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  // For regular desktop view
  const DebtsCard = () => (
    <Box
      sx={{
        flex: 1,
        bgcolor: colors.white,
        borderRadius: isPhone ? '10px' : '15px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        border: colors.TransactionsMetricsBorder,
        minWidth: shouldReorganizeLayout ? 0 : '300px',
        width: shouldReorganizeLayout ? '100%' : 'auto',
        height: isTablet ? '220px' : 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <CreditCardIcon sx={{ mr: 1, fontSize: 30 }} />
          <Typography sx={{ fontWeight: 'bold', fontSize: 28 }}>
            Debts
          </Typography>
        </Box>
        {/* Add bank account button */}
        <IconButton
          size="small"
          onClick={() => navigate('/settings/bank-account')}
          sx={{
            bgcolor: colors.lightGray4,
            color: colors.white,
            '&:hover': { bgcolor: colors.darkGray },
            width: 25,
            height: 25,
          }}
        >
          <AccountBalanceIcon sx={{ fontSize: 15 }} />
        </IconButton>
      </Box>

      <Box
        sx={{
          textAlign: 'center',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            sx={{ color: colors.textSecondary, mb: 0.5, fontSize: '1rem' }}
          >
            Total Credit Used
          </Typography>
          <Typography
            sx={{
              color: colors.primary,
              fontWeight: 'bold',
              mb: 2,
              fontSize: 37,
            }}
          >
            {formatCurrency(data.debts.totalUsed)}
          </Typography>
        </Box>

        {/* Custom progress bar */}
        <Box
          sx={{
            width: '100%',
            px: 3,
          }}
        >
          <Box
            sx={{
              width: '100%',
              bgcolor: colors.lightGray2,
              borderRadius: 5,
              height: 10,
              mb: 1,
            }}
          >
            <Box
              sx={{
                width: `${percentageUsed}%`,
                bgcolor: colors.primary,
                borderRadius: 5,
                height: 10,
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant="body2" sx={{ color: colors.textSecondary }}>
              {formatCurrency(data.debts.totalLimit)} total limit
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  // For phone layout - render accordions
  if (isPhone) {
    return (
      <>
        {/* Assets Accordion */}
        <Box
          sx={{
            width: '100vw',
            mx: 0,
            mb: 0.8,
            mt: 2,
            bgcolor: '#FFFFFF',
            overflow: 'hidden',
            border: 'none',
            borderRadius: 0,
          }}
        >
          {/* Assets Accordion Header */}
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              borderBottom: assetsExpanded
                ? colors.TransactionsMetricsBorder
                : 'none',
              cursor: 'pointer',
            }}
            onClick={() => setAssetsExpanded(!assetsExpanded)}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AttachMoneyIcon sx={{ mr: 1, fontSize: 24 }} />
                <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
                  Assets
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {!assetsExpanded && (
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      mr: 1,
                      color: colors.primary,
                    }}
                  >
                    {formatNumber(data.assets.total)}
                  </Typography>
                )}
                {/* Bank account link button */}
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate('/settings/bank-account');
                  }}
                  sx={{
                    mr: 1,
                    bgcolor: colors.lightGray4,
                    color: colors.white,
                    '&:hover': { bgcolor: colors.darkGray },
                    width: 23,
                    height: 23,
                  }}
                >
                  <AccountBalanceIcon sx={{ fontSize: 13 }} />
                </IconButton>
                <IconButton size="small">
                  {assetsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
            </Box>

            {/* Subtitle text that appears only when collapsed */}
            {!assetsExpanded && (
              <Typography
                variant="body2"
                sx={{
                  color: colors.textSecondary,
                  ml: 4,
                  mt: -0.5,
                  fontSize: '0.75rem',
                }}
              >
                Total available cash
              </Typography>
            )}
          </Box>

          {/* Assets Accordion Content */}
          {assetsExpanded && (
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <AssetsCardContent />

                {/* Asset metrics stacked vertically */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Box
                    sx={{
                      p: 2,
                      border: colors.TransactionsMetricsBorder,
                      borderRadius: '10px',
                      bgcolor: colors.TransactionsMetricsBackground,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ mb: 0.4, color: colors.textSecondary }}
                    >
                      Linked Bank (%)
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      {data.assets.linkedBankPercentage}%
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      p: 2,
                      border: colors.TransactionsMetricsBorder,
                      borderRadius: '10px',
                      bgcolor: colors.TransactionsMetricsBackground,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ mb: 0.5, color: colors.textSecondary }}
                    >
                      Manual Cash (%)
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      {data.assets.manualCashPercentage}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        {/* Debts Accordion */}
        <Box
          sx={{
            width: '100vw',
            mx: 0,
            mb: 0.8,
            bgcolor: '#FFFFFF',
            overflow: 'hidden',
            border: 'none',
            borderRadius: 0,
          }}
        >
          {/* Debts Accordion Header */}
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              borderBottom: debtsExpanded
                ? colors.TransactionsMetricsBorder
                : 'none',
              cursor: 'pointer',
            }}
            onClick={() => setDebtsExpanded(!debtsExpanded)}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CreditCardIcon sx={{ mr: 1, fontSize: 24 }} />
                <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
                  Debts
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {!debtsExpanded && (
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      mr: 1,
                      color: colors.primary,
                    }}
                  >
                    {formatNumber(data.debts.totalUsed)}
                  </Typography>
                )}
                {/* Bank account link button */}
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate('/settings/bank-account');
                  }}
                  sx={{
                    mr: 1,
                    bgcolor: colors.lightGray4,
                    color: colors.white,
                    '&:hover': { bgcolor: colors.darkGray },
                    width: 23,
                    height: 23,
                  }}
                >
                  <AccountBalanceIcon sx={{ fontSize: 13 }} />
                </IconButton>
                <IconButton size="small">
                  {debtsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
            </Box>

            {/* Subtitle text that appears only when collapsed */}
            {!debtsExpanded && (
              <Typography
                variant="body2"
                sx={{
                  color: colors.textSecondary,
                  ml: 4,
                  mt: -0.5,
                  fontSize: '0.75rem',
                }}
              >
                Total credit used
              </Typography>
            )}
          </Box>

          {/* Debts Accordion Content */}
          {debtsExpanded && (
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <DebtsCardContent />

                {/* Debt metrics stacked vertically */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Box
                    sx={{
                      p: 2,
                      border: colors.TransactionsMetricsBorder,
                      borderRadius: '10px',
                      bgcolor: colors.TransactionsMetricsBackground,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ mb: 0.5, color: colors.textSecondary }}
                    >
                      Credit Utilization (%)
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {data.debts.utilizationPercentage}%
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      p: 2,
                      border: colors.TransactionsMetricsBorder,
                      borderRadius: '10px',
                      bgcolor: colors.TransactionsMetricsBackground,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ mb: 0.5, color: colors.textSecondary }}
                    >
                      Total Credit Left ($)
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      {isPhone
                        ? formatNumber(data.debts.totalLeft)
                        : formatCurrency(data.debts.totalLeft)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  }

  // For non-phone layouts, keep the original implementation
  return (
    <Box
      sx={{
        bgcolor: colors.white,
        borderRadius: '25px',
        p: 3,
        width: { xs: '95vw', sm: '95vw', md: '95vw', lg: '95vw' },
        mx: 'auto',
        my: 3,
      }}
    >
      {/* Main container - reorganizes based on screen size */}
      {shouldReorganizeLayout ? (
        // Reorganized layout for screens below 1590px (above 600px)
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {/* Main cards in first row */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: isTablet ? 'column' : 'row',
              gap: 3,
              '& > *': {
                flex: isTablet ? 'auto' : 1,
                width: isTablet ? '100%' : 'auto',
              },
            }}
          >
            <AssetsCard />
            <DebtsCard />
          </Box>

          {/* Small metrics cards in second row */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 3,
              '& > *': {
                flex: isMobile ? 'auto' : 1,
                width: isMobile ? '100%' : 'auto',
              },
            }}
          >
            <AssetPercentagesCards />
            <DebtPercentagesCards />
          </Box>
        </Box>
      ) : (
        // Original layout for large screens (above 1590px) - all cards in a row
        <Box
          sx={{
            display: 'flex',
            alignItems: 'stretch',
            gap: 3,
            height: '100%',
            '& > *': {
              flex: 1,
            },
          }}
        >
          <AssetPercentagesCards />
          <AssetsCard />
          <DebtsCard />
          <DebtPercentagesCards />
        </Box>
      )}
    </Box>
  );
};

export default TransactionsMetricsDisplay;
