export const GENERIC_SIGNUP_ERROR =
  'Something went wrong! Please contact support.';

  export const FIREBASE_ERRORS = {
    'auth/invalid-credential': 'Incorrect password. Please try again, or try resetting your Password.',
    'auth/too-many-requests': 'Too many attempts. Please try again later, or reset your password.',
    'auth/user-disabled': 'Your account has been disabled. Please contact support for assistance.',
    'auth/email-already-in-use': 'This email is already registered. Please sign in, or use a different email.',
  };
  

export const PASSWORD_CRITERIA = {
  length: 'At least 8 characters long',
  letter: 'Contains at least one letter',
  number: 'Contains at least one number',
  specialChar: 'Contains at least one special character from (!@#$%^&*)',
};

export const ALERT_POPUP_SEVERITY = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

export const DASHBOARD_ROUTES = [
  '/dashboard/chart',
  '/dashboard/datagrid',
  '/dashboard/reports',
  '/dashboard/sales',
];

export const TRANSACTIONS_QUERY_KEY = ['transactions'];
export const CATEGORIES_QUERY_KEY = ['categories'];
