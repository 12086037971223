export const colors = {
  primary: '#1360EF',
  primaryHover: '#1360EFD9',
  primaryDark: '#0E4AAB',
  secondary: '#F85014',
  textSecondary: '#6B7280',
  success: '#00C853',
  warning: '#FFD600',
  error: '#FF1744',
  info: '#00B0FF',
  light: '#F5F5F5',
  lightError: '#FFCCD5',
  dark: '#263238',
  white: '#FFFFFF',
  black: '#000000',
  black2: '#060928',
  black2Hover: '#1A1E40',
  gray: '#D4D4D0',
  lightGray: '#FBFBFB',
  lightGray2: '#F0F0F0',
  lightGray3: '#888888',
  darkGray: '#A9A9A9',
  bgGray: '#c8c5c5',
  darkGray2: '#434343',
  transparent: 'transparent',
  gradient: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  shadow: '1px 1px 3px rgba(0, 0, 0, 0.2)',
  expenses: '#f22233',
  income: '#03a63c',
  borderColor: '#00000036',
  hoverBg: '#303f9f',
  buttonBg: '#007bff',
  darkGray3: '#333333',
  mediumGray: '#666666',
  lightGray4: '#999999',
  ebtext: '#2A2A2A',
  BorderLine: '#007bff',
  receiptButtonGrey: `#ececec`,
  ebbackground: '#FFF0F0',
  aiCritiqueBg: '#e8f1ff',
  warningBg: '#fff3d7',
  errorBg: '#ffd7d7',
  primaryGradient: 'linear-gradient(90deg, #ea0e4a, #af52de, #5856d6, #5856d6)',
  boxShadowFocus:
    'rgba(236, 110, 173, 0.5), 0 0 12px 0 rgba(243, 144, 79, 0.5)',
  placeholder: '#6B7280',
  textHover: '#111827',
  filterChipBackground: '#000',
  loadingGradient: 'linear-gradient(45deg, #FFA657, #BC8CFF)',
  pulseShadow: 'rgba(0, 123, 255, 0.7)',
  text: '#555',
  SignInConGradient: 'linear-gradient(135deg, #e3f2fd, #ffffff)',
  signInText: 'linear-gradient(to right, #1976D2, #42A5F5)',
  signUpText: '#1976D2',
  DarkHeaderGradient: 'linear-gradient(135deg, #0000FF, #89CFF0)',
  PaperBackgroundGradient: 'linear-gradient(135deg, #ffffff, #f7f9fc)',
  PaperBackground: 'linear-gradient(145deg, #f3f4f6, #ffffff)',
  AppBackground: '#e9e9e9',
  PasswordBackground: '#F8FAFC',
  PasswordHover: '#F1F5F9',
  TransactionsMetricsBackground: '#f8f9ff',
  TransactionsMetricsBorder: '1px solid #e0e0e0',
  TransactionsMetricsBorder2: '1px solid rgb(0, 0, 0)',
  textPrimary: '#1A1A1A',
  sectionSummary: '#FFF7ED',
  sectionIncome: '#ECFDF5',
  sectionCostOfGoodsSold: '#FEF2F2',
  sectionGrossProfit: '#F5F3FF',
  sectionGrossProfitPercentage: '#EFF6FF',
  sectionOperatingExpenses: '#F0FDF4',
  sectionNetProfit: '#FDF2F8',
  sectionSuggestions: '#F8FAFC',
  chipBackground: '#e6efff',
  chipText: '#333333',
  chipIcon: '#ffa000',
  modalBackground: '#FFFFFF',
  border: '#E5E7EB',
  lightBackground: '#F3F4F6',
  iconHover: '#4B5563',
  coin: '#eaba1c',
  coinBorder: 'rgb(232, 178, 0)',
  overlay: 'rgba(255, 255, 255, 0.4)',
  shadow1: 'rgba(0, 0, 0, 0.1)',
  shadow2: 'rgba(0, 0, 0, 0.06)',
  shadow3: 'rgba(0, 0, 0, 0.2)',
  gradientStart: '#5aa8ff',
  gradientMiddle: '#ff6482',
  gradientEnd: '#5aa8ff',
  buttonBackground: '#0072f5',
  buttonText: '#feffff',
  buttonShadow: 'rgba(0, 114, 245, 0.3)',
  borderColor2: '#d0d1d4',
  aiAnalysisBullet: '#4CAF50',
  aiInfoBackground: '#f0f7ff',
  aiInfoText: '#4285F4',

  // New metrics-specific colors
  successLight: 'rgba(46, 204, 113, 0.1)',
  errorLight: 'rgba(231, 76, 60, 0.1)',
  successText: '#2ecc71',
  errorText: '#e74c3c',

  // Tab navigation specific colors
  primaryLight: 'rgba(19, 96, 239, 0.05)',

  // Sales table source chip colors
  sourceChipBg: '#f0f4ff',
  sourceChipText: '#3366cc',

  // Sales table status colors
  statusCompletedBg: '#e6f7ee',
  statusCompletedText: '#0e9b5a',
  statusPendingBg: '#f8f8e8',
  statusPendingText: '#9b9b37',
  statusFailedBg: '#fee8e8',
  statusFailedText: '#d32f2f',
  statusCanceledBg: '#eeeeee',
  statusCanceledText: '#757575',

  // Sales table colors
  salesGridHeaderBg: '#f5f5f5',
  salesGridBorderColor: '#f0f0f0',
  salesGridBoxShadow: 'rgba(0,0,0,0.05)',

  // Default category colors array (for when categories don't have assigned colors)
  categoryDefaultColors: [
    '#e6f7ee',
    '#e6f0ff',
    '#f8f8e8',
    '#fee8e8',
    '#eeeeee',
  ],

  // Mobile sales page specific colors
  mobileSalesHeaderBg: '#4169e1',
  mobileSalesHoverBg: 'rgba(0,0,0,0.05)',
  mobileSalesDateHeaderBg: 'rgba(65, 105, 225, 0.1)',
  mobileSalesDateHeaderText: '#4169e1',
  mobileSalesBorderColor: '#e0e0e0',
  mobileSalesExpandedBg: 'rgba(0,0,0,0.02)',
  mobileSalesLoadingOverlay: 'rgba(255,255,255,0.7)',
  mobileSalesButtonHoverBg: 'rgba(255,255,255,0.2)',
  mobileSalesButtonBg: 'rgba(255,255,255,0.1)',
  mobileSalesPositiveAvatarBg: 'rgba(14, 155, 90, 0.1)',
  mobileSalesPositiveAvatarText: '#0e9b5a',
  mobileSalesNegativeAvatarBg: 'rgba(211, 47, 47, 0.1)',
  mobileSalesNegativeAvatarText: '#d32f2f',

  // Sales edit toolbar colors
  toolbarButtonText: '#616161',
  toolbarButtonHoverText: '#424242',
  toolbarButtonHoverBg: 'rgba(0, 0, 0, 0.04)',

  // Search toolbar colors
  searchPlaceholderText: '#757575',
  searchText: '#333',
  searchBorderHover: 'rgba(0, 0, 0, 0.2)',
  searchBorderFocus: 'rgba(90, 168, 255, 0.5)',
  searchShadowFocus: 'rgba(90, 168, 255, 0.1)',
  searchPulseColor: 'rgba(90, 168, 255, 0.1)',

  // Filter component colors
  filterIconColor: '#1565C0',
  filterButtonColor: '#1565C0',
  filterButtonHoverColor: '#1976D2',
  filterButtonActiveBg: 'rgba(21, 101, 192, 0.08)',
  filterButtonHoverBg: 'rgba(21, 101, 192, 0.04)',
  filterDisabledColor: 'rgba(0, 0, 0, 0.38)',
  filterDisabledBorderColor: 'rgba(0, 0, 0, 0.1)',
  filterSelectedBg: 'rgba(21, 101, 192, 0.08)',
  filterSelectedHoverBg: 'rgba(21, 101, 192, 0.12)',
  filterOptionHoverBg: 'rgba(0, 0, 0, 0.04)',
  filterPopoverBoxShadow: 'rgba(0,0,0,0.1)',
  filterResetButtonHoverBg: 'rgba(25, 118, 210, 0.08)',
  filterCloseButtonHoverBg: 'rgba(0, 0, 0, 0.04)',
};

export const categoriesColorArray = [
  '#e0f7fa',
  '#f0f4c3',
  '#fff8e1',
  '#f3e5f5',
  '#e8f5e9',
  '#fce4ec',
  '#e0f2f1',
  '#fff3e0',
  '#f1f8e9',
  '#f9fbe7',
  '#ede7f6',
  '#e8eaf6',
  '#e3f2fd',
  '#fbe9e7',
  '#f5f5f5',
  '#e6ee9c',
  '#d1c4e9',
  '#c5cae9',
  '#ffccbc',
  '#d7ccc8',
  '#ffeb3b',
  '#ffe0b2',
  '#c8e6c9',
  '#bbdefb',
  '#ffecb3',
  '#f8bbd0',
  '#ffccbc',
  '#e1bee7',
  '#c5e1a5',
  '#b3e5fc',
  '#ffcc80',
  '#d7ccc8',
];

export const pieChart = {
  pieChartColors: [
    '#0EA5E9', // Sky blue
    '#F97316', // Orange
    '#10B981', // Emerald green
    '#8B5CF6', // Purple
    '#EF4444', // Red
    '#14B8A6', // Teal
    '#F59E0B', // Amber
    '#6366F1', // Indigo
    '#EC4899', // Pink
    '#64748B', // Slate
    '#94A3B8', // Light slate
  ],
  primary: '#1F2937', // Darker gray
  secondary: '#6B7280', // Medium gray
};
