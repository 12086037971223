import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  InputAdornment,
  TextField,
  Divider,
  IconButton,
  List,
  ListItem,
  Select,
  MenuItem,
  Chip,
  FormControl,
  Button,
  Avatar,
  Collapse,
  CircularProgress,
} from '@mui/material';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  ArrowDropDown as ArrowDropDownIcon,
  RestartAlt as RestartAltIcon,
  ShoppingCart as ShoppingCartIcon,
  CreditCard as CreditCardIcon,
  AccountBalance as AccountBalanceIcon,
  MonetizationOn as MonetizationOnIcon,
  Receipt as ReceiptIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
} from '@mui/icons-material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import dayjs from 'dayjs';
import { useStore } from '@tanstack/react-store';
import { store, updateStore } from '../../../data/store';
import searchSalesTransactions from '../../../utils/salesSearch';
import { colors } from '../../../themes/theme';

/**
 * Enhanced Mobile version of the Sales Data Grid
 * Implements a mobile-friendly UI for viewing sales transactions with improved UX
 */
const MobileSalesPage = ({
  rows = [],
  searchTerm = '',
  setSearchTerm = () => {},
  startDate,
  endDate,
  isLoading = false,
  onRefresh = () => {},
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState('Date');
  const [sortDirection, setSortDirection] = useState('Descending');
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [dateFilter, setDateFilter] = useState('All Time');
  const [refreshing, setRefreshing] = useState(false);

  // Use sales-specific state key
  const salesTransactionType = useStore(
    store,
    (state) => state.salesTransactionType || 'All'
  );

  const handleFilterChange = (newValue) => {
    updateStore({ salesTransactionType: newValue });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // Save to localStorage for persistence between view changes
    localStorage.setItem('salesSearchTerm', event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    localStorage.setItem('salesSearchTerm', '');
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // Refresh handler with loading animation
  const handleRefresh = useCallback(() => {
    setRefreshing(true);
    onRefresh();

    // Simulate refresh animation
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, [onRefresh]);

  const toggleItemExpanded = (id) => {
    setExpandedItemId(expandedItemId === id ? null : id);
  };

  // Reset all filters with animation frame sequencing for smoother transitions
  const resetAllFilters = () => {
    requestAnimationFrame(() => {
      setSortBy('Date');
      requestAnimationFrame(() => {
        setSortDirection('Descending');
        requestAnimationFrame(() => {
          updateStore({ salesTransactionType: 'All' });
          setSearchTerm('');
          setDateFilter('All Time');
          localStorage.setItem('salesSearchTerm', '');
        });
      });
    });
  };

  // State for filter animation effect
  const [isFiltering, setIsFiltering] = useState(false);

  // Apply animation effect when filter criteria change
  useEffect(() => {
    setIsFiltering(true);
    const timer = setTimeout(() => {
      setIsFiltering(false);
    }, 300); // Match this with the CSS transition duration
    return () => clearTimeout(timer);
  }, [searchTerm, salesTransactionType, sortBy, sortDirection, dateFilter]);

  // Handle pull-to-refresh gesture
  useEffect(() => {
    let touchStartY = 0;
    let touchEndY = 0;
    const minSwipeDistance = 80;
    const listElement = document.getElementById('transaction-list');

    const handleTouchStart = (e) => {
      touchStartY = e.touches[0].clientY;
    };

    const handleTouchEnd = (e) => {
      if (!listElement) return;
      touchEndY = e.changedTouches[0].clientY;

      // Check if at top of list and swiped down
      if (
        listElement.scrollTop === 0 &&
        touchEndY - touchStartY > minSwipeDistance
      ) {
        handleRefresh();
      }
    };

    if (listElement) {
      listElement.addEventListener('touchstart', handleTouchStart);
      listElement.addEventListener('touchend', handleTouchEnd);

      return () => {
        listElement.removeEventListener('touchstart', handleTouchStart);
        listElement.removeEventListener('touchend', handleTouchEnd);
      };
    }
    return undefined;
  }, [handleRefresh]);

  // Apply filters and sorting to rows
  const filteredRows = useMemo(() => {
    // First filter by transaction type
    let filtered = [...rows];

    // Apply date filter
    if (dateFilter !== 'All Time') {
      const now = dayjs();
      let startOfPeriod;

      switch (dateFilter) {
        case 'Today':
          startOfPeriod = now.startOf('day');
          break;
        case 'This Week':
          startOfPeriod = now.startOf('week');
          break;
        case 'This Month':
          startOfPeriod = now.startOf('month');
          break;
        case 'This Year':
          startOfPeriod = now.startOf('year');
          break;
        default:
          startOfPeriod = null;
      }

      if (startOfPeriod) {
        filtered = filtered.filter(
          (row) => row.date && dayjs(row.date).isAfter(startOfPeriod)
        );
      }
    }

    if (salesTransactionType !== 'All') {
      filtered = filtered.filter((row) => {
        if (salesTransactionType === 'Income') {
          return row.amount >= 0;
        } else if (salesTransactionType === 'Expenses') {
          return row.amount < 0;
        }
        return true;
      });
    }

    // Apply the enhanced unified search
    if (searchTerm) {
      filtered = searchSalesTransactions(filtered, searchTerm);
    }

    // Apply sorting
    return filtered.sort((a, b) => {
      const sortMultiplier = sortDirection === 'Ascending' ? 1 : -1;

      switch (sortBy) {
        case 'ID':
          return sortMultiplier * ((a.id || 0) - (b.id || 0));
        case 'Date':
          return (
            sortMultiplier * (new Date(a.date || 0) - new Date(b.date || 0))
          );
        case 'Description':
          return (
            sortMultiplier *
            (a.description || '').localeCompare(b.description || '')
          );
        case 'Account':
          return (
            sortMultiplier * (a.account || '').localeCompare(b.account || '')
          );
        case 'Items':
          return sortMultiplier * ((a.items || 1) - (b.items || 1));
        case 'Amount':
          return sortMultiplier * ((a.amount || 0) - (b.amount || 0));
        case 'Status':
          return (
            sortMultiplier * (a.status || '').localeCompare(b.status || '')
          );
        case 'PaymentMethod':
          return (
            sortMultiplier *
            (a.paymentMethod || '').localeCompare(b.paymentMethod || '')
          );
        case 'Category':
          return (
            sortMultiplier * (a.category || '').localeCompare(b.category || '')
          );
        default:
          return 0;
      }
    });
  }, [
    rows,
    salesTransactionType,
    searchTerm,
    sortBy,
    sortDirection,
    dateFilter,
  ]);

  // Group transactions by the selected sort field
  const groupedTransactions = useMemo(() => {
    const grouped = {};

    filteredRows.forEach((row) => {
      let groupKey;

      switch (sortBy) {
        case 'Date':
          if (!row.date) return;
          groupKey = dayjs(row.date).format('YYYY-MM-DD');
          break;
        case 'Amount':
          // Group by positive/negative amount
          groupKey = row.amount >= 0 ? 'Income' : 'Expense';
          break;
        case 'Status':
          groupKey = row.status || 'Unknown';
          break;
        case 'PaymentMethod':
          groupKey = row.paymentMethod || 'Unknown';
          break;
        case 'Account':
          groupKey = row.account || 'Unknown';
          break;
        case 'Category':
          groupKey = row.category || 'Uncategorized';
          break;
        case 'ID':
          // For ID, group by first digit of padded ID
          const paddedId = (row.id?.toString() || '0000').padStart(4, '0');
          groupKey = `ID: ${paddedId.charAt(0)}xxx`;
          break;
        case 'Items':
          // Group by item count
          groupKey = `${row.items || 1} item${row.items !== 1 ? 's' : ''}`;
          break;
        case 'Description':
          // For descriptions, group by first letter
          groupKey = row.description
            ? row.description.charAt(0).toUpperCase()
            : 'Other';
          break;
        default:
          groupKey = 'Other';
      }

      if (!grouped[groupKey]) {
        grouped[groupKey] = [];
      }
      grouped[groupKey].push(row);
    });

    return Object.entries(grouped).sort(([keyA], [keyB]) => {
      // Sort based on the field type
      if (sortBy === 'Date') {
        return sortDirection === 'Ascending'
          ? new Date(keyA) - new Date(keyB)
          : new Date(keyB) - new Date(keyA);
      } else if (sortBy === 'Amount') {
        // For amount, always show Income first
        return sortDirection === 'Ascending'
          ? keyA.localeCompare(keyB)
          : keyB.localeCompare(keyA);
      } else {
        // String comparison for other fields
        return sortDirection === 'Ascending'
          ? keyA.localeCompare(keyB)
          : keyB.localeCompare(keyA);
      }
    });
  }, [filteredRows, sortBy, sortDirection]);

  const shouldUseGroupedView = true;

  // Get consistent icons for different payment methods
  const getPaymentIcon = (method) => {
    if (!method) return <ReceiptIcon fontSize="small" />;

    switch (method.toLowerCase()) {
      case 'credit card':
        return <CreditCardIcon fontSize="small" />;
      case 'bank transfer':
      case 'bank':
      case 'account':
        return <AccountBalanceIcon fontSize="small" />;
      case 'cash':
      case 'money':
      case 'currency':
        return <MonetizationOnIcon fontSize="small" />;
      default:
        return <ReceiptIcon fontSize="small" />;
    }
  };

  // Get consistent icons for different group headers
  const getGroupHeaderIcon = (groupKey) => {
    switch (sortBy) {
      case 'Date':
        return null; // No icon for date headers
      case 'Amount':
        return groupKey === 'Income' ? (
          <TrendingUpIcon
            fontSize="small"
            sx={{ mr: 1, color: colors.mobileSalesPositiveAvatarText }}
          />
        ) : (
          <TrendingDownIcon
            fontSize="small"
            sx={{ mr: 1, color: colors.mobileSalesNegativeAvatarText }}
          />
        );
      case 'Status':
        return <ReceiptIcon fontSize="small" sx={{ mr: 1 }} />;
      case 'PaymentMethod':
        // Reuse the same payment method icon function
        return getPaymentIcon(groupKey);
      case 'Account':
        return <AccountBalanceIcon fontSize="small" sx={{ mr: 1 }} />;
      default:
        return <FilterAltIcon fontSize="small" sx={{ mr: 1 }} />;
    }
  };

  // Get status color and styling information
  const getStatusInfo = (status) => {
    switch (status) {
      case 'PENDING':
        return {
          color: colors.statusPendingText,
          bgColor: colors.statusPendingBg,
          icon: null,
        };
      case 'COMPLETED':
        return {
          color: colors.statusCompletedText,
          bgColor: colors.statusCompletedBg,
          icon: null,
        };
      case 'FAILED':
        return {
          color: colors.statusFailedText,
          bgColor: colors.statusFailedBg,
          icon: null,
        };
      default:
        return {
          color: colors.statusCanceledText,
          bgColor: colors.statusCanceledBg,
          icon: null,
        };
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.salesGridHeaderBg,
        position: 'relative',
        mt: -5,
      }}
    >
      {/* Header with title, tabs, and search */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: colors.mobileSalesHeaderBg,
          color: colors.white,
          borderRadius: { xs: '0', sm: '12px 12px 0 0' },
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          position: 'relative',
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ShoppingCartIcon sx={{ mr: 1 }} />
            <Typography variant="h6" fontWeight="bold">
              Sales Overview
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <IconButton
              onClick={toggleFilters}
              sx={{ color: colors.white }}
              aria-label="Toggle filters"
            >
              <FilterAltIcon />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ p: 2, pt: 1 }}>
          <TextField
            fullWidth
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: colors.searchPlaceholderText }} />
                </InputAdornment>
              ),
              endAdornment: searchTerm ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear search"
                    onClick={handleClearSearch}
                    edge="end"
                    size="small"
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null,
              sx: {
                backgroundColor: colors.white,
                borderRadius: '8px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.transparent,
                },
              },
            }}
          />
        </Box>

        {/* Filter section that shows when toggled with smooth transitions */}
        <Collapse in={showFilters}>
          <Box sx={{ p: 2, backgroundColor: 'rgba(0,0,0,0.05)' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1,
              }}
            >
              <Typography variant="subtitle1" fontWeight="medium">
                Advanced Filters
              </Typography>
              <Button
                variant="text"
                size="small"
                startIcon={<RestartAltIcon />}
                onClick={resetAllFilters}
                sx={{
                  color: colors.white,
                  backgroundColor: colors.mobileSalesButtonBg,
                  transition: 'all 0.3s ease, transform 0.15s ease',
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: colors.mobileSalesButtonHoverBg,
                  },
                  '&:active': {
                    transform: 'scale(0.98)',
                  },
                }}
              >
                Reset
              </Button>
            </Box>

            {/* Sort By Section */}
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Sort by
            </Typography>
            <Box sx={{ mb: 2 }}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: '8px',
                  mb: 1,
                }}
              >
                <Select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  IconComponent={ArrowDropDownIcon}
                  sx={{
                    color: colors.black,
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.transparent,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.transparent,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.transparent,
                    },
                    borderRadius: '8px',
                  }}
                >
                  <MenuItem value="ID">ID</MenuItem>
                  <MenuItem value="Date">Date</MenuItem>
                  <MenuItem value="Description">Details</MenuItem>
                  <MenuItem value="Account">Source</MenuItem>
                  <MenuItem value="Items">Items</MenuItem>
                  <MenuItem value="Amount">Amount</MenuItem>
                  <MenuItem value="Status">Status</MenuItem>
                  <MenuItem value="PaymentMethod">Payment Method</MenuItem>
                  <MenuItem value="Category">Category</MenuItem>
                </Select>
              </FormControl>

              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  variant={
                    sortDirection === 'Ascending' ? 'contained' : 'outlined'
                  }
                  fullWidth
                  onClick={() => setSortDirection('Ascending')}
                  sx={{
                    backgroundColor:
                      sortDirection === 'Ascending'
                        ? colors.primary
                        : colors.white,
                    color:
                      sortDirection === 'Ascending'
                        ? colors.white
                        : colors.black,
                    border:
                      sortDirection === 'Ascending'
                        ? 'none'
                        : `1px solid ${colors.border}`,
                    borderRadius: '8px',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor:
                        sortDirection === 'Ascending'
                          ? colors.primaryHover
                          : colors.lightGray2,
                    },
                  }}
                >
                  Ascending
                </Button>
                <Button
                  variant={
                    sortDirection === 'Descending' ? 'contained' : 'outlined'
                  }
                  fullWidth
                  onClick={() => setSortDirection('Descending')}
                  sx={{
                    backgroundColor:
                      sortDirection === 'Descending'
                        ? colors.primary
                        : colors.white,
                    color:
                      sortDirection === 'Descending'
                        ? colors.white
                        : colors.black,
                    border:
                      sortDirection === 'Descending'
                        ? 'none'
                        : `1px solid ${colors.border}`,
                    borderRadius: '8px',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor:
                        sortDirection === 'Descending'
                          ? colors.primaryHover
                          : colors.lightGray2,
                    },
                  }}
                >
                  Descending
                </Button>
              </Box>
            </Box>

            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Transaction Type
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              sx={{ backgroundColor: colors.white, borderRadius: '8px' }}
            >
              <Select
                value={salesTransactionType}
                onChange={(e) => handleFilterChange(e.target.value)}
                IconComponent={ArrowDropDownIcon}
                sx={{
                  color: colors.black,
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.transparent,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.transparent,
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.transparent,
                  },
                  borderRadius: '8px',
                }}
              >
                <MenuItem value="All">All Transactions</MenuItem>
                <MenuItem value="Income">Income Only</MenuItem>
                <MenuItem value="Expenses">Expenses Only</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Collapse>
      </Box>

      {/* Loading overlay */}
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: colors.mobileSalesLoadingOverlay,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 20,
          }}
        >
          <CircularProgress sx={{ color: colors.primary }} />
        </Box>
      )}

      {/* Pull to refresh indicator */}
      <Box
        sx={{
          height: refreshing ? '50px' : '0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'height 0.3s ease',
          overflow: 'hidden',
          backgroundColor: colors.salesGridHeaderBg,
        }}
      >
        {refreshing && (
          <CircularProgress size={24} sx={{ color: colors.primary }} />
        )}
      </Box>

      {/* Transaction list with transition effect for filtered items */}
      <List
        id="transaction-list"
        sx={{
          padding: 0,
          margin: 0,
          overflow: 'auto',
          flexGrow: 1,
          backgroundColor: colors.salesGridHeaderBg,
          transition: 'all 0.3s ease',
          opacity: isFiltering ? 0.7 : 1,
          '& .MuiListItem-root': {
            padding: '16px',
            borderBottom: 'none',
          },
        }}
      >
        {filteredRows.length === 0 ? (
          <Box
            sx={{
              p: 4,
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '300px',
            }}
          >
            <ReceiptIcon
              sx={{ fontSize: 60, color: colors.textSecondary, mb: 2 }}
            />
            <Typography variant="h6" color={colors.textSecondary} gutterBottom>
              No transactions found
            </Typography>
            <Typography variant="body2" color={colors.textSecondary}>
              Try adjusting your filters or search criteria
            </Typography>
            <Button
              variant="outlined"
              startIcon={<RestartAltIcon />}
              onClick={resetAllFilters}
              sx={{ mt: 2 }}
            >
              Reset Filters
            </Button>
          </Box>
        ) : shouldUseGroupedView ? (
          // Grouped by date view
          groupedTransactions.map(([dateKey, transactions]) => (
            <Box key={dateKey} sx={{ mb: 2 }}>
              <Box
                sx={{
                  backgroundColor:
                    sortBy === 'Amount' && dateKey === 'Income'
                      ? `rgba(${colors.income.replace(/[^\d,]/g, '')}, 0.1)` // Green tint for income
                      : sortBy === 'Amount' && dateKey === 'Expense'
                        ? `rgba(${colors.expenses.replace(/[^\d,]/g, '')}, 0.1)` // Red tint for expenses
                        : colors.mobileSalesDateHeaderBg,
                  p: 1,
                  pl: 2,
                  position: 'sticky',
                  top: 0,
                  zIndex: 5,
                  backdropFilter: 'blur(10px)',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: `1px solid ${colors.mobileSalesBorderColor}`,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {getGroupHeaderIcon(dateKey)}
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color={
                      sortBy === 'Amount' && dateKey === 'Income'
                        ? colors.income
                        : sortBy === 'Amount' && dateKey === 'Expense'
                          ? colors.expenses
                          : colors.mobileSalesDateHeaderText
                    }
                  >
                    {sortBy === 'Date'
                      ? dayjs(dateKey).format('MMM D, YYYY')
                      : dateKey}
                  </Typography>
                </Box>
                <Typography variant="caption" color={colors.textSecondary}>
                  {transactions.length} transaction
                  {transactions.length !== 1 ? 's' : ''}
                </Typography>
              </Box>
              {transactions.map((row, index) => (
                <TransactionItem
                  key={row.id || index}
                  row={row}
                  expanded={expandedItemId === row.id}
                  onToggleExpand={() => toggleItemExpanded(row.id)}
                  getPaymentIcon={getPaymentIcon}
                  getStatusInfo={getStatusInfo}
                />
              ))}
            </Box>
          ))
        ) : (
          // Flat list view
          filteredRows.map((row, index) => (
            <TransactionItem
              key={row.id || index}
              row={row}
              expanded={expandedItemId === row.id}
              onToggleExpand={() => toggleItemExpanded(row.id)}
              getPaymentIcon={getPaymentIcon}
              getStatusInfo={getStatusInfo}
            />
          ))
        )}
      </List>
    </Box>
  );
};

/**
 * Individual transaction item component that supports expansion/collapse
 * and implements touch gestures for interaction
 */
const TransactionItem = ({
  row,
  expanded,
  onToggleExpand,
  getPaymentIcon,
  getStatusInfo,
}) => {
  const statusInfo = getStatusInfo(row.status);

  // Touch gesture handling for swipe interactions
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [swiping, setSwiping] = useState(false);
  const [swipeOffset, setSwipeOffset] = useState(0);

  const minSwipeDistance = 40; // Minimum swipe distance for action

  const onTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
    setSwiping(true);
  };

  const onTouchMove = (e) => {
    if (!swiping) return;

    const currentTouch = e.targetTouches[0].clientX;
    const diff = currentTouch - touchStart;

    // Limit the swipe to a maximum distance (100px)
    if (diff > 0 && diff < 100) {
      setSwipeOffset(diff);
    }
  };

  const onTouchEnd = (e) => {
    setTouchEnd(e.changedTouches[0].clientX);
    setSwiping(false);

    // Only toggle expanded state if swipe was long enough
    if (touchStart && touchEnd && touchEnd - touchStart > minSwipeDistance) {
      onToggleExpand();
    }

    // Reset swipe offset with a delay for smooth animation
    setTimeout(() => {
      setSwipeOffset(0);
    }, 100);
  };

  // Format the amount to display with currency symbol
  const formattedAmount = `${row.amount >= 0 ? '+' : '-'}$${Math.abs(row.amount / 100).toFixed(2)}`;

  return (
    <Box
      sx={{
        mb: 0,
        background: colors.white,
        position: 'relative',
        borderBottom: `1px solid ${colors.mobileSalesBorderColor}`,
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '16px',
          backgroundColor: colors.white,
          opacity: 1,
          transform: `translateX(${swipeOffset}px)`,
          transition: swiping ? 'none' : 'all 0.3s ease, transform 0.2s ease',
          animation: 'fadeIn 0.3s ease',
          '@keyframes fadeIn': {
            '0%': {
              opacity: 0,
              transform: 'translateY(10px)',
            },
            '100%': {
              opacity: 1,
              transform: 'translateY(0)',
            },
          },
          cursor: 'pointer',
          '&:active': {
            backgroundColor: colors.mobileSalesHoverBg,
          },
          boxShadow: expanded ? '0 2px 8px rgba(0,0,0,0.1)' : 'none',
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onClick={onToggleExpand}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            mb: 0.5,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              sx={{
                width: 28,
                height: 28,
                mr: 1,
                backgroundColor:
                  row.amount >= 0
                    ? colors.mobileSalesPositiveAvatarBg
                    : colors.mobileSalesNegativeAvatarBg,
                color:
                  row.amount >= 0
                    ? colors.mobileSalesPositiveAvatarText
                    : colors.mobileSalesNegativeAvatarText,
              }}
            >
              {row.amount >= 0 ? (
                <TrendingUpIcon fontSize="small" />
              ) : (
                <TrendingDownIcon fontSize="small" />
              )}
            </Avatar>
            <Typography variant="subtitle1" fontWeight="bold">
              #{(row.id?.toString() || '0000').padStart(4, '0')}
            </Typography>
          </Box>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            color={row.amount >= 0 ? colors.income : colors.expenses}
          >
            {formattedAmount}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 0.5,
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip
              label={row.account || 'Unknown'}
              size="small"
              sx={{
                backgroundColor: colors.sourceChipBg,
                color: colors.sourceChipText,
                fontWeight: 400,
                fontSize: '0.75rem',
                mr: 1,
              }}
            />
            <Typography variant="body2" color={colors.textSecondary}>
              {row.paymentMethod || 'Cash'}
            </Typography>
          </Box>

          <Typography variant="body2" color={colors.textSecondary}>
            {row.date ? dayjs(row.date).format('MMM D') : ''}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mt: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: '0.875rem',
              maxWidth: '70%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {row.description || 'Transaction details'}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Chip
              label={
                row.status?.charAt(0) + row.status?.slice(1).toLowerCase() ||
                'Pending'
              }
              size="small"
              sx={{
                backgroundColor: statusInfo.bgColor,
                color: statusInfo.color,
                fontWeight: 500,
                fontSize: '0.75rem',
              }}
            />

            {expanded ? (
              <ExpandLessIcon
                color="action"
                fontSize="small"
                sx={{ opacity: 0.6, color: colors.textSecondary }}
              />
            ) : (
              <ExpandMoreIcon
                color="action"
                fontSize="small"
                sx={{ opacity: 0.6, color: colors.textSecondary }}
              />
            )}
          </Box>
        </Box>
      </ListItem>

      {/* Expanded details panel */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          sx={{ p: 2, pt: 0, backgroundColor: colors.mobileSalesExpandedBg }}
        >
          <Divider sx={{ mb: 2 }} />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color={colors.textSecondary}>
              Transaction ID
            </Typography>
            <Typography variant="body2">
              {(row.id?.toString() || '0000').padStart(4, '0')}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color={colors.textSecondary}>
              Date & Time
            </Typography>
            <Typography variant="body2">
              {row.date ? dayjs(row.date).format('MMM D, YYYY h:mm A') : 'N/A'}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color={colors.textSecondary}>
              Payment Method
            </Typography>
            <Typography variant="body2">
              {row.paymentMethod || 'Cash'}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color={colors.textSecondary}>
              Account
            </Typography>
            <Typography variant="body2">
              {row.account || 'Default Account'}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color={colors.textSecondary}>
              Status
            </Typography>
            <Chip
              label={
                row.status?.charAt(0) + row.status?.slice(1).toLowerCase() ||
                'Pending'
              }
              size="small"
              sx={{
                backgroundColor: statusInfo.bgColor,
                color: statusInfo.color,
                fontWeight: 500,
                fontSize: '0.75rem',
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="body2" color={colors.textSecondary}>
              Items
            </Typography>
            <Typography variant="body2">{row.items || 3} items</Typography>
          </Box>

          <Typography
            variant="body2"
            color={colors.textSecondary}
            sx={{ mb: 0.5 }}
          >
            Description
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {row.description ||
              'No description available for this transaction.'}
          </Typography>
        </Box>
      </Collapse>

      <Divider />
    </Box>
  );
};

export default MobileSalesPage;
