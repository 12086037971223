import React, { memo, useCallback } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from '@mui/material';
import { CustomGridToolbarContainer } from '../../styles/style.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import SalesTransactionFilter from './SalesTransactionFilter.js';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { updateStore } from '../../../data/store';
import { colors } from '../../../themes/theme';

dayjs.extend(utc);

const SalesEditToolbar = ({ onFilterChange, isEditMode }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Memoize the filter change callback to prevent unnecessary re-renders
  const memoizedFilterChange = useCallback(
    (newFilterState) => {
      if (onFilterChange) {
        onFilterChange(newFilterState);
      }
    },
    [onFilterChange]
  );

  // Handles resetting all filter states and synchronizes components
  const resetAllFilters = useCallback(() => {
    if (isEditMode) return;

    // Reset global state
    updateStore({ salesTransactionType: 'All' });

    // Clear search term in localStorage
    localStorage.setItem('salesSearchTerm', '');

    // Update parent component
    if (onFilterChange) {
      onFilterChange('All');
    }

    // Trigger storage event to notify other components of the change
    window.dispatchEvent(new Event('storage'));
  }, [isEditMode, onFilterChange]);

  const DesktopLayout = () => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Sales
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <SalesTransactionFilter
            onFilterChange={memoizedFilterChange}
            isEditMode={isEditMode}
          />
        </Box>
      </Box>
    </Box>
  );

  const TabletLayout = () => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Sales Transactions
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {isSmallScreen && (
            <Button
              onClick={resetAllFilters}
              startIcon={<RestartAltIcon />}
              disabled={isEditMode}
              variant="text"
              size="small"
              sx={{
                borderRadius: '20px',
                color: colors.toolbarButtonText,
                textTransform: 'none',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': {
                  backgroundColor: colors.toolbarButtonHoverBg,
                  color: colors.toolbarButtonHoverText,
                },
                '&:active': {
                  transform: 'scale(0.98)',
                },
                '&.Mui-disabled': {
                  opacity: 0.5,
                },
              }}
            >
              Reset
            </Button>
          )}
          <SalesTransactionFilter
            onFilterChange={memoizedFilterChange}
            isEditMode={isEditMode}
          />
        </Box>
      </Box>
    </Box>
  );

  const MobileLayout = () => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        transition: 'all 0.3s ease',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          transition: 'all 0.3s ease',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            transition: 'all 0.3s ease',
          }}
        >
          Sales
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Button
            onClick={resetAllFilters}
            startIcon={<RestartAltIcon />}
            disabled={isEditMode}
            variant="text"
            size="small"
            sx={{
              minWidth: 0,
              padding: '4px 8px',
              borderRadius: '16px',
              color: colors.toolbarButtonText,
              textTransform: 'none',
              fontWeight: 500,
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: colors.toolbarButtonHoverBg,
                color: colors.toolbarButtonHoverText,
              },
              '&:active': {
                transform: 'scale(0.96)',
              },
              '&.Mui-disabled': {
                opacity: 0.5,
              },
            }}
          >
            Reset
          </Button>
          <SalesTransactionFilter
            onFilterChange={memoizedFilterChange}
            isEditMode={isEditMode}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <CustomGridToolbarContainer
      sx={{
        width: '100%',
        padding: {
          xs: '16px',
          sm: '20px',
          md: '24px',
        },
        marginBottom: '-10px',
        transition: 'all 0.3s ease',
      }}
    >
      {isDesktop ? (
        <DesktopLayout />
      ) : isTablet ? (
        <TabletLayout />
      ) : (
        <MobileLayout />
      )}
    </CustomGridToolbarContainer>
  );
};

// Optimize rendering by skipping updates when isEditMode hasn't changed
export default memo(SalesEditToolbar, (prevProps, nextProps) => {
  return prevProps.isEditMode === nextProps.isEditMode;
});
