import React, { memo } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CustomGridToolbarContainer, SButton } from '../../styles/style.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TransactionFilter from './transactionFilter.js';
import { colors } from '../../../themes/theme.js';

dayjs.extend(utc);

const EditToolbar = ({ onFilterChange, isEditMode, onAddTransaction }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const DesktopLayout = () => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Transactions
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <TransactionFilter
            onFilterChange={onFilterChange}
            isEditMode={isEditMode}
          />
          <SButton
            color="primary"
            startIcon={<AddIcon />}
            onClick={onAddTransaction}
            disabled={isEditMode}
            sx={{
              height: '40px',
              borderRadius: '20px',
              opacity: isEditMode ? 0.6 : 1,
              cursor: isEditMode ? 'not-allowed' : 'pointer',
              '&.Mui-disabled': {
                color: colors.black,
                backgroundColor: colors.black2,
                pointerEvents: 'none',
              },
              fontWeight: 'bold',
            }}
          >
            Add Transaction
          </SButton>
        </Box>
      </Box>
    </Box>
  );

  const TabletLayout = () => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Transactions
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <TransactionFilter
            onFilterChange={onFilterChange}
            isEditMode={isEditMode}
          />
          <SButton
            color="primary"
            startIcon={<AddIcon />}
            onClick={onAddTransaction}
            disabled={isEditMode}
            sx={{
              height: '40px',
              borderRadius: '20px',
              opacity: isEditMode ? 0.6 : 1,
              cursor: isEditMode ? 'not-allowed' : 'pointer',
              '&.Mui-disabled': {
                color: colors.black,
                backgroundColor: colors.black2,
                pointerEvents: 'none',
              },
              fontWeight: 'bold',
            }}
          >
            ADD
          </SButton>
        </Box>
      </Box>
    </Box>
  );

  const MobileLayout = () => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          width: '100%',
          textAlign: 'center',
          fontWeight: 600,
        }}
      >
        Transactions
      </Typography>
      <TransactionFilter
        onFilterChange={onFilterChange}
        isEditMode={isEditMode}
      />
    </Box>
  );

  return (
    <CustomGridToolbarContainer
      sx={{
        width: '100%',
        padding: {
          xs: '16px',
          sm: '20px',
          md: '24px',
        },
        marginBottom: '-10px',
      }}
    >
      {isDesktop ? (
        <DesktopLayout />
      ) : isTablet ? (
        <TabletLayout />
      ) : (
        <MobileLayout />
      )}
    </CustomGridToolbarContainer>
  );
};

export default memo(EditToolbar);
