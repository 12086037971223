import React, { useState, useCallback, memo } from 'react';
import {
  Box,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  LinearProgress,
  Modal,
  TextField,
  Button,
  SwipeableDrawer,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { colors } from '../../../themes/theme';

// Isolated DatePicker component that manages its own state
const IsolatedDatePicker = memo(
  ({
    onSelectComplete,
    initialStartDate,
    initialEndDate,
    minDate,
    maxDate,
    onClose,
    step: initialStep,
    isMobile,
  }) => {
    const [localStep, setLocalStep] = useState(initialStep);
    const [localStartDate, setLocalStartDate] = useState(
      dayjs(initialStartDate)
    );
    const [localEndDate, setLocalEndDate] = useState(
      localStep === 2 ? dayjs(initialEndDate) : null
    );

    // Handle date selection with different behavior for mobile/desktop
    const handleDateSelect = useCallback(
      (newDate) => {
        if (localStep === 1) {
          setLocalStartDate(newDate);

          if (isMobile) {
            setLocalStep(2);
          }
        } else {
          setLocalEndDate(newDate);

          if (isMobile) {
            // For mobile, auto-finish on end date selection
            onSelectComplete(
              localStartDate.format('YYYY-MM-DD'),
              newDate.format('YYYY-MM-DD')
            );
            onClose();
          }
        }
      },
      [localStep, localStartDate, isMobile, onSelectComplete, onClose]
    );

    const handleNext = useCallback(() => {
      if (localStep === 1) {
        setLocalStep(2);
      } else {
        onSelectComplete(
          localStartDate.format('YYYY-MM-DD'),
          localEndDate.format('YYYY-MM-DD')
        );
        onClose();
      }
    }, [localStep, localStartDate, localEndDate, onSelectComplete, onClose]);

    const handleBack = useCallback(() => {
      setLocalStep(1);
    }, []);

    const handleFinish = useCallback(() => {
      if (localStartDate && localEndDate) {
        onSelectComplete(
          localStartDate.format('YYYY-MM-DD'),
          localEndDate.format('YYYY-MM-DD')
        );
      }
      onClose();
    }, [localStartDate, localEndDate, onSelectComplete, onClose]);

    // Calculate current value and min date based on selection step
    const currentValue =
      localStep === 1 ? localStartDate : localEndDate || dayjs(maxDate);
    const currentMinDate = localStep === 1 ? dayjs(minDate) : localStartDate;

    return (
      <>
        <LinearProgress
          variant="determinate"
          value={localStep === 1 ? 50 : 100}
          sx={{
            mb: 3,
            height: 8,
            borderRadius: 4,
            bgcolor: 'action.hover',
            '& .MuiLinearProgress-bar': {
              bgcolor: colors.secondary,
            },
          }}
        />

        <Typography
          variant={isMobile ? 'subtitle1' : 'h6'}
          align="center"
          gutterBottom
          sx={{ fontWeight: 600 }}
        >
          Select {localStep === 1 ? 'Start' : 'End'} Date
        </Typography>
        <Typography
          variant="body2"
          color={colors.darkGray3}
          align="center"
          sx={{ mb: 3 }}
        >
          Step {localStep} of 2:{' '}
          {localStep === 1 ? 'Choose when to begin' : 'Choose when to end'}
        </Typography>

        <StaticDatePicker
          value={currentValue}
          onChange={handleDateSelect}
          minDate={currentMinDate}
          maxDate={dayjs(maxDate)}
          slots={{
            actionBar: () => null,
          }}
          sx={{
            '& .MuiPickersDay-root': {
              borderRadius: '8px',
              fontSize: isMobile ? '0.875rem' : '1rem',
              '&.Mui-selected': {
                bgcolor: colors.secondary,
                '&:hover': {
                  bgcolor: colors.secondary,
                },
              },
            },
            '& .MuiDayCalendar-weekDayLabel': {
              fontSize: isMobile ? '0.75rem' : '0.875rem',
            },
          }}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 3,
            px: isMobile ? 1 : 2,
          }}
        >
          <Button onClick={onClose} sx={{ color: colors.darkGray3 }}>
            Cancel
          </Button>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {localStep === 2 && (
              <>
                <Button onClick={handleBack} sx={{ color: colors.secondary }}>
                  Back to Start Date
                </Button>
                <Button onClick={handleFinish} sx={{ color: colors.secondary }}>
                  Finish
                </Button>
              </>
            )}
            {!isMobile && localStep === 1 && (
              <Button
                onClick={handleNext}
                sx={{
                  color: colors.secondary,
                  '&.Mui-disabled': {
                    color: colors.darkGray,
                  },
                }}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </>
    );
  }
);

// Add display name to satisfy ESLint's display-name rule
IsolatedDatePicker.displayName = 'IsolatedDatePicker';

// Main DateRangeIndicator component
const DateRangeIndicator = ({
  startDate,
  endDate,
  minDateRange,
  maxDateRange,
  onDateChange,
  canReset,
  isMobile,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // Only call onDateChange when both dates are completely selected
  const handleSelectComplete = useCallback(
    (newStartDate, newEndDate) => {
      if (newStartDate && newEndDate) {
        onDateChange(newStartDate, newEndDate);
      }
    },
    [onDateChange]
  );

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  // Shared styles for the TextField with conditional styling for reset state
  const commonStyles = {
    width: '99%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '24px !important',
    },
    '& input': {
      textAlign: 'center',
      fontSize: { xs: '0.75rem', sm: '0.875rem' },
    },
    '& .MuiInputAdornment-root': {
      marginRight: '8px',
      '& svg': {
        fontSize: { xs: '1.2rem', sm: '1.5rem' },
      },
    },
    ...(canReset && {
      '& .MuiOutlinedInput-root': {
        borderRadius: '24px !important',
        '& fieldset': {
          borderColor: colors.secondary,
        },
        '&:hover fieldset': {
          borderColor: colors.secondary,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.secondary,
        },
      },
    }),
  };

  // Format dates for display
  const formattedDateRange = `${dayjs(startDate).format('MMM DD, YYYY')} – ${dayjs(endDate).format('MMM DD, YYYY')}`;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={0.5} sx={{ width: '100%' }}>
        <TextField
          size="small"
          value={formattedDateRange}
          onClick={handleOpen}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }}
          sx={commonStyles}
        />

        {/* Conditional rendering for mobile vs desktop date picker */}
        {isMobile ? (
          <SwipeableDrawer
            anchor="bottom"
            open={isOpen}
            onClose={handleClose}
            onOpen={() => setIsOpen(true)}
            disableSwipeToOpen
            PaperProps={{
              sx: {
                height: '95vh',
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                px: 2,
                pt: 2,
                pb: 4,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mb: 1,
              }}
            >
              <IconButton
                onClick={handleClose}
                size="small"
                sx={{ color: colors.darkGray3 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Only render the picker when modal is open to improve performance */}
            {isOpen && (
              <IsolatedDatePicker
                onSelectComplete={handleSelectComplete}
                initialStartDate={startDate}
                initialEndDate={endDate}
                minDate={minDateRange}
                maxDate={maxDateRange}
                onClose={handleClose}
                step={1}
                isMobile={isMobile}
              />
            )}
          </SwipeableDrawer>
        ) : (
          <Modal
            open={isOpen}
            onClose={handleClose}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
            }}
          >
            <Box
              sx={{
                bgcolor: 'background.paper',
                borderRadius: 5,
                boxShadow: 24,
                p: 3,
                maxWidth: 'md',
                width: '100%',
                maxHeight: '90vh',
                overflow: 'auto',
              }}
            >
              {isOpen && (
                <IsolatedDatePicker
                  onSelectComplete={handleSelectComplete}
                  initialStartDate={startDate}
                  initialEndDate={endDate}
                  minDate={minDateRange}
                  maxDate={maxDateRange}
                  onClose={handleClose}
                  step={1}
                  isMobile={false}
                />
              )}
            </Box>
          </Modal>
        )}

        <Typography
          variant="caption"
          sx={{
            color: colors.darkGray,
            textAlign: 'center',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          Click above to select a custom date range.
        </Typography>
      </Stack>
    </LocalizationProvider>
  );
};

export default DateRangeIndicator;
