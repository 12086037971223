import { useState, useEffect } from "react";
import { Box, Typography, Zoom, Tooltip, Chip, Alert, AlertTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BoltIcon from "@mui/icons-material/Bolt";
import { motion, AnimatePresence } from "framer-motion";
import axiosInstance from "../../../api/axiosWrapper";
import { store, updateStore } from "../../../data/store";
import { useStore } from "@tanstack/react-store";
import logToSentry from "../../../lib/sentry";
import { colors } from "../../../themes/theme";
import { StyledTitle, StyledContent, SubTitleWrapper, SubTitle, SectionBox, TitleWithIcon, StyledList } from "./AiSummaryModal.styles";
import FinancialLoadingAnimation from "./FinancialLoadingAnimation";

const AiSummaryModal = ({ transactions = [], reports = [], type = "home", toggleMenu }) => {
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingText, setLoadingText] = useState("Gathering financial insights...");
  const { user } = useStore(store) || { user: { aiCredits: 0 } };

  useEffect(() => {
    if (loading) {
      const texts = [
        "Compiling your financial summary",
        "Evaluating income sources and expenses",
        "Analyzing your business performance",
        "Calculating your gross profit and margins",
        "Generating tailored insights",
        "Formulating AI-driven recommendations"
      ];
      let i = 0;
      const interval = setInterval(() => {
        setLoadingText(texts[i]);
        i = (i + 1) % texts.length;
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [loading]);

  const fetchInsights = async () => {
    setLoading(true);
    setError(null);
    updateStore({
      isAiInsightsLoading: true
    });
    try {
      const { data } = await axiosInstance.post("/ai-insights", {
        pageType: type,
        dateRange: {
          startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split("T")[0],
          endDate: new Date().toISOString().split("T")[0]
        },
        transactions: type === "home" ? transactions.map(transaction => ({
          account: transaction?.account?.name || transaction?.account || 'Unknown',
          amount: parseFloat(transaction?.amount),
          date: transaction.authorized_date || transaction.date,
          description: transaction.description || transaction.name,
          category: transaction.category,
          currency: transaction.currency || 'USD',
        })) : reports
      });
      setInsights(data);
      updateStore({
        user: {
          ...user,
          aiCredits: user.aiCredits - 1
        }
      });
    } catch (err) {
      logToSentry("fetchInsights", {
        status: "error",
        error: err,
        tags: { component: "AiSummaryModal" }
      });
      const apiErrorMessage =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : null;
      setError(
        apiErrorMessage ||
        "We encountered a hiccup while retrieving your financial insights. Don't worry, we're here to help! Please try again shortly, and if the issue continues, reach out to us at info@onebitapp.com for prompt assistance."
      );
    } finally {
      setLoading(false);
      updateStore({
        isAiInsightsLoading: false
      });
    }
  };

  useEffect(() => {
    if (!user.aiCredits || user.aiCredits <= 0) {
      setError("It seems you have run out of AI credits. To continue enjoying our insights, please reach out to us at info@onebitapp.com for assistance in adding more credits to your account.");
      return;
    }
    fetchInsights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderErrorState = () => {
    const isNoCredits = user.aiCredits <= 0;
    const alertSx = {
      width: "100%",
      borderRadius: "8px",
      padding: "16px",
      backgroundColor: "white",
      border: "1px solid black",
      color: "black",
      "& .MuiAlert-message": {
        width: "100%"
      }
    };
    return (
      <Box
        sx={{
          height: { xs: "auto", sm: "300px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 3
        }}
      >
        <Alert severity={isNoCredits ? "warning" : "error"} sx={alertSx}>
          <AlertTitle>{isNoCredits ? "No Credits Available" : "Error"}</AlertTitle>
          <Typography sx={{ color: "inherit", mt: 1, mb: 2 }}>
            {error || "Something went wrong. Please try again later."}
          </Typography>
          <Typography
            component="a"
            href={`mailto:info@onebitapp.com${isNoCredits ? "?subject=Request%20for%20Additional%20Credits" : "?subject=Error%20Report"}`}
            sx={{
              color: "inherit",
              textDecoration: "underline",
              "&:hover": { opacity: 0.8 }
            }}
          >
            Contact Support
          </Typography>
        </Alert>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: colors.overlay,
          backdropFilter: "blur(12px)",
          WebkitBackdropFilter: "blur(12px)",
          zIndex: -1
        }}
        onClick={loading ? undefined : () => toggleMenu(false)}
      />
      <Box
        sx={{
          position: "fixed",
          top: { xs: 0, sm: "50%" },
          left: { xs: 0, sm: "50%" },
          transform: { xs: "none", sm: "translate(-50%, -50%)" },
          width: { xs: "100%", sm: "95%" },
          height: { xs: "100%", sm: "auto" },
          maxWidth: { xs: "100%", sm: "800px" },
          maxHeight: { xs: "100%", sm: "98vh" },
          zIndex: 1400,
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Zoom in>
          <Box
            sx={{
              borderRadius: { xs: 0, sm: "16px" },
              position: "relative",
              backgroundColor: colors.modalBackground,
              boxShadow: `0 4px 6px -1px ${colors.shadow1}, 0 2px 4px -1px ${colors.shadow2}`,
              height: { xs: "100%", sm: "auto" },
              display: "flex",
              flexDirection: "column",
              overflow: "scroll"
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: colors.modalBackground,
                borderTopLeftRadius: { xs: 0, sm: "16px" },
                borderTopRightRadius: { xs: 0, sm: "16px" },
                borderBottom: `1px solid ${colors.border}`,
                padding: { xs: "16px", sm: "24px" }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: 2, sm: 0 },
                  borderRadius: "16px"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                  }}
                >
                  <TitleWithIcon>
                    <Typography
                      sx={{
                        fontSize: { xs: "1.5rem", sm: "2rem" },
                        fontWeight: 700,
                        color: colors.textPrimary
                      }}
                    >
                      ✨
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: { xs: "1.5rem", sm: "2rem" },
                        fontWeight: 700,
                        color: colors.textPrimary,
                        fontFamily: "SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif",
                        whiteSpace: "nowrap"
                      }}
                    >
                      Financial Summary
                    </Typography>
                  </TitleWithIcon>
                  <Box
                    sx={{
                      display: { xs: "none", sm: "flex" },
                      alignItems: "center",
                      gap: 2,
                      marginRight: "12px",
                      marginLeft: "auto"
                    }}
                  >
                    <Tooltip title="Every request to AI summary or AI search costs 1 credit" arrow placement="bottom">
                      <Chip
                        icon={<BoltIcon />}
                        label={`${user.aiCredits || 0} Credits Available`}
                        sx={{
                          backgroundColor: colors.chipBackground,
                          color: colors.chipText,
                          fontWeight: "bold",
                          height: "32px",
                          "& .MuiChip-icon": {
                            color: colors.chipIcon
                          }
                        }}
                      />
                    </Tooltip>
                  </Box>
                  {!loading && (
                    <Box
                      sx={{
                        cursor: "pointer",
                        width: { xs: "28px", sm: "32px" },
                        height: { xs: "28px", sm: "32px" },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        backgroundColor: colors.lightBackground,
                        "&:hover": { backgroundColor: colors.border }
                      }}
                      onClick={() => toggleMenu(false)}
                    >
                      <CloseIcon sx={{ fontSize: { xs: 18, sm: 20 }, color: colors.textSecondary }} />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                padding: { xs: "8px 16px", sm: "12px 30px" },
                flexGrow: 1,
                overflowY: "auto",
                borderRadius: "16px !important"
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    height: "300px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <FinancialLoadingAnimation />
                  <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 20 }} transition={{ duration: 0.5 }}>
                    <Typography
                      sx={{
                        color: colors.textSecondary,
                        fontWeight: 500,
                        fontSize: { xs: "1rem", sm: "1.125rem" },
                        marginTop: "12px",
                        textAlign: "center"
                      }}
                    >
                      {loadingText}
                    </Typography>
                  </motion.div>
                </Box>
              ) : error ? (
                renderErrorState()
              ) : (
                insights && (
                  <AnimatePresence>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
                      <>
                        <Box
                          sx={{
                            display: { xs: "flex", sm: "none" },
                            justifyContent: "center",
                            width: "100%",
                            borderRadius: "16px",
                            marginBottom: "10px"
                          }}
                        >
                          <Tooltip title="Every request to AI summary or AI search costs 1 credit" arrow placement="top">
                            <Chip
                              icon={<BoltIcon />}
                              label={`${user.aiCredits || 0} Credits Available`}
                              sx={{
                                backgroundColor: colors.chipBackground,
                                color: colors.chipText,
                                fontWeight: "bold",
                                height: "28px",
                                "& .MuiChip-icon": { color: colors.chipIcon },
                                "& .MuiChip-label": { px: 1, fontSize: "0.75rem" }
                              }}
                            />
                          </Tooltip>
                        </Box>
                        <SubTitleWrapper>
                          <SubTitle>AI summary of your financial health</SubTitle>
                          <Tooltip title="AI-generated suggestions are based on available data and may not be completely accurate. Please review all suggestions carefully." arrow placement="right">
                            <InfoOutlinedIcon
                              sx={{
                                fontSize: 16,
                                color: colors.textSecondary,
                                cursor: "help",
                                "&:hover": { color: colors.iconHover }
                              }}
                            />
                          </Tooltip>
                        </SubTitleWrapper>
                        {Object.entries(insights.insights)
                          .filter(([key]) => !["totalIncome", "totalExpenses", "netPosition"].includes(key))
                          .map(([key, value], index) => (
                            <motion.div key={key} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: index * 0.1 }}>
                              <SectionBox bgColor={colors["section" + key.charAt(0).toUpperCase() + key.slice(1)] || colors.lightBackground}>
                                <StyledTitle>{key.split(/(?=[A-Z])/).join(" ")}</StyledTitle>
                                {Array.isArray(value) ? (
                                  <StyledList>
                                    {value.map((item, idx) => (
                                      <li key={idx} dangerouslySetInnerHTML={{ __html: item }} />
                                    ))}
                                  </StyledList>
                                ) : (
                                  <StyledContent dangerouslySetInnerHTML={{ __html: value }} />
                                )}
                              </SectionBox>
                            </motion.div>
                          ))}
                      </>
                    </motion.div>
                  </AnimatePresence>
                )
              )}
            </Box>
          </Box>
        </Zoom>
      </Box>
    </>
  );
};

export default AiSummaryModal;