import React, { useMemo, useCallback, lazy, Suspense } from 'react';
import { Box, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { useStore } from '@tanstack/react-store';
import _ from 'lodash';
import { store } from '../../../data/store';
import AiSummary from '../AiSummary';

// Lazy load chart components to reduce initial bundle size
const MyPieChart = lazy(() => import('./Expenses Pie Chart'));
const NetChart = lazy(() => import('./Net Profit Chart'));
const TransactionChart = lazy(() => import('./Income Area Chart'));

// Loading fallback component
const ChartLoader = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100%"
    minHeight="300px"
  >
    <CircularProgress />
  </Box>
);

// Memoized utility function to format numbers into human-readable currency strings
// Examples: 1234567 -> "$1.2M", 1234 -> "$1.2K"
const formatNumber = _.memoize((num) => {
  if (num === 0) return '$0';
  if (num >= 1000000000) {
    return `$${(Math.floor((num / 1000000000) * 10) / 10).toFixed(1)}B`;
  }
  if (num >= 1000000) {
    return `$${(Math.floor((num / 1000000) * 10) / 10).toFixed(1)}M`;
  }
  if (num >= 1000) {
    return `$${(Math.floor((num / 1000) * 10) / 10).toFixed(1)}K`;
  }
  return `$${Math.floor(num)}`;
});

const Charts = ({ activeTimeTab, startDate, endDate }) => {
  const theme = useTheme();
  const isMdOrBelow = useMediaQuery(theme.breakpoints.down('md'));
  // Fetch and filter transactions from global store based on date range
  const transactions = useStore(
    store,
    useCallback(
      (state) => {
        const txs = state.transactions;
        const start = new Date(startDate);
        const end = new Date(endDate);
        return txs.filter((tx) => {
          const txDate = new Date(tx.authorized_date);
          return txDate >= start && txDate <= end;
        });
      },
      [startDate, endDate]
    )
  );

  const chartProps = useMemo(
    () => ({
      transactions,
      timePeriod: activeTimeTab,
      startDate,
      endDate,
      formatNumber,
    }),
    [transactions, activeTimeTab, startDate, endDate]
  );

  const styles = useMemo(
    () => ({
      container: {
        mt: -3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        width: isMdOrBelow ? '100vw' : '95vw',
        position: 'relative',
      },
      chartBox: {
        backgroundColor: 'white',
        padding: 1,
        borderRadius: isMdOrBelow ? 0 : 5,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        width: '100%',
        height: 'auto',
      },
      bottomRow: {
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'column',
          md: 'row',
        },
        gap: 2,
        width: '100%',
        mb: 8,
      },
    }),
    [isMdOrBelow]
  );

  return (
    <Box sx={styles.container}>
      <Box sx={styles.chartBox}>
        <Box sx={{ height: '100%', width: '100%' }}>
          <Suspense fallback={<ChartLoader />}>
            <NetChart {...chartProps} />
          </Suspense>
        </Box>
      </Box>

      <Box sx={styles.bottomRow}>
        <Box sx={{ ...styles.chartBox, flex: '1' }}>
          <Suspense fallback={<ChartLoader />}>
            <TransactionChart {...chartProps} />
          </Suspense>
        </Box>
        <Box sx={{ ...styles.chartBox, flex: '1', minHeight: { xs: '500px' } }}>
          <Suspense fallback={<ChartLoader />}>
            <MyPieChart {...chartProps} />
          </Suspense>
        </Box>
      </Box>
      <AiSummary transactions={transactions} type="home" />
    </Box>
  );
};

export default React.memo(Charts);
