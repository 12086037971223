import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useStore } from '@tanstack/react-store';
import { store, updateStore } from '../../../data/store';
import { Stack, Button } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const AiSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 28,
  padding: 8,
  [theme.breakpoints.down('sm')]: {
    width: 45,
    height: 25,
    padding: 6,
  },
  '& .MuiSwitch-switchBase': {
    margin: 0,
    padding: 3,
    transform: 'translateX(5px)',
    [theme.breakpoints.down('sm')]: {
      padding: 2,
      transform: 'translateX(4px)',
    },
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        background: 'linear-gradient(45deg, #ff6482, #5aa8ff)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    background: 'linear-gradient(45deg, #000, #000)',
    width: 20,
    height: 20,
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
  },
}));

export default function ToggleAiSearch({ isMobile }) {
  const enableAiSearch = useStore(store, (state) => state.enableAiSearch);

  if (isMobile) {
    return (
      <Button
        size="small"
        onClick={() => updateStore({ enableAiSearch: !enableAiSearch })}
        startIcon={<AutoAwesomeIcon sx={{ fontSize: '16px' }} />}
        sx={{
          minWidth: 'auto',
          height: '32px',
          mr: 1,
          borderRadius: '8px',
          px: 1.5,
          background: enableAiSearch
            ? 'linear-gradient(135deg, #5aa8ff 0%, #ff6482 100%)'
            : 'transparent',
          color: enableAiSearch ? 'white' : '#666',
          border: enableAiSearch ? 'none' : '1px solid #ddd',
          fontSize: '13px',
          textTransform: 'none',
          fontWeight: 500,
          '&:hover': {
            background: enableAiSearch
              ? 'linear-gradient(135deg, #4997ee 0%, #ee5371 100%)'
              : 'rgba(0, 0, 0, 0.04)',
          },
          '& .MuiSvgIcon-root': {
            background: enableAiSearch
              ? 'none'
              : 'linear-gradient(135deg, #5aa8ff 0%, #ff6482 100%)',
            WebkitBackgroundClip: enableAiSearch ? 'none' : 'text',
            WebkitTextFillColor: enableAiSearch ? 'white' : 'transparent',
          },
        }}
      >
        AI
      </Button>
    );
  }

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <AutoAwesomeIcon
          sx={{
            fill: enableAiSearch ? 'url(#iconGradient)' : 'gray',
            fontSize: { xs: '20px', sm: '20px' },
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: {
              xs: '0.875rem',
              sm: '0.9rem',
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #5aa8ff, #ff6482)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            },
          }}
        >
          AI Search
        </Typography>
        <AiSwitch
          checked={enableAiSearch}
          onChange={() => updateStore({ enableAiSearch: !enableAiSearch })}
          inputProps={{ 'aria-label': 'AI Search Toggle' }}
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              background: 'linear-gradient(45deg, #ff6482, #5aa8ff)',
            },
          }}
        />
      </Stack>
    </FormGroup>
  );
}
