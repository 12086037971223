import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Sort as SortIcon } from '@mui/icons-material';
import {
  addTransaction,
  markTransactionAsRemoved,
  editTransaction,
} from '../../services/api/transactions-service.js';
import { randomId } from '@mui/x-data-grid-generator';
import MobileTrxList from './Mobile Trx List/MobileTrxList';
import TrxModal from './Mobile Trx List/TrxModal';
import ReceiptModal from './ReceiptModal';
import TransactionFilter from './transactionFilter';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { store, updateStore } from '../../../data/store.js';
import { useStore } from '@tanstack/react-store';
import { colors } from '../../../themes/theme.js';
import QuickSearchToolbar from './QuickSearchToolbar.js';
import AiSearchBar from '../AiSearch';
import MobileFloatingActionMenu from './MobileFloatingActionButton.js';
import TransactionsMetricsDisplay from './TransactionsMetricsDisplay.js';
import AiSummary from '../AiSummary/index.js';

const MobileTransactionsPage = ({
  rows,
  accounts,
  setOpenAlert,
  setAlertSeverity,
  setAlertMessage,
  categories,
  timePeriod,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate,
  fetchTransactions,
  transactionCategoryColorMap,
  onFilterChange,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSort, setShowSort] = useState(false);
  const [aiSearchResults, setAiSearchResults] = useState(null);
  const {
    isReceiptModalOpen,
    enableAiSearch,
    editingTransactionId,
    isAddingNewTransaction,
    rowEditCount,
    currentEditData,
    sortField,
    sortDirection,
  } = useStore(store, (state) => ({
    isReceiptModalOpen: state.isReceiptModalOpen,
    enableAiSearch: state.enableAiSearch,
    editingTransactionId: state.editingTransactionId,
    isAddingNewTransaction: state.isAddingNewTransaction,
    rowEditCount: state.rowEditCount || 0, // provide default value
    currentEditData: state.currentEditData,
    sortField: state.sortField,
    sortDirection: state.sortDirection,
  }));

  const transactionType = useStore(store, (state) => state.transactionType);

  // Handle search filter
  const handleSearch = (value) => {
    setSearchTerm(value);
    setAiSearchResults(null);
    if (onFilterChange) {
      onFilterChange(value);
    }
  };

  // Add a new handler for AI search
  const handleAiSearch = (results) => {
    setAiSearchResults(results);
    // Clear the regular search term when using AI search
    setSearchTerm('');
  };

  useEffect(() => {
    if (editingTransactionId && !modalOpen) {
      const transaction = rows.find((t) => t.id === editingTransactionId);
      if (transaction) {
        setCurrentTransaction(transaction);
        setModalOpen(true);
      }
    }
  }, [editingTransactionId, rows, modalOpen]);

  const handleEdit = (id) => {
    const transaction = rows.find((t) => t.id === id);
    setCurrentTransaction(transaction);
    setModalOpen(true);
    updateStore({
      editingTransactionId: id,
      currentEditData: transaction,
      rowEditCount: 1,
    });
  };

  const handleModalClose = () => {
    if (isAddingNewTransaction) {
      const filteredRows = rows.filter((row) => !row.isNew);

      updateStore({
        rows: filteredRows,
        editingTransactionId: null,
        currentEditData: null,
        rowEditCount: 0,
        isAddingNewTransaction: false,
      });
    } else {
      updateStore({
        editingTransactionId: null,
        currentEditData: null,
        rowEditCount: 0,
        isAddingNewTransaction: false,
      });
    }

    setModalOpen(false);
    setCurrentTransaction(null);
  };

  const handleAddClick = () => {
    const newId = randomId();
    // Use the current exact time instead of just the date
    const now = new Date();
    const utcDate = dayjs(now).utc().format(); // This includes time

    const newTransaction = {
      id: newId,
      date: utcDate,
      description: '',
      account: 'Other',
      category: '',
      amount: 0,
      isNew: true,
    };

    updateStore({
      isAddingNewTransaction: true,
      rows: [newTransaction, ...rows],
      editingTransactionId: newId,
      currentEditData: newTransaction,
      rowEditCount: (rowEditCount || 0) + 1,
    });

    setModalOpen(true);
  };

  const categoryGroups = useMemo(
    () => ({
      income: [
        {
          title: 'INCOME',
          categories: [
            'Sales',
            'Shipping & Delivery Income',
            'Other Income',
            'Uncategorized Income',
          ],
        },
      ],
      expense: [
        {
          title: 'OPERATING EXPENSES',
          categories: [
            'Accounting Fees',
            'Advertising & Promotion',
            'Bank Service Charges',
            'Business Licenses & Permits',
            'Computer - Hardware',
            'Computer - Hosting',
            'Computer - Internet',
            'Computer - Software',
            'Dues & Subscriptions',
            'Event Fees',
            'Insurance - Vehicles',
            'Meals and Entertainment',
            'Office Supplies',
            'Professional Fees',
            'Payroll - Salary & Wages',
            'Rent Expense',
            'Repairs & Maintenance',
            'Telephone - Land Line',
            'Travel Expense',
            'Utilities',
            'Vehicle - Fuel',
            'Vehicle - Repairs & Maintenance',
            'Taxes',
            'Uncategorized Expense',
          ],
        },
        {
          title: 'COST OF GOODS',
          categories: [
            'Food Cost',
            'Product Samples',
            'Purchases - Parts & Materials',
            'Purchases - Resale Items',
          ],
        },
      ],
    }),
    []
  );

  const handleSave = async (formData) => {
    try {
      const isEditOperation = currentTransaction !== null;

      const category = categories.allCategories.find(
        (cat) => cat.value === formData.category
      );

      // Adjust amount sign based on category type
      let amount = parseFloat(formData.amount);

      // Check if it's an income or expense category
      const isIncomeCategory = categories.incomeCategories.some(
        (cat) => cat.value === formData.category
      );

      const isExpenseCategory = categories.expenseCategories.some(
        (cat) => cat.value === formData.category
      );

      // Adjust amount sign based on category type
      if (isIncomeCategory && amount < 0) {
        amount = Math.abs(amount);
      } else if (isExpenseCategory && amount > 0) {
        amount = -Math.abs(amount);
      }

      if (isEditOperation) {
        if (!currentTransaction || !currentTransaction.id) {
          console.error(
            'Missing transaction ID for edit operation',
            currentTransaction
          );
          setAlertSeverity('error');
          setAlertMessage('Cannot edit transaction: Missing ID');
          setOpenAlert(true);
          return;
        }

        // Ensure date is in proper ISO-8601 format
        let dateString;
        if (typeof formData.date === 'string') {
          // Make sure the string is a valid ISO format
          try {
            dateString = dayjs(formData.date).toISOString();
          } catch (e) {
            console.error('Invalid date string:', formData.date, e);
            dateString = dayjs().toISOString(); // Fallback to current date
          }
        } else if (formData.date.toISOString) {
          dateString = formData.date.toISOString();
        } else {
          dateString = dayjs(formData.date).toISOString();
        }

        const editedTransactionData = {
          date: dateString,
          authorized_date: dateString, // Ensure ISO format
          name: formData.description,
          description: formData.description,
          category: formData.category,
          category_id: category?.id,
          amount: amount,
          notes: formData.notes,
          account: formData.account,
        };

        try {
          const response = await editTransaction(
            currentTransaction.id,
            editedTransactionData
          );

          if (response.status === 200) {
            handleModalClose();

            setAlertSeverity('success');
            setAlertMessage('Transaction successfully modified.');

            await fetchTransactions({
              background: false,
              invalidate: true,
              forceRefresh: true,
            });

            setOpenAlert(true);
          }
        } catch (error) {
          console.error('Error editing transaction:', error);
          setAlertSeverity('error');
          setAlertMessage('Unable to update transaction. Please try again.');
          setOpenAlert(true);
        }
      } else {
        // Ensure date is in proper ISO-8601 format for new transactions
        let dateString;
        if (typeof formData.date === 'string') {
          // Make sure the string is a valid ISO format
          try {
            dateString = dayjs(formData.date).toISOString();
          } catch (e) {
            console.error('Invalid date string:', formData.date, e);
            dateString = dayjs().toISOString(); // Fallback to current date
          }
        } else if (formData.date.toISOString) {
          dateString = formData.date.toISOString();
        } else {
          dateString = dayjs(formData.date).toISOString();
        }

        const newTransactionData = {
          date: dateString,
          authorized_date: dateString, // Ensure ISO format
          description: formData.description,
          name: formData.description,
          category: formData.category,
          category_id: category?.id,
          amount: amount,
          notes: formData.notes,
          account: formData.account,
        };

        try {
          const response = await addTransaction(newTransactionData);

          if (response.status === 200) {
            handleModalClose();

            setAlertSeverity('success');
            setAlertMessage('Transaction successfully added.');

            await fetchTransactions({
              background: false,
              invalidate: true,
              forceRefresh: true,
            });

            setOpenAlert(true);
          }
        } catch (error) {
          console.error('Error adding transaction:', error);
          setAlertSeverity('error');
          setAlertMessage('Unable to add transaction. Please try again.');
          setOpenAlert(true);
        }
      }
    } catch (error) {
      console.error('Error in transaction handling:', error);
      setAlertSeverity('error');
      setAlertMessage('An unexpected error occurred. Please try again.');
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    if (isAddingNewTransaction && currentEditData && !modalOpen) {
      setCurrentTransaction(currentEditData);
      setModalOpen(true);
    }
  }, [isAddingNewTransaction, currentEditData, modalOpen]);

  const handleDelete = async (id) => {
    try {
      await markTransactionAsRemoved(id);
      await fetchTransactions({ background: true, invalidate: true });
      setAlertSeverity('success');
      setAlertMessage('Transaction successfully removed.');
      setOpenAlert(true);
    } catch (error) {
      setAlertSeverity('error');
      setAlertMessage('Unable to remove transaction. Please try again.');
      setOpenAlert(true);
    }
  };
  const handleUploadReceipt = (id) => {
    const transaction = rows.find((row) => row.id === id);
    const receiptUrl = transaction?.receiptUrl || null;
    updateStore({
      currentRowId: id,
      currentReceiptUrl: receiptUrl,
      isReceiptModalOpen: true,
    });
  };

  const handleSortChange = (event) => {
    updateStore({ sortField: event.target.value });
  };

  const handleSortDirectionChange = (event, newDirection) => {
    if (newDirection !== null) {
      updateStore({ sortDirection: newDirection });
    }
  };

  const filteredAndSortedRows = useMemo(() => {
    // Ensure initial rows is an array
    if (!Array.isArray(rows)) {
      console.warn('rows is not an array:', rows);
      return [];
    }

    if (aiSearchResults) {
      if (!Array.isArray(aiSearchResults)) {
        console.warn('aiSearchResults is not an array:', aiSearchResults);
        return [...rows];
      }
      return aiSearchResults;
    }

    let result = [...rows];
    // Rest of your filtering logic remains the same
    if (searchTerm) {
      result = result.filter((row) => {
        const searchFields = [
          row.description,
          row.category,
          row.amount?.toString(),
          dayjs(row.date).format('MMM DD, YYYY'),
          row.account_name,
          row.notes,
        ].filter(Boolean);

        const searchString = searchFields.join(' ').toLowerCase();
        return searchString.includes(searchTerm.toLowerCase());
      });
    }

    // Filter by time period
    result = result.filter((row) => {
      // Always include new transactions regardless of date filter
      if (row.isNew) return true;

      const rowDate = dayjs(row.date);
      switch (timePeriod) {
        case 'week':
          return selectedWeeks.some((week) => {
            const [start, end] = week.split(' - ');
            return rowDate.isBetween(dayjs(start), dayjs(end), null, '[]');
          });
        case 'month':
          return selectedMonths.includes(rowDate.format('MMMM YYYY'));
        case 'quarter':
          return selectedQuarters.includes(
            `Q${rowDate.quarter()} ${rowDate.year()}`
          );
        case 'year':
          return selectedYears.includes(rowDate.year().toString());
        case 'all':
          // For "all" mode, ensure we're using the full day range
          const start = dayjs(startDate).startOf('day');
          const end = dayjs(endDate).endOf('day');
          return rowDate.isBetween(start, end, null, '[]');
        default:
          return true;
      }
    });

    // Filter by transactionType
    if (transactionType === 'income') {
      result = result.filter((row) => row.amount >= 0);
    } else if (transactionType === 'expenses') {
      result = result.filter((row) => row.amount < 0);
    }

    //Sort
    result.sort((a, b) => {
      let aValue = a[sortField];
      let bValue = b[sortField];

      if (sortField === 'date') {
        aValue = dayjs(aValue);
        bValue = dayjs(bValue);
      } else if (sortField === 'amount') {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    return result;
  }, [
    rows,
    searchTerm,
    aiSearchResults,
    timePeriod,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
    transactionType,
    sortField,
    sortDirection,
  ]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TransactionsMetricsDisplay />
      <Box
        sx={{
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: colors.gray,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
        }}
      >
        {/* Search Section */}
        <Box
          sx={{
            width: '100%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.gray,
            borderRadius: 10,
          }}
        >
          <Box sx={{ p: 1 }}>
            {enableAiSearch ? (
              <AiSearchBar
                fetchTransactions={fetchTransactions}
                onFilterChange={handleAiSearch}
                isEditMode={false}
                isMobile={true}
              />
            ) : (
              <QuickSearchToolbar
                fetchTransactions={fetchTransactions}
                onFilterChange={handleSearch}
                value={searchTerm}
                isMobile={true}
                isEditMode={false}
              />
            )}
          </Box>
        </Box>
      </Box>

      {/* Second Box: Transactions List */}
      <Box
        sx={{
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.paper',
          height: `calc(100vh - ${rows.length > 0 ? Math.min(rows.length * 80, 100) + 'px' : '100px'})`,
          maxHeight: '800px',
          overflow: 'hidden',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
        }}
      >
        {/* Header Section with Title and Filter */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
            py: 1.5,
            backgroundColor: colors.shadow,
            color: 'Black',
            border: '1px solid rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="h6">Transactions</Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TransactionFilter />
            <IconButton color="inherit" onClick={() => setShowSort(!showSort)}>
              <SortIcon />
            </IconButton>
          </Box>
        </Box>
        {/* Sort Options */}
        {showSort && (
          <Box
            sx={{
              p: 2,
              backgroundColor: 'background.paper',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            }}
          >
            <TextField
              select
              fullWidth
              value={sortField}
              onChange={handleSortChange}
              label="Sort by"
              sx={{ mb: 2 }}
            >
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="description">Description</MenuItem>
              <MenuItem value="amount">Amount</MenuItem>
            </TextField>
            <ToggleButtonGroup
              value={sortDirection}
              exclusive
              onChange={handleSortDirectionChange}
              aria-label="Sort direction"
              fullWidth
              sx={{
                gap: 2,
                '& .MuiToggleButton-root': {
                  borderRadius: '100px',
                  backgroundColor: colors.primary,
                  color: 'white',
                  textTransform: 'capitalize',
                  '&.Mui-selected': {
                    backgroundColor: colors.secondary,
                    color: 'white',
                  },
                },
              }}
            >
              <ToggleButton value="asc" aria-label="Ascending">
                Ascending
              </ToggleButton>
              <ToggleButton value="desc" aria-label="Descending">
                Descending
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <MobileTrxList
            transactions={filteredAndSortedRows}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onUploadReceipt={handleUploadReceipt}
            transactionCategoryColorMap={transactionCategoryColorMap}
          />
        </Box>

        {/* Floating Action Button */}
        <MobileFloatingActionMenu onAddTransaction={handleAddClick} />

        {/* Modals */}
        <TrxModal
          open={modalOpen}
          handleClose={handleModalClose}
          transaction={currentTransaction}
          onSave={handleSave}
          accounts={accounts}
          categories={categories}
          categoryGroups={categoryGroups}
          transactionCategoryColorMap={transactionCategoryColorMap}
        />
        <AiSummary transactions={rows} />

        {isReceiptModalOpen && (
          <ReceiptModal
            open={isReceiptModalOpen}
            fetchTransactions={fetchTransactions}
          />
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default MobileTransactionsPage;
