import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { SDataGrid } from '../../styles/style';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { ResponsiveSalesColumns } from './columns';
import MobileSalesPage from './mobileSalesPage';
import { useStore } from '@tanstack/react-store';
import { store, updateStore } from '../../../data/store';
import SalesEditToolbar from './SalesEditToolbar';
import SalesQuickSearchToolbar from './SalesQuickSearchToolbar';
import searchSalesTransactions from '../../../utils/salesSearch';
import { colors } from '../../../themes/theme';

// Extend dayjs with required plugins
dayjs.extend(isBetween);
dayjs.extend(utc);

const SalesDataGrid = ({
  rows = [],
  accounts = [],
  fetchSales,
  categories = [],
  timePeriod = 'all',
  startDate,
  endDate,
  transactionCategoryColorMap = {},
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Store searchTerm in localStorage to persist between view changes
  const [searchTerm, setSearchTerm] = useState(() => {
    const savedSearch = localStorage.getItem('salesSearchTerm');
    return savedSearch || '';
  });

  // Update localStorage when searchTerm changes
  useEffect(() => {
    localStorage.setItem('salesSearchTerm', searchTerm);
  }, [searchTerm]);

  // Handle storage events to sync searchTerm across tabs/views
  useEffect(() => {
    const handleStorageChange = () => {
      const savedSearch = localStorage.getItem('salesSearchTerm') || '';
      if (savedSearch !== searchTerm) {
        setSearchTerm(savedSearch);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [searchTerm]);

  // Sync searchTerm when view changes (mobile/desktop toggle)
  useEffect(() => {
    const savedSearch = localStorage.getItem('salesSearchTerm');
    if (savedSearch && savedSearch !== searchTerm) {
      setSearchTerm(savedSearch);
    }
  }, [isMobile, isDesktop, searchTerm]);

  // Get transaction type filter from global store
  const salesTransactionType = useStore(
    store,
    (state) => state.salesTransactionType || 'All'
  );

  // Generate default category colors if not provided in props
  const defaultCategoryColors = useMemo(() => {
    const defaultColors = {};
    categories.forEach((category, index) => {
      if (!transactionCategoryColorMap[category]) {
        defaultColors[category] =
          colors.categoryDefaultColors[
            index % colors.categoryDefaultColors.length
          ];
      }
    });
    return { ...defaultColors, ...transactionCategoryColorMap };
  }, [categories, transactionCategoryColorMap]);

  // Handle search without transitions
  const handleSearch = useCallback((value) => {
    setSearchTerm(value);
    localStorage.setItem('salesSearchTerm', value);
  }, []);

  // Update transaction type filter in global store
  const handleFilterChange = useCallback((newFilterState) => {
    updateStore({ salesTransactionType: newFilterState });
  }, []);

  // Filter rows based on search, date range, and transaction type
  const filteredRows = useMemo(() => {
    let result = [...rows];

    // Apply date range filter if explicit dates are provided
    if (startDate && endDate) {
      const start = dayjs(startDate);
      const end = dayjs(endDate);

      result = result.filter((row) => {
        if (!row.date) return false;
        const rowDate = dayjs(row.date);
        return (
          rowDate.isAfter(start.subtract(1, 'day')) &&
          rowDate.isBefore(end.add(1, 'day'))
        );
      });
    } else if (timePeriod && timePeriod !== 'all') {
      // Apply relative time period filter when no explicit dates
      const today = dayjs();
      let periodStart;

      switch (timePeriod) {
        case '7days':
          periodStart = today.subtract(6, 'day');
          break;
        case '30days':
          periodStart = today.subtract(29, 'day');
          break;
        case '90days':
          periodStart = today.subtract(89, 'day');
          break;
        case '365days':
          periodStart = today.subtract(364, 'day');
          break;
        default:
          periodStart = null;
      }

      if (periodStart) {
        result = result.filter((row) => {
          if (!row.date) return false;
          const rowDate = dayjs(row.date);
          return (
            rowDate.isAfter(periodStart.subtract(1, 'day')) &&
            rowDate.isBefore(today.add(1, 'day'))
          );
        });
      }
    }

    // Apply transaction type filter (Income/Expenses)
    if (salesTransactionType && salesTransactionType !== 'All') {
      if (salesTransactionType === 'Income') {
        result = result.filter((row) => row.amount >= 0);
      } else if (salesTransactionType === 'Expenses') {
        result = result.filter((row) => row.amount < 0);
      }
    }

    // Apply search term filter using utility function
    if (searchTerm) {
      result = searchSalesTransactions(result, searchTerm);
    }

    return result;
  }, [rows, searchTerm, timePeriod, startDate, endDate, salesTransactionType]);

  // Render mobile view for small screens
  if (isMobile) {
    return (
      <MobileSalesPage
        rows={filteredRows}
        searchTerm={searchTerm}
        setSearchTerm={handleSearch}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  // Render desktop/tablet view with responsive columns
  return (
    <ResponsiveSalesColumns defaultCategoryColors={defaultCategoryColors}>
      {(columns) => (
        <Box
          sx={{
            width: isMediumScreen ? '100vw' : '95vw',
            height: '100vh',
            backgroundColor: colors.white,
            borderRadius: isMediumScreen ? 0 : 5,
            overflow: 'hidden',
            boxShadow: `0 4px 12px ${colors.salesGridBoxShadow}`,
            marginBottom: 3,
          }}
        >
          <SDataGrid
            rows={filteredRows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50]}
            disableRowSelectionOnClick
            components={{
              Toolbar: () => (
                <>
                  <SalesEditToolbar
                    onFilterChange={handleFilterChange}
                    isEditMode={false}
                  />
                  <Box sx={{ px: 3, pb: 2 }}>
                    <SalesQuickSearchToolbar
                      fetchTransactions={fetchSales}
                      onFilterChange={handleSearch}
                      value={searchTerm}
                      isEditMode={false}
                      isMobile={isMobile}
                    />
                  </Box>
                </>
              ),
            }}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: colors.salesGridHeaderBg,
              },
              '& .MuiDataGrid-cell:hover': {
                color: 'inherit',
              },
              '& .positive-amount': {
                color: colors.income,
              },
              '& .negative-amount': {
                color: colors.expenses,
              },
              border: 'none',
              '& .MuiDataGrid-cell': {
                borderBottom: `1px solid ${colors.salesGridBorderColor}`,
                padding: '8px 16px',
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              padding: '12px',
              '& .MuiDataGrid-virtualScroller': {
                overflow: 'auto',
              },
            }}
          />
        </Box>
      )}
    </ResponsiveSalesColumns>
  );
};

export default SalesDataGrid;
