import { styled, Typography, Box } from "@mui/material";
import { colors } from "../../../themes/theme";

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: 600,
  color: colors.textPrimary,
  marginBottom: theme.spacing(1),
  fontFamily: "SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif"
}));

const StyledContent = styled(Typography)(({ theme }) => ({
  fontSize: "1.125rem",
  fontWeight: 500,
  color: colors.textSecondary,
  lineHeight: 1.6,
  fontFamily: "SF Pro Text, -apple-system, BlinkMacSystemFont, sans-serif"
}));

const SubTitleWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginBottom: "18px",
  marginLeft: "10px"
});

const SubTitle = styled(Typography)({
  fontSize: "1.1rem",
  fontWeight: 400,
  color: colors.textSecondary,
  fontFamily: "SF Pro Text, -apple-system, BlinkMacSystemFont, sans-serif"
});

const SectionBox = styled(Box)(({ bgColor }) => ({
  padding: "24px",
  marginBottom: "16px",
  borderRadius: "12px",
  backgroundColor: bgColor,
  textTransform: "capitalize"
}));

const TitleWithIcon = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px"
});

const StyledList = styled("ul")({
  listStyle: "disc",
  paddingLeft: "20px",
  margin: "8px 0",
  "& li": {
    padding: "8px 0",
    fontSize: "1.125rem",
    color: colors.textSecondary
  }
});

export { StyledTitle, StyledContent, SubTitleWrapper, SubTitle, SectionBox, TitleWithIcon, StyledList };