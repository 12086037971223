/**
 * Format a number to a maximum of 6 significant figures
 * For large numbers, converts to abbreviated format (M)
 * Ensures no values exceed 6 significant figures
 *
 * @param {string|number} value - The number to format (can be a string like "$1,234.56")
 * @param {boolean} isCurrency - Whether the value is currency (prepends $ if true)
 * @returns {string} - The formatted value
 */
export const formatToSixSigFigs = (value, isCurrency = false) => {
  // Handle null or undefined values
  if (value == null) return isCurrency ? '$0' : '0';

  // Extract numeric value if it's a currency string
  let numericValue;
  if (typeof value === 'string') {
    // Remove currency symbol, commas, and other non-numeric characters except decimal point
    numericValue = parseFloat(value.replace(/[$,\s]/g, ''));
  } else {
    numericValue = parseFloat(value);
  }

  // Check if it's a valid number
  if (isNaN(numericValue)) return isCurrency ? '$0' : '0';

  // Format the number based on its magnitude
  let formattedValue;

  if (Math.abs(numericValue) >= 1000000000) {
    // Format as billions (B)
    formattedValue = (numericValue / 1000000000).toFixed(1);
    // Remove trailing zeros after decimal point
    formattedValue = parseFloat(formattedValue).toString() + 'B';
  } else if (Math.abs(numericValue) >= 1000000) {
    // Format as millions (M)
    formattedValue = (numericValue / 1000000).toFixed(1);
    // Remove trailing zero if it's .0
    formattedValue = formattedValue.endsWith('.0')
      ? Math.floor(numericValue / 1000000).toString() + 'M'
      : parseFloat(formattedValue).toString() + 'M';
  } else if (Math.abs(numericValue) >= 10000) {
    // Format 10,000+ as whole numbers with comma separators
    formattedValue = Math.round(numericValue).toLocaleString();
  } else if (Math.abs(numericValue) >= 100) {
    // Format 100-9,999 with at most 2 decimal places
    formattedValue = numericValue.toFixed(2);
    // Remove trailing zeros
    formattedValue = parseFloat(formattedValue).toLocaleString();
  } else {
    // For smaller values (< 100), allow up to 2 decimal places
    // The original had separate conditions for 10-99 and 0-9.99, but the logic was identical
    formattedValue = numericValue.toFixed(2);
    // Remove trailing zeros
    formattedValue = parseFloat(formattedValue).toString();
  }

  // Add currency symbol if needed
  return isCurrency ? '$' + formattedValue : formattedValue;
};

/**
 * Format percentage changes to a sensible precision
 *
 * @param {string|number} percentValue - The percentage to format (can include % sign)
 * @returns {string} - The formatted percentage
 */
export const formatPercentChange = (percentValue) => {
  if (percentValue == null) return '0%';

  // Extract numeric value if it's a string with % symbol
  let numericValue;
  if (typeof percentValue === 'string') {
    numericValue = parseFloat(percentValue.replace(/%/g, ''));
  } else {
    numericValue = parseFloat(percentValue);
  }

  // Check if it's a valid number
  if (isNaN(numericValue)) return '0%';

  // Format to a whole number by rounding
  const formattedPercent = Math.round(numericValue);

  // Add % symbol
  return formattedPercent + '%';
};
