import React, { useRef, useEffect, useCallback } from 'react';
import { Box, TextField } from '@mui/material';
import { SGridToolbarQuickFilter } from '../../styles/style.js';
import { Clear as ClearIcon } from '@mui/icons-material';
import { colors } from '../../../themes/theme';

function SalesQuickSearchToolbar({
  fetchTransactions,
  onFilterChange,
  value = '',
  isEditMode,
  isMobile,
}) {
  const inputRef = useRef(null);

  // Get the appropriate localStorage key based on viewport
  // Memoized to prevent unnecessary effect triggers
  const getStorageKey = useCallback(
    () => (isMobile ? 'salesSearchTermMobile' : 'salesSearchTermDesktop'),
    [isMobile]
  );

  // Initialize search value from localStorage on component mount
  useEffect(() => {
    const storageKey = getStorageKey();
    const storedValue = localStorage.getItem(storageKey) || '';
    if (storedValue !== value && onFilterChange) {
      onFilterChange(storedValue);
    }
  }, [isMobile, onFilterChange, value, getStorageKey]);

  // Ensure input scrolls to end for better UX
  useEffect(() => {
    const input = inputRef.current?.querySelector('input');
    if (input) {
      const scrollToEnd = () => {
        input.scrollLeft = input.scrollWidth;
      };
      input.addEventListener('input', scrollToEnd);
      return () => input.removeEventListener('input', scrollToEnd);
    }
    return undefined; // Return a function or undefined for consistent cleanup
  }, []);

  // Handle clearing the search input
  const handleClear = useCallback(() => {
    if (isEditMode) return;

    if (onFilterChange) {
      onFilterChange('');
      localStorage.setItem(getStorageKey(), '');
    }

    if (fetchTransactions) {
      fetchTransactions({ background: true });
    }
  }, [isEditMode, onFilterChange, getStorageKey, fetchTransactions]);

  // Handle search input changes
  const handleInputChange = useCallback(
    (event) => {
      if (isEditMode) return;

      const newValue = event.target.value;

      if (onFilterChange) {
        onFilterChange(newValue);
        localStorage.setItem(getStorageKey(), newValue);
      }
    },
    [isEditMode, onFilterChange, getStorageKey]
  );

  // Desktop version with custom scrollable input field
  if (!isMobile) {
    return (
      <Box sx={{ width: '100%', padding: '0', mb: 1 }}>
        <Box
          ref={inputRef}
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            opacity: isEditMode ? 0.6 : 1,
            pointerEvents: isEditMode ? 'none' : 'auto',
            '& .MuiInputBase-root': {
              paddingRight: '60px',
              height: 'auto',
              minHeight: '40px',
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiInputBase-input': {
              whiteSpace: 'nowrap',
              overflow: 'auto',
              textOverflow: 'ellipsis',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              maxWidth: 'calc(100% - 60px)',
              '&:focus': {
                outline: 'none',
              },
            },
          }}
        >
          <SGridToolbarQuickFilter
            disabled={isEditMode}
            quickFilterProps={{
              placeholder: 'Search sales transactions...',
              variant: 'standard',
              multiline: false,
              disabled: isEditMode,
              value: value,
              InputProps: {
                disableUnderline: true,
                onChange: handleInputChange,
                readOnly: isEditMode,
              },
            }}
            sx={{
              width: '100%',
              '& .MuiInput-underline:before': {
                display: 'none',
              },
              '& .MuiInput-underline:after': {
                display: 'none',
              },
              '& .Mui-disabled': {
                cursor: 'not-allowed',
                WebkitTextFillColor: colors.disabledSearchText,
                '&::placeholder': {
                  opacity: 0.5,
                },
              },
            }}
          />
          {/* Clear button container with gradient icon */}
          <Box
            sx={{
              position: 'absolute',
              right: '16px',
              top: '50%',
              transform: 'translateY(-50%)',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              backgroundColor: colors.white,
              zIndex: 1,
              borderLeft: `8px solid ${colors.white}`,
              pointerEvents: isEditMode ? 'none' : 'auto',
            }}
          >
            {value && (
              <Box
                onClick={handleClear}
                sx={{
                  cursor: isEditMode ? 'not-allowed' : 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    opacity: isEditMode ? 1 : 0.7,
                  },
                  '&:active': {
                    transform: 'scale(0.95)',
                  },
                }}
              >
                <ClearIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                    fill: 'url(#iconGradient)',
                  }}
                />
              </Box>
            )}

            {/* Note: AI search feature will be implemented in the future */}
          </Box>
        </Box>
      </Box>
    );
  }

  // Mobile version with pulsing highlight animation
  return (
    <Box sx={{ width: '100%', padding: '0' }}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: colors.white,
          borderRadius: '12px',
          border: '0.3px solid',
          opacity: isEditMode ? 0.6 : 1,
          pointerEvents: isEditMode ? 'none' : 'auto',
          height: '48px',
          '&:hover': {
            border: `1px solid ${colors.searchBorderHover}`,
          },
          '&:focus-within': {
            border: `1px solid ${colors.searchBorderFocus}`,
            boxShadow: `0 0 0 4px ${colors.searchShadowFocus}`,
          },
          animation: value ? 'none' : 'pulseHighlight 2s ease infinite',
          '@keyframes pulseHighlight': {
            '0%': { boxShadow: '0 0 0 0 rgba(90, 168, 255, 0)' },
            '50%': { boxShadow: `0 0 0 4px ${colors.searchPulseColor}` },
            '100%': { boxShadow: '0 0 0 0 rgba(90, 168, 255, 0)' },
          },
        }}
      >
        <TextField
          fullWidth
          variant="standard"
          placeholder="Search sales transactions..."
          value={value}
          onChange={handleInputChange}
          disabled={isEditMode}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            ml: 1,
            mr: 1,
            flex: 1,
            '& .MuiInputBase-root': {
              height: '100%',
              fontSize: '14px',
              color: colors.searchText,
              backgroundColor: colors.transparent,
            },
            '& .MuiInputBase-input': {
              padding: '8px 0',
              '&::placeholder': {
                color: colors.searchPlaceholderText,
                opacity: 1,
              },
            },
          }}
        />

        {/* Note: AI search button will be implemented in the future */}
      </Box>
    </Box>
  );
}

export default SalesQuickSearchToolbar;
