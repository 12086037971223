import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Typography,
  Snackbar,
  CircularProgress,
  Divider,
  Skeleton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Alert } from '@mui/material';
import { AuthContext } from '../../../../../components/common/contexts/authContext';
import axiosInstance from '../../../../../api/axiosWrapper';
import NotificationSnackbar from './NotificationSnackbar';
import BankAccountCard from './BankAccountCard';
import LinkBankAccountModal from '../../../../../components/common/LinkBankAccountModal';
import ConfirmationDialog from './ConfirmationDialog';
import { colors } from '../../../../../themes/theme';
import { TRANSACTIONS_QUERY_KEY } from '../../../../../data/constants';
import { useQueryClient } from '@tanstack/react-query';
import { getPlaidItemsForUser } from '../../../../../components/services/api/plaid-service';

const BankAccountInfo = () => {
  const { currentUser, isLoggedIn } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const [bankAccounts, setBankAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [modalOpen, setModalOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [reauthItems, setReauthItems] = useState([]);
  const [currentLinkToken, setCurrentLinkToken] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [itemName, setItemName] = useState('');
  const [confirmationAction, setConfirmationAction] = useState('');

  // dialog state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [verificationText, setVerificationText] = useState('');

useEffect(() => {
  const fetchBankAccounts = async () => {
    if (currentUser && isLoggedIn) {
      setLoading(true);
      try {
        const { items, reauthItems, hasReauthItems, linkToken } =
          await getPlaidItemsForUser();

        setBankAccounts(items);
        setReauthItems(reauthItems);

        if (hasReauthItems) {
          setCurrentLinkToken(linkToken);
          setOpenAlert(true);
          setModalOpen(true);
        }
      } catch (error) {
        console.error('Error fetching bank accounts:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  fetchBankAccounts();
}, [currentUser, isLoggedIn]);

  const updateAccountState = (itemId, accountId, updatedAccount) => {
    setBankAccounts((prevAccounts) =>
      prevAccounts.map((bank) =>
        bank.id === itemId
          ? {
              ...bank,
              accounts: bank.accounts.map((account) =>
                account.id === accountId
                  ? { ...account, ...updatedAccount }
                  : account
              ),
            }
          : bank
      )
    );
  };

  const handleSaveCustomName = async (itemId, accountId, newCustomName) => {
    try {
      updateAccountState(itemId, accountId, { custom_name: newCustomName });

      await axiosInstance.patch(`/accounts/${accountId}/custom-name`, {
        customName: newCustomName,
      });

      setSnackbarMessage('Account name updated successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Failed to save custom name:', error);
      setSnackbarMessage('Failed to update account name. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    queryClient.invalidateQueries({ queryKey: TRANSACTIONS_QUERY_KEY }); // Refresh transactions
  };
  const handleLinkNewBank = () => setModalOpen(true);
  const handleAlertClose = () => setOpenAlert(false);
  const handleSnackbarClose = () => setOpenSnackbar(false);

  const handleOpenDialog = (
    type,
    accountId,
    itemId,
    name,
    action = 'unlink'
  ) => {
    setDialogType(type);
    setSelectedAccountId(accountId);
    setSelectedItemId(itemId);
    setItemName(name);
    setConfirmationAction(action);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType('');
    setVerificationText('');
  };

  const handleToggleAccount = (accountId, itemId, accountName, isActive) => {
    const action = isActive ? 'deactivate' : 'activate';
    handleOpenDialog('account', accountId, itemId, accountName, action);
  };

  const toggleAccount = async (accountId, itemId, accountName, activate) => {
    try {
      updateAccountState(itemId, accountId, { is_active: activate });

      await axiosInstance.post('/deactivateAccount', {
        accountId,
        itemId,
        activate,
      });

      setSnackbarMessage(
        activate
          ? `Account "${accountName}" reactivated successfully!`
          : `Account "${accountName}" deactivated successfully!`
      );
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

      await queryClient.invalidateQueries({ queryKey: TRANSACTIONS_QUERY_KEY });
      await queryClient.refetchQueries({ queryKey: TRANSACTIONS_QUERY_KEY });
    } catch (error) {
      console.error('Failed to toggle account state:', error);
      setSnackbarMessage('Failed to toggle account state. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };
  const handleUnlinkItem = async (itemId, itemName) => {
    try {
      await axiosInstance.post('/deactivatePlaidItem', { itemId });

      setBankAccounts((prevAccounts) =>
        prevAccounts.filter((bank) => bank.id !== itemId)
      );

      setSnackbarMessage(`Bank item "${itemName}" unlinked successfully!`);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

      // Clear cache
      await queryClient.invalidateQueries({ queryKey: TRANSACTIONS_QUERY_KEY });
      await queryClient.refetchQueries({ queryKey: TRANSACTIONS_QUERY_KEY });
    } catch (error) {
      console.error('Error unlinking bank item:', error);
      setSnackbarMessage('Failed to unlink bank item. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleConfirmRemove = async () => {
    if (dialogType === 'account') {
      const activate = confirmationAction === 'activate';
      await toggleAccount(
        selectedAccountId,
        selectedItemId,
        itemName,
        activate
      );
    } else if (dialogType === 'item') {
      await handleUnlinkItem(selectedItemId, itemName);
    }
    handleCloseDialog();
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
          mr: {xs:1.23, sm:0},
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '18px', sm: '26px' },
          }}
        >
          Linked Accounts
        </Typography>
        <Button
          variant="contained"
          onClick={handleLinkNewBank}
          disabled={loading}
          startIcon={<AddIcon />}
          sx={{
            borderRadius: '20px',
            backgroundColor: colors.primary,
            color: colors.white,
            textTransform: 'none',
            '&:hover': { backgroundColor: colors.primaryHover },
            fontSize: { xs: '12px', sm: '16px' },
            padding: { xs: '4px 8px', sm: '8px 16px' },
          }}
        >
          Add Account
        </Button>
      </Box>
      {reauthItems.length > 0 && (
        <NotificationSnackbar
          open={openAlert}
          message="Some of your accounts require re-authentication."
          onClose={handleAlertClose}
          severity="warning"
        />
      )}
      <Divider sx={{ width: '100%', mb: 2 }} />
      {loading ? (
        <Box sx={{ textAlign: 'center', width: '100%' }}>
          <CircularProgress />
          <Box mt={2} sx={{ width: '100%' }}>
            {[1, 2, 3].map((i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                height={120}
                width="100%"
                sx={{ mb: 2, borderRadius: '8px' }}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box>
          {bankAccounts.map((bank) => (
            <BankAccountCard
              key={bank.id}
              bank={bank}
              reauthItems={reauthItems}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarSeverity={setSnackbarSeverity}
              setOpenSnackbar={setOpenSnackbar}
              handleOpenDialog={handleOpenDialog}
              handleToggleAccount={handleToggleAccount}
              onSaveCustomName={handleSaveCustomName}
              onUpdateAccountState={updateAccountState}
            />
          ))}
        </Box>
      )}
      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* Modal */}
      <LinkBankAccountModal
        open={modalOpen}
        handleClose={handleModalClose}
        linkToken={currentLinkToken}
      />
      {/* Confirmation Dialog */}
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmRemove}
        dialogType={dialogType}
        itemName={itemName}
        confirmationAction={confirmationAction}
        verificationText={verificationText}
        setVerificationText={setVerificationText}
      />
    </Box>
  );
};

export default BankAccountInfo;
