import React from 'react';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { motion } from 'framer-motion';
import { colors } from '../../../themes/theme.js';

const MobileFloatingActionMenu = ({ onAddTransaction }) => {
  const buttonVariants = {
    initial: { scale: 1 },
    hover: { scale: 1.05 },
  };

  return (
    <Box
      sx={{ position: 'fixed', right: '24px', bottom: '100px', zIndex: 1300 }}
    >
      <motion.div
        variants={buttonVariants}
        whileHover="hover"
        initial="initial"
        style={{ position: 'relative' }}
      >
        <motion.div
          onClick={onAddTransaction}
          style={{
            width: 60,
            height: 60,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: colors.black2 || '#2C3E50',
            color: 'white',
            boxShadow: `0 2px 10px ${colors.buttonShadow || 'rgba(0, 0, 0, 0.2)'}`,
          }}
        >
          <AddIcon sx={{ fontSize: 24 }} />
        </motion.div>
      </motion.div>
    </Box>
  );
};

export default MobileFloatingActionMenu;
