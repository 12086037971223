import React from 'react';
import { Chip, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { formatCategoryName } from '../Data Grid/FormatCategoryName';
import { colors } from '../../../themes/theme';

/**
 * Creates the column definitions for the Sales Data Grid with full text display
 * @param {Object} defaultCategoryColors - Mapping of categories to colors
 * @param {Object} screenSizes - Object containing screen size breakpoints
 * @returns {Array} Array of column definitions
 */
export const getSalesColumns = (defaultCategoryColors, screenSizes = {}) => {
  const { isMediumScreen = false } = screenSizes;

  // Helper function to create consistent status chips
  const renderStatusChip = (label, bgColor, textColor) => (
    <Chip
      label={label}
      size="small"
      sx={{
        backgroundColor: bgColor,
        color: textColor,
        fontWeight: 500,
        fontSize: '0.75rem',
        minWidth: '100px',
        '& .MuiChip-label': {
          padding: '0 8px',
          overflow: 'visible',
          whiteSpace: 'normal',
        },
      }}
    />
  );

  // Responsive columns configuration
  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      minWidth: 100,
      renderCell: (params) => {
        // Format ID with leading zeros and # prefix
        const formattedValue = `#${(params.value?.toString() || '0000').padStart(4, '0')}`;
        return (
          <Tooltip title={formattedValue} placement="left" arrow>
            <div className="MuiDataGrid-cellContent" role="presentation">
              {formattedValue}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'date',
      headerName: 'DATE',
      width: 150,
      minWidth: 150,
      renderCell: (params) => {
        // Format dates for display and tooltip
        const formattedDate = params.value
          ? dayjs(params.value).format('MMM DD, YYYY')
          : '';
        const tooltipDate = params.value
          ? dayjs(params.value).format('MMMM DD, YYYY')
          : 'No date';

        return (
          <Tooltip title={tooltipDate} placement="right" arrow>
            <div className="MuiDataGrid-cellContent" role="presentation">
              {formattedDate}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'description',
      headerName: 'DETAILS',
      width: 250,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        <Tooltip title={params.value} placement="top-start" arrow>
          <div
            className="MuiDataGrid-cellContent"
            role="presentation"
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                overflow: 'visible',
                whiteSpace: 'normal',
                width: '100%',
              }}
            >
              {params.value}
            </div>
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'account',
      headerName: 'SOURCE',
      width: 160,
      minWidth: 160,
      hide: false,
      renderCell: (params) => {
        const value = params.value || 'Unknown';
        return (
          <Tooltip title={value} placement="left" arrow>
            <div className="MuiDataGrid-cellContent" role="presentation">
              <Chip
                label={value}
                size="small"
                sx={{
                  backgroundColor: colors.sourceChipBg,
                  color: colors.sourceChipText,
                  fontWeight: 400,
                  fontSize: '0.75rem',
                  maxWidth: 'none',
                  '& .MuiChip-label': {
                    whiteSpace: 'normal',
                    overflow: 'visible',
                  },
                }}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'items',
      headerName: 'ITEMS',
      width: isMediumScreen ? 125 : 110,
      minWidth: 100,
      type: 'number',
      // Ensure there's always a valid items count, defaulting to 1
      valueGetter: (params) => params.row.items || 1,
      renderCell: (params) => {
        const value = params.row.items || 1;
        return (
          <Tooltip title={`Items: ${value}`} placement="left" arrow>
            <div
              className="MuiDataGrid-cellContent"
              role="presentation"
              style={{
                textAlign: 'center',
                fontSize: '1rem',
                fontWeight: 500,
              }}
            >
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'AMOUNT',
      width: 160,
      minWidth: 160,
      type: 'number',
      // Format currency values consistently with dollar sign and 2 decimal places
      valueFormatter: (params) => {
        if (params.value === undefined || params.value === null) return '$0.00';
        const value =
          typeof params.value === 'number'
            ? params.value
            : parseFloat(params.value);
        return `$${Math.abs(value / 100).toFixed(2)}`;
      },
      renderCell: (params) => {
        // Ensure value is treated as a number and handle null/undefined
        const value =
          params.value === undefined || params.value === null
            ? 0
            : params.value;
        const isPositive = value >= 0;
        const formattedValue = `$${Math.abs(parseFloat(value) / 100).toFixed(2)}`;
        const tooltipText = `${isPositive ? 'Inflow' : 'Outflow'}: ${formattedValue}`;

        return (
          <Tooltip title={tooltipText} placement="left" arrow>
            <div
              className="MuiDataGrid-cellContent"
              role="presentation"
              style={{
                color: isPositive ? colors.income : colors.expenses,
                fontWeight: 500,
                fontSize: '0.9rem',
                padding: '4px 8px',
              }}
            >
              {formattedValue}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 140,
      minWidth: 140,
      hide: false,
      renderCell: (params) => {
        // Determine status from row data with fallback logic
        const status =
          params.row.status || (params.row.isPending ? 'PENDING' : 'COMPLETED');

        // Mapping of status values to their display colors
        const statusColors = {
          COMPLETED: {
            bg: colors.statusCompletedBg,
            color: colors.statusCompletedText,
          },
          PENDING: {
            bg: colors.statusPendingBg,
            color: colors.statusPendingText,
          },
          FAILED: { bg: colors.statusFailedBg, color: colors.statusFailedText },
          CANCELED: {
            bg: colors.statusCanceledBg,
            color: colors.statusCanceledText,
          },
          APPROVED: {
            bg: colors.statusCompletedBg,
            color: colors.statusCompletedText,
          },
          DECLINED: {
            bg: colors.statusFailedBg,
            color: colors.statusFailedText,
          },
        };

        const style = statusColors[status] || statusColors.PENDING;
        // Format status for display (capitalize first letter only)
        const displayText = status.charAt(0) + status.slice(1).toLowerCase();

        return (
          <Tooltip title={status} placement="right" arrow>
            <div className="MuiDataGrid-cellContent" role="presentation">
              {renderStatusChip(displayText, style.bg, style.color)}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'paymentMethod',
      headerName: 'PAYMENT METHOD',
      width: isMediumScreen ? 220 : 180,
      minWidth: 180,
      hide: false,
      renderCell: (params) => {
        const value = params.row.paymentMethod || 'Credit Card';
        return (
          <Tooltip title={value} placement="left" arrow>
            <div
              className="MuiDataGrid-cellContent"
              role="presentation"
              style={{
                overflow: 'visible',
                whiteSpace: 'normal',
                width: '100%',
                fontSize: '0.9rem',
                fontWeight: 500,
                padding: '4px 0',
              }}
            >
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'category',
      headerName: 'CATEGORY',
      width: 170,
      minWidth: 170,
      valueFormatter: (params) => formatCategoryName(params.value || ''),
      renderCell: (params) => {
        const category = params.value || 'Uncategorized';
        // Get color from the provided mapping or fall back to a default
        const color = defaultCategoryColors[category] || colors.lightGray2;
        const formattedName = formatCategoryName(category);

        return (
          <Tooltip title={formattedName} placement="right" arrow>
            <div
              className="MuiDataGrid-cellContent"
              role="presentation"
              style={{
                backgroundColor: color,
                padding: '2px 12px',
                borderRadius: '50px',
                whiteSpace: 'normal',
                overflow: 'visible',
                maxWidth: 'none',
              }}
            >
              {formattedName}
            </div>
          </Tooltip>
        );
      },
    },
  ];
};

/**
 * Wrapper component that provides responsive columns for the sales data grid
 * Detects screen size and passes appropriate configuration to column definitions
 */
export const ResponsiveSalesColumns = ({ defaultCategoryColors, children }) => {
  const { useTheme, useMediaQuery } = require('@mui/material');
  const theme = useTheme();

  // Screen size detection for responsive design
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md')); // below 900px
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // below 600px

  // Generate columns with current screen size information
  const columns = getSalesColumns(defaultCategoryColors, {
    isMediumScreen,
    isSmallScreen,
  });

  // Use render props pattern to pass columns to children
  return children(columns);
};

export default getSalesColumns;
