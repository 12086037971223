import { useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import { formatCurrency } from '../../features/Format Currency/formatCurrency.js';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

export function useFinancialMetrics(
  transactions,
  activeTimeTab,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate
) {
  return useMemo(() => {
    let income = 0;
    let expenses = 0;
    let netProfit = 0;

    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = dayjs.utc(transaction.authorized_date).local();
      const transactionMonthStr = transactionDate.format('MMMM YYYY');
      const transactionQuarterStr = `Q${Math.floor(transactionDate.month() / 3) + 1} ${transactionDate.year()}`;
      const transactionYearStr = transactionDate.format('YYYY');

      // Handle standard time period filters (7days, 30days, etc.)
      if (['7days', '30days', '90days', '365days'].includes(activeTimeTab)) {
        const now = dayjs();
        let cutoffDate;

        switch (activeTimeTab) {
          case '7days':
            cutoffDate = now.subtract(7, 'day');
            break;
          case '30days':
            cutoffDate = now.subtract(30, 'day');
            break;
          case '90days':
            cutoffDate = now.subtract(90, 'day');
            break;
          case '365days':
            cutoffDate = now.subtract(365, 'day');
            break;
          default:
            cutoffDate = null;
        }

        // Include transactions on or after the cutoff date
        return cutoffDate
          ? transactionDate.isAfter(cutoffDate) ||
              transactionDate.isSame(cutoffDate, 'day')
          : true;
      }

      // Handle custom time period filters
      switch (activeTimeTab) {
        case 'week':
          return selectedWeeks.some((week) => {
            const [start, end] = week.split(' - ');
            const year = end.split(', ')[1];
            const startDate = dayjs(`${start}, ${year}`).startOf('day');
            const endDate = dayjs(end).endOf('day');
            return transactionDate.isBetween(startDate, endDate, null, '[]');
          });
        case 'month':
          return selectedMonths.includes(transactionMonthStr);
        case 'quarter':
          return selectedQuarters.includes(transactionQuarterStr);
        case 'year':
          return selectedYears.includes(transactionYearStr);
        case 'all':
          return true;
        default:
          // Custom date range filter
          if (startDate && endDate) {
            const start = dayjs(startDate).startOf('day');
            const end = dayjs(endDate).endOf('day');
            return transactionDate.isBetween(start, end, null, '[]');
          }
          return true;
      }
    });

    // Calculate metrics from filtered transactions
    filteredTransactions.forEach((transaction) => {
      const amount = parseFloat(transaction.amount);
      if (!isNaN(amount)) {
        netProfit += amount;
        if (amount > 0) {
          income += amount;
        } else if (amount < 0) {
          expenses += Math.abs(amount);
        }
      }
    });

    const formattedStartDate = startDate
      ? dayjs(startDate).format('MMM D, YYYY')
      : '';
    const formattedEndDate = endDate
      ? dayjs(endDate).format('MMM D, YYYY')
      : '';

    return {
      income: formatCurrency(income),
      expenses: formatCurrency(expenses),
      netProfit: formatCurrency(netProfit),
      dateRange: `${formattedStartDate} - ${formattedEndDate}`,
    };
  }, [
    transactions,
    activeTimeTab,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
  ]);
}
