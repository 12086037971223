import React, { useMemo } from 'react';
import { Box, Typography, Paper, Chip, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import {
  AttachMoney as AttachMoneyIcon,
  Receipt as ReceiptIcon,
  TrendingUp as TrendingUpIcon,
  Inventory as InventoryIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';
import { calculateItemsPerSale } from './index';
import {
  formatToSixSigFigs,
  formatPercentChange,
} from '../../../utils/salesMetricsRounding';
import { colors } from '../../../themes/theme';

// Styled metric card for the clean design
const CleanMetricCard = styled(Paper)(({ theme }) => ({
  borderRadius: 16,
  padding: theme.spacing(1.5),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },
  '@media (max-width: 375px)': {
    padding: theme.spacing(1),
  },
  boxShadow: `0 2px 6px ${colors.shadow1}`,
  border: `1px solid ${colors.border}`,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

// Styled metric title with icon
const MetricTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0.5),
  '@media (max-width: 375px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
  },
}));

// Styled percentage change container
const PercentageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 375px)': {
    alignSelf: 'flex-start',
  },
}));

const MetricName = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
  color: colors.textSecondary,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  '@media (max-width: 375px)': {
    width: '100%',
  },
}));

// Styled percentage change indicator with conditional styling based on value
const PercentageChange = styled(Box)(({ theme, isPositive, isNeutral }) => ({
  backgroundColor: isNeutral
    ? colors.lightGray2
    : isPositive
      ? colors.successLight
      : colors.errorLight,
  color: isNeutral
    ? colors.textSecondary
    : isPositive
      ? colors.successText
      : colors.errorText,
  borderRadius: 20,
  padding: '3px 8px',
  fontSize: '0.75rem',
  fontWeight: 600,
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  '@media (max-width: 375px)': {
    padding: '2px 6px',
    fontSize: '0.7rem',
    gap: theme.spacing(0.25),
  },
}));

// Styled metric value (large number)
const MetricValue = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.75rem',
  },
  '@media (max-width: 375px)': {
    width: '100%',
  },
  fontWeight: 700,
  color: colors.textPrimary,
  marginTop: theme.spacing(0.5),
  marginBottom: 0,
  lineHeight: 1.2,
}));

// Small context metrics
const MetricContext = styled(Box)(({ theme }) => ({
  fontSize: '0.75rem',
  color: colors.textSecondary,
  marginTop: theme.spacing(0.5),
  '@media (max-width: 375px)': {
    fontSize: '0.7rem',
    marginTop: theme.spacing(0.25),
  },
}));

// Inline context (displayed next to the main value)
const InlineContext = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: colors.textSecondary,
  alignSelf: 'flex-end',
  marginBottom: 3,
  marginLeft: 4,
  '@media (max-width: 375px)': {
    fontSize: '0.7rem',
    marginBottom: 0,
    marginLeft: 0,
    marginTop: theme.spacing(0.25),
    alignSelf: 'flex-start',
  },
}));

// Comparison period chip at the bottom
const ComparisonChip = styled(Chip)(({ theme }) => ({
  backgroundColor: colors.lightGray2,
  color: colors.textSecondary,
  fontSize: '0.75rem',
  height: 24,
  borderRadius: 12,
  margin: '8px auto 0',
  '@media (max-width: 375px)': {
    fontSize: '0.7rem',
    height: 22,
    borderRadius: 11,
  },
}));

// Main component using React.memo for performance optimization
const SalesMetricsDisplay = React.memo(
  ({ metricsData, startDate, endDate }) => {
    // Format the comparison period for display - hooks must be called at the top level
    const formattedComparisonPeriod = useMemo(() => {
      if (!metricsData?.comparisonPeriod) return null;

      const { startDate: prevStart, endDate: prevEnd } =
        metricsData.comparisonPeriod;

      if (!prevStart || !prevEnd) return null;

      const formatDate = (dateStr) => dayjs(dateStr).format('MMM D, YYYY');
      return `vs ${formatDate(prevStart)} - ${formatDate(prevEnd)}`;
    }, [metricsData?.comparisonPeriod]);

    // Early return after all hooks have been called
    if (!metricsData) return null;

    // Destructure and provide default values to prevent rendering errors when data is missing
    const {
      totalSales = '$0.00',
      totalTransactions = '0',
      averageSale = '$0.00',
      itemsSold = '0',
      percentChanges = {
        sales: '0.0%',
        transactions: '0.0%',
        average: '0.0%',
        items: '0.0%',
      },
      previousValues = {
        sales: '$0.00',
        transactions: '0',
        average: '$0.00',
        items: '0',
      },
    } = metricsData;

    // Format all numeric values to max 6 significant figures
    const formattedTotalSales = formatToSixSigFigs(totalSales, true);
    const formattedTotalTransactions = formatToSixSigFigs(totalTransactions);
    const formattedAverageSale = formatToSixSigFigs(averageSale, true);
    const formattedItemsSold = formatToSixSigFigs(itemsSold);

    // Format previous values
    const formattedPrevSales = formatToSixSigFigs(previousValues.sales, true);
    const formattedPrevTransactions = formatToSixSigFigs(
      previousValues.transactions
    );
    const formattedPrevAverage = formatToSixSigFigs(
      previousValues.average,
      true
    );
    const formattedPrevItems = formatToSixSigFigs(previousValues.items);

    // Calculate items per sale with formatted values
    const rawItemsPerSale = calculateItemsPerSale(itemsSold, totalTransactions);
    const formattedItemsPerSale = formatToSixSigFigs(rawItemsPerSale);

    // Helper to determine if a percent change string is positive, negative, or neutral
    // and format it consistently
    const getPercentChangeInfo = (percentChangeStr) => {
      const formattedPercentChange = formatPercentChange(percentChangeStr);
      const cleanStr = formattedPercentChange.replace('%', '');
      const value = parseFloat(cleanStr);

      return {
        isPositive: value > 0,
        isNeutral: value === 0,
        isNegative: value < 0,
        displayValue: value === 0 ? '0.0%' : formattedPercentChange,
      };
    };

    // Process all percentage changes for consistent display
    const salesChange = getPercentChangeInfo(percentChanges.sales);
    const transactionsChange = getPercentChangeInfo(
      percentChanges.transactions
    );
    const averageChange = getPercentChangeInfo(percentChanges.average);
    const itemsChange = getPercentChangeInfo(percentChanges.items);

    return (
      <>
        <Grid
          container
          spacing={2}
          sx={{
            pt: 2,
            pb: 1, // Reduced bottom padding
            px: { xs: 0, sm: 0 }, // Consistent horizontal padding
            '@media (max-width: 375px)': {
              spacing: 1.5,
            },
          }}
        >
          {/* Net Sales Card */}
          <Grid item xs={6} sm={6} md={3}>
            <CleanMetricCard>
              <MetricTitle>
                <MetricName>
                  <AttachMoneyIcon sx={{ fontSize: 16 }} /> Net Sales
                </MetricName>
                <PercentageContainer>
                  <PercentageChange
                    isPositive={salesChange.isPositive}
                    isNeutral={salesChange.isNeutral}
                  >
                    {salesChange.isPositive ? (
                      <ArrowUpwardIcon sx={{ fontSize: 14 }} />
                    ) : salesChange.isNegative ? (
                      <ArrowDownwardIcon sx={{ fontSize: 14 }} />
                    ) : null}
                    {salesChange.displayValue}
                  </PercentageChange>
                </PercentageContainer>
              </MetricTitle>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'baseline',
                  '@media (max-width: 375px)': {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  },
                }}
              >
                <MetricValue>{formattedTotalSales}</MetricValue>
              </Box>
              <MetricContext>Previous: {formattedPrevSales}</MetricContext>
            </CleanMetricCard>
          </Grid>

          {/* Transactions Card */}
          <Grid item xs={6} sm={6} md={3}>
            <CleanMetricCard>
              <MetricTitle>
                <MetricName>
                  <ReceiptIcon sx={{ fontSize: 16 }} /> Transactions
                </MetricName>
                <PercentageContainer>
                  <PercentageChange
                    isPositive={transactionsChange.isPositive}
                    isNeutral={transactionsChange.isNeutral}
                  >
                    {transactionsChange.isPositive ? (
                      <ArrowUpwardIcon sx={{ fontSize: 14 }} />
                    ) : transactionsChange.isNegative ? (
                      <ArrowDownwardIcon sx={{ fontSize: 14 }} />
                    ) : null}
                    {transactionsChange.displayValue}
                  </PercentageChange>
                </PercentageContainer>
              </MetricTitle>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'baseline',
                  '@media (max-width: 375px)': {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  },
                }}
              >
                <MetricValue>{formattedTotalTransactions}</MetricValue>
              </Box>
              <MetricContext>
                Previous: {formattedPrevTransactions}
              </MetricContext>
            </CleanMetricCard>
          </Grid>

          {/* Average Sale Card */}
          <Grid item xs={6} sm={6} md={3}>
            <CleanMetricCard>
              <MetricTitle>
                <MetricName>
                  <TrendingUpIcon sx={{ fontSize: 16 }} /> Average Sale
                </MetricName>
                <PercentageContainer>
                  <PercentageChange
                    isPositive={averageChange.isPositive}
                    isNeutral={averageChange.isNeutral}
                  >
                    {averageChange.isPositive ? (
                      <ArrowUpwardIcon sx={{ fontSize: 14 }} />
                    ) : averageChange.isNegative ? (
                      <ArrowDownwardIcon sx={{ fontSize: 14 }} />
                    ) : null}
                    {averageChange.displayValue}
                  </PercentageChange>
                </PercentageContainer>
              </MetricTitle>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'baseline',
                  '@media (max-width: 375px)': {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  },
                }}
              >
                <MetricValue>{formattedAverageSale}</MetricValue>
                <InlineContext>per transaction</InlineContext>
              </Box>
              <MetricContext>Previous: {formattedPrevAverage}</MetricContext>
            </CleanMetricCard>
          </Grid>

          {/* Items Sold Card */}
          <Grid item xs={6} sm={6} md={3}>
            <CleanMetricCard>
              <MetricTitle>
                <MetricName>
                  <InventoryIcon sx={{ fontSize: 16 }} /> Items Sold
                </MetricName>
                <PercentageContainer>
                  <PercentageChange
                    isPositive={itemsChange.isPositive}
                    isNeutral={itemsChange.isNeutral}
                  >
                    {itemsChange.isPositive ? (
                      <ArrowUpwardIcon sx={{ fontSize: 14 }} />
                    ) : itemsChange.isNegative ? (
                      <ArrowDownwardIcon sx={{ fontSize: 14 }} />
                    ) : null}
                    {itemsChange.displayValue}
                  </PercentageChange>
                </PercentageContainer>
              </MetricTitle>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'baseline',
                  '@media (max-width: 375px)': {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  },
                }}
              >
                <MetricValue>{formattedItemsSold}</MetricValue>
                <InlineContext>{formattedItemsPerSale} per sale</InlineContext>
              </Box>
              <MetricContext>Previous: {formattedPrevItems}</MetricContext>
            </CleanMetricCard>
          </Grid>
        </Grid>

        {/* Comparison period chip */}
        {formattedComparisonPeriod && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 1,
              mb: 0, // Reduced bottom margin
              '@media (max-width: 375px)': {
                mt: 0.5,
                mb: 0,
              },
            }}
          >
            <ComparisonChip label={formattedComparisonPeriod} />
          </Box>
        )}
      </>
    );
  }
);

export default SalesMetricsDisplay;
