import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import logToSentry from '../../../lib/sentry';

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({
  wsUrl,
  userId: initialUserId,
  children,
}) => {
  const storedUserId = localStorage.getItem('userId');
  const userId = initialUserId || storedUserId;
  const [isConnected, setIsConnected] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [syncCompleted, setSyncCompleted] = useState(false);
  const wsRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const pingIntervalRef = useRef(null);

  const connectWebSocket = useCallback(() => {
    if (!wsUrl || !userId) {
      logToSentry('websocket_missing_configuration', {
        status: 'error',
        tags: { category: 'websocket', step: 'validation' },
        extraData: { wsUrl, userId },
      });
      return;
    }

    console.log(
      `[WebSocket] Attempting connection for userId: ${userId} to ${wsUrl}`
    );

   if (
     wsRef.current &&
     (wsRef.current.readyState === WebSocket.CONNECTING ||
       wsRef.current.readyState === WebSocket.OPEN)
   ) {
     console.log(
       '[WebSocket] Already connecting or connected for user ' + userId
     );
     return;
   }


    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
     try {
       const ws = new WebSocket(`${wsUrl}?userId=${userId}`);

       const connectionAttemptTime = new Date().toISOString();

       ws.onopen = () => {
         console.log(
           `[WebSocket] Successfully connected for user ${userId} at ${new Date().toISOString()}`
         );
         console.log(
           `[WebSocket] Connection time: ${Date.now() - new Date(connectionAttemptTime).getTime()}ms`
         );

         setIsConnected(true);

         try {
           ws.send(JSON.stringify({ type: 'updateUser', newUserId: userId }));
           console.log('[WebSocket] Sent updateUser message');
         } catch (e) {
           console.error('[WebSocket] Error sending updateUser message:', e);
         }

         if (pingIntervalRef.current) {
           clearInterval(pingIntervalRef.current);
         }

         pingIntervalRef.current = setInterval(() => {
           if (ws.readyState === WebSocket.OPEN) {
             try {
               ws.send(JSON.stringify({ type: 'ping' }));
               console.log('[WebSocket] Ping sent');
             } catch (e) {
               console.error('[WebSocket] Error sending ping:', e);
             }
           } else {
             console.warn('[WebSocket] Cannot send ping, connection not open');
           }
         }, 30000);
       };

       ws.onmessage = (event) => {
         try {
           const message = JSON.parse(event.data);
           switch (message.status) {
             case 'syncing':
               setSyncing(true);
               setSyncCompleted(false);
               logToSentry('websocket_sync_started', {
                 tags: { category: 'websocket', step: 'syncing' },
                 extraData: { message },
               });
               break;
             case 'complete':
               setSyncing(false);
               setSyncCompleted(true);
               logToSentry('websocket_sync_complete', {
                 tags: { category: 'websocket', step: 'complete' },
                 extraData: { message },
               });
               break;
             case 'error':
               setSyncing(false);
               setSyncCompleted(false);
               console.error('Sync error:', message.error);
               logToSentry('websocket_sync_error', {
                 status: 'error',
                 tags: { category: 'websocket', step: 'sync_error' },
                 error: new Error('Sync error'),
                 extraData: { message },
               });
               break;
             default:
               logToSentry('websocket_unknown_message', {
                 tags: { category: 'websocket', step: 'unknown_message' },
                 extraData: { message },
               });
           }
         } catch (error) {
           console.error('Error parsing WebSocket message:', error);
           logToSentry('websocket_message_parsing_error', {
             status: 'error',
             tags: { category: 'websocket', step: 'message_parsing' },
             error,
             extraData: { rawData: event.data },
           });
         }
       };

       ws.onclose = (event) => {
         setIsConnected(false);

         if (pingIntervalRef.current) {
           clearInterval(pingIntervalRef.current);
           pingIntervalRef.current = null;
         }

         if (event.code !== 1000) {
           logToSentry('websocket_disconnected', {
             status: 'warning',
             tags: { category: 'websocket', step: 'disconnection' },
             extraData: { code: event.code, reason: event.reason },
           });
           if (reconnectTimeoutRef.current) {
             clearTimeout(reconnectTimeoutRef.current);
           }
           reconnectTimeoutRef.current = setTimeout(() => {
             connectWebSocket();
           }, 5000);
         }
       };

       ws.onerror = (error) => {
         logToSentry('websocket_error', {
           status: 'error',
           tags: { category: 'websocket', step: 'error' },
           error,
           extraData: { userId },
         });
       };

       wsRef.current = ws;
     } catch (error) {
       console.error('[WebSocket] Connection error:', error);
       logToSentry('websocket_connection_error', {
         status: 'error',
         tags: { category: 'websocket', step: 'connection' },
         error,
         extraData: { wsUrl, userId },
       });

       // Schedule a reconnection attempt
       if (reconnectTimeoutRef.current) {
         clearTimeout(reconnectTimeoutRef.current);
       }
       reconnectTimeoutRef.current = setTimeout(() => {
         console.log('[WebSocket] Reconnecting after error...');
         connectWebSocket();
       }, 3000);
     }
  }, [wsUrl, userId]);

  // Initial connection
  useEffect(() => {
    if (!userId) return;

    connectWebSocket();

    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
      if (wsRef.current) {
        wsRef.current.close(1000, 'Component unmounting');
        wsRef.current = null;
      }
    };
  }, [userId, connectWebSocket]);

  // Force reconnection function
  const reconnect = useCallback(() => {
    connectWebSocket();
  }, [connectWebSocket]);

  const contextValue = {
    isConnected,
    syncing,
    syncCompleted,
    wsRef,
    reconnect,
    userId,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
