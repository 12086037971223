import React, { createContext, useContext, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useDateRange from '../../common/hooks/useDateRange';

// Initialize dayjs plugins for handling timezones
dayjs.extend(utc);
dayjs.extend(timezone);

const DashboardContext = createContext();

export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardProvider = ({
  children,
  minDateRange,
  maxDateRange,
  fetchTransactions,
}) => {
  const { startDate, endDate, setStartDate, setEndDate } = useDateRange(
    minDateRange,
    maxDateRange
  );

  // Convert UTC dates to local timezone for UI display
  const minDateRangeLocal = useMemo(
    () => dayjs.utc(minDateRange).local().toDate(),
    [minDateRange]
  );
  const maxDateRangeLocal = useMemo(
    () => dayjs.utc(maxDateRange).local().toDate(),
    [maxDateRange]
  );

  const [activeTimeTab, setActiveTimeTab] = useState('all');
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedQuarters, setSelectedQuarters] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);

  // Default sales metrics state with initial values
  const [salesMetricsData, setSalesMetricsData] = useState({
    totalSales: '$0.00',
    totalTransactions: '0',
    averageSale: '$0.00',
    itemsSold: '0',
    percentChanges: {
      sales: '+0%',
      transactions: '+0%',
      average: '+0%',
      items: '+0%',
    },
    previousValues: {
      sales: '$0.00',
      transactions: '0',
      average: '$0.00',
      items: '0',
    },
  });

  return (
    <DashboardContext.Provider
      value={{
        activeTimeTab,
        setActiveTimeTab,
        isCustomRange,
        setIsCustomRange,
        selectedWeeks,
        setSelectedWeeks,
        selectedMonths,
        setSelectedMonths,
        selectedQuarters,
        setSelectedQuarters,
        selectedYears,
        setSelectedYears,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        fetchTransactions,
        minDateRangeLocal,
        maxDateRangeLocal,
        salesMetricsData,
        setSalesMetricsData,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
