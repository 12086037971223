"use client"

import { Box } from "@mui/material"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import CloseIcon from "@mui/icons-material/Close"
import { motion } from "framer-motion"
import { useState } from "react"
import { colors } from "../../../themes/theme"

const FloatingButton = ({ isOpen, toggleMenu }) => {
  const [isHovered, setIsHovered] = useState(false)

  const buttonVariants = {
    initial: { scale: 1 },
    hover: { scale: 1.05 },
  }

  const borderVariants = {
    initial: { opacity: 0 },
    hover: {
      opacity: 1,
      transition: { duration: 0.3 },
    },
  }

  return (
    <Box sx={{ position: "fixed", right: "24px", bottom: "24px", zIndex: 1300 }}>
      <motion.div
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        variants={buttonVariants}
        whileHover="hover"
        initial="initial"
        style={{ position: "relative" }}
      >
        <motion.div
          variants={borderVariants}
          animate={isHovered ? "hover" : "initial"}
          style={{
            position: "absolute",
            top: -4,
            left: -4,
            right: -4,
            bottom: -4,
            borderRadius: "50%",
            background: `linear-gradient(90deg, ${colors.gradientStart}, ${colors.gradientMiddle}, ${colors.gradientEnd})`,
            backgroundSize: "250% 250%",
            zIndex: -1,
          }}
        >
          <motion.div
            animate={{
              backgroundPosition: isHovered ? ["0% 0%", "200% 200%"] : "0% 0%",
            }}
            transition={{
              duration: 3,
              repeat: Number.POSITIVE_INFINITY,
              repeatType: "reverse",
              ease: "linear",
            }}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              background: "inherit",
            }}
          />
        </motion.div>
        <motion.div
          onClick={() => toggleMenu(!isOpen)}
          style={{
            width: 60,
            height: 60,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            backgroundColor: colors.buttonBackground,
            color: colors.buttonText,
            boxShadow: `0 2px 10px ${colors.buttonShadow}`,
            border: isHovered ? `1px solid ${colors.borderColor2}` : "none",
          }}
        >
          {isOpen ? <CloseIcon /> : <AutoAwesomeIcon />}
        </motion.div>
      </motion.div>
    </Box>
  )
}

export default FloatingButton
